import { PopupWrapper } from "../../UI/PopupWrapper/PopupWrapper";
import React, { useContext, useState, useRef } from "react";
import "./MetaDataPopup.css";
import axios from "axios";
import { userAuthContext } from "../../Context/UserAuthContext";
import { shootmatesalbumdata } from "../../store/firebase-config";
import { ref, uploadBytes } from "firebase/storage";
import popupsCloseIcon from "../../Assets/popupsCloseIcon.svg";
import coverImageIcon from "../../Assets/coverImageIcon.svg";
import { dataContext } from "../../Context/DataContext";
function MetaDataPopupAlbum({
  update,
  setUpdate,
  visible,
  setView,
  data,
  studioId,
  shootmatesAlbumId,
  projectId,
}) {
  const inputRefStudioLogo = useRef(null);
  const inputRefTimelineImages = useRef(null);
  const inputRefAlbumCover = useRef(null);
  const inputRefAlbumCoverSong = useRef(null);
  const { studioDetails, activePackages } = useContext(dataContext);
  const { userIdToken, currentUser, verifyUser } = useContext(userAuthContext);
  const tokenData = userIdToken || currentUser?.accessToken;
  const [timelineImages, setTimelineImages] = useState([]);
  const [studioLogoLoading, setstudioLogoLoading] = useState(false);
  const [metaDataButtonLoading, setMetaDataButtonLoading] = useState(false);
  const [albumCoverImageLoading, setAlbumCoverImageLoading] = useState(false);
  const [albumCoverSongLoading, setAlbumCoverSongLoading] = useState(false);
  const [TimelineImagesLoading, setTimelineImagesLoading] = useState(false);
  const [validationErrors, setValidationErrors] = useState({});
  const [albumMetaDataDetails, setAlbumMetaDataDetails] = useState({
    albumId: data?.memshotAlbumId,
    albumCover: data?.albumMetaData?.albumCover,
    albumName: data?.albumMetaData?.albumName,
    brideGroomName: data?.albumMetaData?.brideGroomName,
    studio: {
      whatsAppNumber:
        data?.albumMetaData?.studio?.whatsAppNumber ||
        studioDetails?.whatsAppNumber,
      studioLogo:
        data?.albumMetaData?.studio?.studioLogo || studioDetails?.studioLogo,
      studioName:
        data?.albumMetaData?.studio?.studioName || studioDetails?.studioName,
      studioLocation:
        data?.albumMetaData?.studio?.studioLocation ||
        studioDetails?.studioLocation,
      phoneNumber:
        data?.albumMetaData?.studio?.phoneNumber || studioDetails?.phoneNumber,
      website: data?.albumMetaData?.studio?.website || studioDetails?.website,
      email: data?.albumMetaData?.studio?.email || studioDetails?.email,
      socialMedia: [],
      transitionFiles: data?.albumMetaData?.studio?.transitionFiles
        ? data?.albumMetaData?.studio?.transitionFiles
        : [],
    },
    song: data?.albumMetaData?.song,
    faceDetectionButtonText: data?.albumMetaData?.faceDetectionButtonText,
    faceDetectionButtonUrl: data?.albumMetaData?.faceDetectionButtonUrl,
  });

  const handleTimelineImagesChange = async (e) => {
    //https://storage.googleapis.com/memshot_album_metadata/613/613.jpg
    const fileList = e.target.files;
    const imagesArray = Array.from(fileList);
    try {
      setTimelineImagesLoading(true);
      const uploadedUrls = [];
      for (const selectedFile of imagesArray) {
        const fileName = selectedFile.name;
        const storageRef = ref(
          shootmatesalbumdata,
          `${data?.memshotAlbumId}/albumTimelineImages/${fileName}`
        );
        await uploadBytes(storageRef, selectedFile);
        const bucketName = "shootmatesalbumdata";
        const filePath =
          data?.memshotAlbumId + "/albumTimelineImages/" + fileName;
        const gcsBucketUrl = `https://storage.googleapis.com/${bucketName}/${filePath}`;
        uploadedUrls.push(gcsBucketUrl);
      }
      setTimelineImages(uploadedUrls);
      setAlbumMetaDataDetails((prevDetails) => ({
        ...prevDetails,
        studio: {
          ...prevDetails.studio,
          transitionFiles: [
            ...prevDetails?.studio?.transitionFiles,
            ...uploadedUrls,
          ],
        },
      }));
      setTimelineImagesLoading(false);
    } catch (error) {
      console.error("Error uploading images:", error);
      setTimelineImagesLoading(false);
    }
  };

  const handleStudioLogoChange = async (e) => {
    const selectedFile = e.target.files[0];
    if (selectedFile) {
      try {
        setstudioLogoLoading(true);
        const fileName = selectedFile.name;
        const storageRef = ref(
          shootmatesalbumdata,
          `${data?.memshotAlbumId}/studioLogos/${fileName}`
        );
        await uploadBytes(storageRef, selectedFile);
        const bucketName = "shootmatesalbumdata";
        const filePath = data?.memshotAlbumId + "/studioLogos/" + fileName;
        const gcsBucketUrl = `https://storage.googleapis.com/${bucketName}/${filePath}`;
        setAlbumMetaDataDetails((prevDetails) => ({
          ...prevDetails,
          studio: {
            ...prevDetails.studio,
            studioLogo: gcsBucketUrl,
          },
        }));
        setstudioLogoLoading(false);
      } catch (error) {
        console.error("Error uploading studio logo:", error);
        setstudioLogoLoading(false);
      }
    }
  };

  const handleAlbumCoverSongChange = async (e) => {
    const selectedFile = e.target.files[0];
    if (selectedFile) {
      if (!selectedFile.type.includes("audio/")) {
        alert("Invalid file format. Please upload an MP3 file.");
        return;
      }
      try {
        setAlbumCoverSongLoading(true);
        const fileName = selectedFile.name;
        const storageRef = ref(
          shootmatesalbumdata,
          `${data?.memshotAlbumId}/studioSong/${fileName}`
        );
        await uploadBytes(storageRef, selectedFile);
        const bucketName = "shootmatesalbumdata";
        const filePath = data?.memshotAlbumId + "/studioSong/" + fileName;
        const gcsBucketUrl = `https://storage.googleapis.com/${bucketName}/${filePath}`;
        setAlbumMetaDataDetails((prevDetails) => ({
          ...prevDetails,
          song: gcsBucketUrl,
        }));
        setAlbumCoverSongLoading(false);
      } catch (error) {
        console.error("Error uploading studio song:", error);
        setAlbumCoverSongLoading(false);
      }
    } else {
      console.error("Invalid file format. Please upload an MP3 file.");
    }
  };

  const handleAlbumCoverPage = async (e) => {
    const selectedFile = e.target.files[0];
    if (selectedFile) {
      const validTypes = ["image/jpeg", "image/jpg"];

      if (!validTypes.includes(selectedFile.type)) {
        alert("Only .jpg or .jpeg images are allowed.");
        return;
      }

      try {
        setAlbumCoverImageLoading(true);
        let fileToUpload = selectedFile;
        if (selectedFile.size > 400 * 1024) {
          fileToUpload = await compressImage(selectedFile, 0.5);
        }
        const fileName = `${shootmatesAlbumId}albumcover.jpeg`;
        const storageRef = ref(
          shootmatesalbumdata,
          `${data?.memshotAlbumId}/albumCover/${fileName}`
        );
        const metadata = {
          contentType: fileToUpload.type,
          cacheControl: "no-cache, no-store, must-revalidate",
        };
        await uploadBytes(storageRef, fileToUpload, metadata);
        const bucketName = "shootmatesalbumdata";
        const filePath = `${data?.memshotAlbumId}/albumCover/${fileName}`;
        const gcsBucketUrl = `https://storage.googleapis.com/${bucketName}/${filePath}`;
        setAlbumMetaDataDetails((prevDetails) => ({
          ...prevDetails,
          albumCover: gcsBucketUrl,
        }));
        setAlbumCoverImageLoading(false);
      } catch (error) {
        console.error("Error uploading album cover:", error);
        setAlbumCoverImageLoading(false);
      }
    }
  };

  const compressImage = (file, quality) => {
    return new Promise((resolve, reject) => {
      const canvas = document.createElement("canvas");
      const ctx = canvas.getContext("2d");
      const img = new Image();
      img.onload = () => {
        canvas.width = img.width;
        canvas.height = img.height;
        ctx.drawImage(img, 0, 0, img.width, img.height);
        canvas.toBlob(
          (blob) => {
            const compressedFile = new File([blob], file.name, {
              type: file.type,
              lastModified: Date.now(),
            });
            resolve(compressedFile);
          },
          file.type,
          quality
        );
      };
      img.onerror = (err) => reject(err);
      const reader = new FileReader();
      reader.onload = (event) => {
        img.src = event.target.result;
      };
      reader.readAsDataURL(file);
    });
  };

  const removeImage = (index) => {
    const updatedImages = [...timelineImages];
    updatedImages.splice(index, 1);
    setTimelineImages(updatedImages);
    setAlbumMetaDataDetails((prevDetails) => ({
      ...prevDetails,
      studio: {
        ...prevDetails.studio,
        transitionFiles: updatedImages,
      },
    }));
  };

  const removeImageStudioLogo = () => {
    setAlbumMetaDataDetails((prevDetails) => ({
      ...prevDetails,
      studio: {
        ...prevDetails.studio,
        studioLogo: "",
      },
    }));
  };

  const removeAlbumCoverImage = () => {
    setAlbumMetaDataDetails((prevDetails) => ({
      ...prevDetails,
      albumCover: "",
    }));
  };

  const handleAlbumMetaDataDetails = (e, type) => {
    let { name, value } = e.target;
    if (type === "album") {
      setAlbumMetaDataDetails((prevDetails) => ({
        ...prevDetails,
        [name]: value,
      }));
    } else {
      setAlbumMetaDataDetails((prevDetails) => ({
        ...prevDetails,
        studio: {
          ...prevDetails.studio,
          [name]: value,
        },
      }));

      // Clear validation error for this studio field when it's updated
      setValidationErrors((prevErrors) => ({
        ...prevErrors,
        studio: {
          ...prevErrors.studio,
          [name]: undefined,
        },
      }));
    }
  };

  const handleSubmit = async () => {
    try {
      setMetaDataButtonLoading(true);
      //     let validationErrors = {};
      // if (!albumMetaDataDetails.albumCover) {
      //     validationErrors.albumCover = 'Album albumCover is required';
      // }
      const payload = {
        ...albumMetaDataDetails,
        studio: {
          ...albumMetaDataDetails?.studio,
        },
      };
      verifyUser();
      let config = {
        method: "post",
         url: `https://asia-south1-shootmates.cloudfunctions.net/shootmatesMemshotsServices/api/shootmates/memshotsservices/saveAlbumMetaData/${projectId}/${studioId}/${shootmatesAlbumId}`,
        // url: `https://asia-south1-shootmates.cloudfunctions.net/test_shootmatesMemshotsServices/api/shootmates/memshotsservices/saveAlbumMetaData/${projectId}/${studioId}/${shootmatesAlbumId}`,

        data: {
          albumMetaData: payload,
        },
        headers: {
          Authorization: `Bearer ${tokenData}`,
          "Content-Type": "application/json",
        },
      };
      const response = await axios(config);
      if (response.status === 200) {
        setUpdate(!update);
        setMetaDataButtonLoading(false);
        setView(false);
      } else {
        setUpdate(!update);
        setMetaDataButtonLoading(false);
        setView(false);
      }
    } catch (error) {
      console.log("error", error);
      setUpdate(!update);
      setMetaDataButtonLoading(false);
      setView(false);
    }
  };
  return (
    <PopupWrapper visible={visible}>
      <div className="metadataFormContainer mt-[78px] min-w-[768px]">
        <div className="flex justify-between p-[24px] items-center">
          <p className="font_semibold_20 text-[#161616]">Settings</p>
          <img
            src={popupsCloseIcon}
            alt=""
            onClick={() => {
              setView(false);
            }}
            className="cursor-pointer"
          />
        </div>
        <div className="seperatorHorizontalNew"></div>

        <div className="seperatorHorizontalNew"></div>
        <div className="seperatorHorizontalNew"></div>
        <div className="w-full h-full px-[24px] py-[20px]">
          <p className="font_semibold_16 text-[#161616] mb-[24px]">
            Album Details
          </p>
          <div className="w-full grid grid-cols-2 gap-[24px]">
            <div>
              <p className="font_normal_12 text-[#8C8C8C] mb-[8px]">
                Bride & Groom Name
              </p>
              <input
                type="text"
                id="brideGroomName"
                name="brideGroomName"
                placeholder="Please enter Bride & Groom name"
                className={`w-full font_normal_14 border-[1px] p-[10px] rounded-[8px] border-[#8C8C8C4D]`}
                value={albumMetaDataDetails.brideGroomName}
                onChange={(e) => handleAlbumMetaDataDetails(e, "album")}
              />
            </div>
            <div>
              <p className="font_normal_12 text-[#8C8C8C] mb-[8px]">
                Album Name
              </p>
              <input
                type="text"
                id="albumName"
                name="albumName"
                placeholder="Please enter Groom name"
                className={`w-full font_normal_14 border-[1px] p-[10px] rounded-[8px] border-[#8C8C8C4D]`}
                value={albumMetaDataDetails.albumName}
                onChange={(e) => handleAlbumMetaDataDetails(e, "album")}
              />
            </div>
            <div>
              <div className="rounded-[8px] border-[#8C8C8C4D] border-[1px] h-[100px] flex justify-center items-center ">
                {albumMetaDataDetails?.albumCover ? (
                  <div className="w-full h-full">
                    <img
                      src={albumMetaDataDetails?.albumCover}
                      alt=""
                      className="w-full h-full object-cover"
                    />
                  </div>
                ) : (
                  <div
                    onClick={() => inputRefAlbumCover.current.click()}
                    className="flex gap-[10px] items-center border-[1px] border-[#8C8C8C33] cursor-pointer"
                  >
                    <input
                      type="file"
                      accept="image/*"
                      onChange={handleAlbumCoverPage}
                      className="hidden"
                      id="albumCover"
                      name="albumCover"
                      ref={inputRefAlbumCover}
                    />
                    <img src={coverImageIcon} alt="" />
                    <p className="font_normal_14 text-[#161616]">
                      {albumCoverImageLoading
                        ? "Uploading..."
                        : "Choose Album Cover"}
                    </p>
                  </div>
                )}
              </div>
              <div className="flex justify-end px-[24px] mt-[8px]">
                {albumMetaDataDetails?.albumCover && (
                  <p
                    onClick={removeAlbumCoverImage}
                    className="font_normal_12 text-[#4D58E3] cursor-pointer"
                  >
                    Change Cover image
                  </p>
                )}
              </div>
            </div>
            {/* <div>
              <div className="rounded-[8px] border-[#8C8C8C4D] border-[1px] h-[100px] flex justify-center items-center ">
                {albumMetaDataDetails?.song ? (
                  <div>
                    <audio controls>
                      <source
                        src={albumMetaDataDetails?.song}
                        type="audio/mp3"
                      />
                      Your browser does not support the audio element.
                    </audio>
                  </div>
                ) : (
                  <div
                    onClick={() => inputRefAlbumCoverSong.current.click()}
                    className="flex gap-[10px] items-center border-[1px] border-[#8C8C8C33] cursor-pointer"
                  >
                    <input
                      type="file"
                      accept="audio/*"
                      onChange={handleAlbumCoverSongChange}
                      className="hidden"
                      id="song"
                      name="song"
                      ref={inputRefAlbumCoverSong}
                    />
                    <img src={coverImageIcon} alt="" />
                    <p className="font_normal_14 text-[#161616]">
                      {albumCoverSongLoading
                        ? "Uploading..."
                        : "Choose Album Cover Song"}
                    </p>
                  </div>
                )}
              </div>
              <div className="flex justify-end px-[24px] mt-[8px]">
                {albumMetaDataDetails?.song && (
                  <p
                    onClick={handleAlbumCoverSongChange}
                    className="font_normal_12 text-[#4D58E3] cursor-pointer"
                  >
                    Change Cover song
                  </p>
                )}
              </div>
            </div> */}
            <div>
              <p className="font_normal_12 text-[#8C8C8C] mb-[8px]">
                {" "}
                FaceDetection Url
              </p>
              <input
                type="text"
                id="faceDetectionButtonUrl"
                name="faceDetectionButtonUrl"
                placeholder="Please enter Face Detection Url"
                className={`w-full font_normal_14 border-[1px] p-[10px] rounded-[8px] border-[#8C8C8C4D]`}
                value={albumMetaDataDetails.faceDetectionButtonUrl}
                onChange={(e) => handleAlbumMetaDataDetails(e, "album")}
              />
            </div>
            <div>
              <p className="font_normal_12 text-[#8C8C8C] mb-[8px]">
                {" "}
                FaceDetection Button Text
              </p>
              <input
                type="text"
                id="faceDetectionButtonText"
                name="faceDetectionButtonText"
                placeholder="Please enter Face Detection Button text"
                className={`w-full font_normal_14 border-[1px] p-[10px] rounded-[8px] border-[#8C8C8C4D]`}
                value={albumMetaDataDetails.faceDetectionButtonText}
                onChange={(e) => handleAlbumMetaDataDetails(e, "album")}
              />
            </div>
            {/* <div>
              <div className="rounded-[8px] border-[#8C8C8C4D] border-[1px]  h-[100px] flex justify-center items-center ">
                <div
                  onClick={() => inputRefTimelineImages.current.click()}
                  className="flex gap-[10px] items-center border-[1px] border-[#8C8C8C33] cursor-pointer"
                >
                  <input
                    type="file"
                    accept="image/*"
                    multiple
                    onChange={handleTimelineImagesChange}
                    className="hidden"
                    id="albumTimelineImages"
                    name="albumTimelineImages"
                    ref={inputRefTimelineImages}
                  />
                  <img src={coverImageIcon} alt="" />
                  <p className="font_normal_14 text-[#161616]">
                    {TimelineImagesLoading
                      ? "Uploading..."
                      : albumMetaDataDetails?.studio?.transitionFiles
                          ?.length === 0
                      ? "Choose timeline images"
                      : "Add more timeline images"}
                  </p>
                </div>
              </div>
              <div className="mt-[8px] justify-center items-center">
                {albumMetaDataDetails?.studio?.transitionFiles.map(
                  (url, index) => (
                    <div key={index} className="flex items-center mb-2">
                      <img
                        src={url}
                        alt={`Uploaded  ${index}`}
                        className="w-20 h-20 mr-2 object-cover"
                      />
                      <button
                        onClick={() => removeImage(index)}
                        className="text-red-500"
                      >
                        Remove
                      </button>
                      <span className="text-green-500 ml-2">
                        &#10003; Uploaded
                      </span>
                    </div>
                  )
                )}
              </div>
            </div> */}
          </div>
        </div>

        <div className="w-full h-full relative">
          {data?.packageType === "YEARLY" && (
            <div className="absolute w-full h-full bg-[#fff]  bg-opacity-10"></div>
          )}
          <div className="w-full h-full px-[24px] py-[20px]">
            <p className="font_semibold_16 text-[#161616] mb-[24px]">
              Studio Details
            </p>
            <div className="w-full grid grid-cols-2 gap-[24px]">
              <div>
                <p className="font_normal_12 text-[#8C8C8C] mb-[8px]">
                  Studio Name<span className="text-red-500">*</span>
                </p>
                <input
                  type="text"
                  id="studioName"
                  name="studioName"
                  placeholder="Please enter Studio name"
                  className={`w-full font_normal_14 border-[1px] p-[10px] rounded-[8px] border-[#8C8C8C4D]`}
                  value={albumMetaDataDetails?.studio?.studioName}
                  onChange={(e) => handleAlbumMetaDataDetails(e, "studio")}
                />
                {validationErrors?.studio?.studioName && (
                  <p className="font-normal text-[12px] leading-[14px] text-[#fc3535] mt-[5px]">
                    {validationErrors?.studio?.studioName}
                  </p>
                )}
              </div>
              <div>
                <p className="font_normal_12 text-[#8C8C8C] mb-[8px]">
                  Studio Email<span className="text-red-500">*</span>
                </p>
                <input
                  type="text"
                  id="email"
                  name="email"
                  placeholder="Please enter Studio email"
                  className={`w-full font_normal_14 border-[1px] p-[10px] rounded-[8px] border-[#8C8C8C4D]`}
                  value={albumMetaDataDetails?.studio?.email}
                  onChange={(e) => handleAlbumMetaDataDetails(e, "studio")}
                />
                {validationErrors?.studio?.email && (
                  <p className="font-normal text-[12px] leading-[14px] text-[#fc3535] mt-[5px]">
                    {validationErrors?.studio?.email}
                  </p>
                )}
              </div>
              <div>
                <p className="font_normal_12 text-[#8C8C8C] mb-[8px]">
                  Studio Location
                </p>
                <input
                  type="text"
                  id="studioLocation"
                  name="studioLocation"
                  placeholder="Please enter Studio location"
                  className={`w-full font_normal_14 border-[1px] p-[10px] rounded-[8px] border-[#8C8C8C4D]`}
                  value={albumMetaDataDetails?.studio?.studioLocation}
                  onChange={(e) => handleAlbumMetaDataDetails(e, "studio")}
                />
              </div>
              <div>
                <p className="font_normal_12 text-[#8C8C8C] mb-[8px]">
                  Website Url
                </p>
                <input
                  type="text"
                  id="website"
                  name="website"
                  placeholder="Please enter Website Url"
                  className={`w-full font_normal_14 border-[1px] p-[10px] rounded-[8px] border-[#8C8C8C4D]`}
                  value={albumMetaDataDetails?.studio?.website}
                  onChange={(e) => handleAlbumMetaDataDetails(e, "studio")}
                />
              </div>
              <div>
                <p className="font_normal_12 text-[#8C8C8C] mb-[8px]">
                  Mobile Number
                </p>
                <input
                  type="number"
                  id="phoneNumber"
                  name="phoneNumber"
                  placeholder="Please enter Mobile Number"
                  className={`w-full font_normal_14 border-[1px] p-[10px] rounded-[8px] border-[#8C8C8C4D]`}
                  value={albumMetaDataDetails?.studio?.phoneNumber}
                  onChange={(e) => handleAlbumMetaDataDetails(e, "studio")}
                />
              </div>
              <div>
                <p className="font_normal_12 text-[#8C8C8C] mb-[8px]">
                  WhatsApp Number
                </p>
                <input
                  type="number"
                  id="whatsAppNumber"
                  name="whatsAppNumber"
                  placeholder="Please enter WhatsApp Number"
                  className={`w-full font_normal_14 border-[1px] p-[10px] rounded-[8px] border-[#8C8C8C4D]`}
                  value={albumMetaDataDetails?.studio?.whatsAppNumber}
                  onChange={(e) => handleAlbumMetaDataDetails(e, "studio")}
                />
              </div>
              <div>
                <div className="rounded-[8px] border-[#8C8C8C4D] border-[1px] h-[100px] flex justify-center items-center ">
                  {albumMetaDataDetails?.studio?.studioLogo ? (
                    <div className="w-full h-full">
                      <img
                        src={albumMetaDataDetails?.studio?.studioLogo}
                        alt=""
                        className="w-full h-full object-cover"
                      />
                    </div>
                  ) : (
                    <div
                      onClick={() => inputRefStudioLogo.current.click()}
                      className="flex gap-[10px] items-center border-[1px] border-[#8C8C8C33] cursor-pointer"
                    >
                      <input
                        type="file"
                        accept="image/*"
                        onChange={handleStudioLogoChange}
                        className="hidden"
                        id="studioLogo"
                        name="studioLogo"
                        ref={inputRefStudioLogo}
                      />
                      <img src={coverImageIcon} alt="" />
                      <p className="font_normal_14 text-[#161616]">
                        {studioLogoLoading
                          ? "Uploading..."
                          : "Choose Studio Logo"}
                      </p>
                    </div>
                  )}
                </div>
                <div className="flex justify-end px-[24px] mt-[8px]">
                  {albumMetaDataDetails?.studio?.studioLogo && (
                    <p
                      onClick={removeImageStudioLogo}
                      className="font_normal_12 text-[#4D58E3] cursor-pointer"
                    >
                      Change Cover image
                    </p>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="seperatorHorizontalNew "></div>
        <div className="px-[24px] w-full flex gap-[16px] items-center justify-end pt-[12px] pb-[24px]">
          <button
            className="cancelButton font_semibold_12"
            onClick={() => {
              setView(false);
            }}
          >
            Cancel
          </button>
          {metaDataButtonLoading ? (
            <button class=" eventButton">
              <p className="text-[#F6F6F6] font_semibold_12">Loading</p>
            </button>
          ) : (
            <button class=" eventButton" onClick={handleSubmit}>
              <p className="text-[#F6F6F6] font_semibold_12">Save Settings</p>
            </button>
          )}
        </div>
        <div className="container mx-auto p-0 bg-[#ffff] mt-0 mb-0">
          <div className="mb-0"></div>
        </div>
      </div>
    </PopupWrapper>
  );
}

export default MetaDataPopupAlbum;
