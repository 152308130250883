import React, { useContext, useState, useEffect } from "react";
import Lottie from "../../Assets/Animation/Loading";
import { useParams } from "react-router-dom";
import { dataContext } from "../../Context/DataContext";
import MetaDataPopupInvitation from "../../Components/MetaDataPopup/MetaDataPopupInvitation";
import qrCodeIcon from "../../Assets/qrCodeIcon.svg";
import MessagePopup from "../../Components/PopUps/MessagePopup/MessagePopup";
import { userAuthContext } from "../../Context/UserAuthContext";
import Lottie1 from "../../Assets/Animation/LoadingWhite";
import ErrorPopup from "../../Components/PopUps/ErrorPopup/ErrorPopup";
import { PopupWrapper } from "../../UI/PopupWrapper/PopupWrapper";
import AlbumEditForm from "../../Components/AlbumSection/AlbumEditForm";
import axios from "axios";
import AddUser from "../../Components/Forms/AddUserForm/AddUser";
import "./InvitationView.css";
import invitation from "../../Assets/invitation.svg";
import refresshIcon from "../../Assets/refresshIcon.svg";
import detailsIcon from "../../Assets/detailsIcon.svg";
import Breadcrumb from "../../Components/BreadCrumb/BreadCrumb";
import TemplatePurchase from "../../Components/InvitationPurchase/TemplatePurchase";
import { shootmatesalbumdata } from "../../store/firebase-config";
import { ref, uploadBytesResumable } from "firebase/storage";
import compressImage from "../../utils/compressImage";

const InvitationView = () => {
  const [metaDataPopup, setMetaDataPopup] = useState(false);
  const [messagePopup, setMessagePopup] = useState({
    visible: false,
    type: "",
    message: "",
  });
  const [copy, setCopy] = useState(null);
  const [loadingText, setLoadingText] = useState("");
  const [invitationData, setInvitationData] = useState({});
  const [pickedTemplate, setPickedTemplate] = useState({});
  const [editAlbumTemplate, setEditAlbumTemplate] = useState({
    mode: false,
    albumId: null,
  });
  const [addUserPopup, setAddUserPopup] = useState(false);
  const [update, setUpdate] = useState(false);
  const [loading, setLoading] = useState(false);
  const [publishCrmAlbumLoading, setPublishCrmAlbumLoading] = useState(false);
  const { projectId, shootmatesAlbumId } = useParams();
  const [error, setError] = useState({
    errorStatus: false,
    errorMessage: "",
    header: "",
  });
  const { userIdToken, currentUser, verifyUser } = useContext(userAuthContext);
  const tokenData = userIdToken || currentUser?.accessToken;
  const { studioId, roleSelected } = useContext(dataContext);
  const [imagesToUpload, setImagesToUpload] = useState({});
  const [uploadLoading, setUploadLoading] = useState(false);
  const [progress, setProgress] = useState([]);

  useEffect(() => {
    verifyUser();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    if (studioId) {
      getInvitationTemplateData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [studioId, update]);

  const getInvitationTemplateData = async () => {
    try {
      setLoading(true);
      let config = {
        method: "get",
        url: `https://asia-south1-shootmates.cloudfunctions.net/shootmatesAlbums/api/shootmates/albumoprtn/getInvitationDetails/${projectId}/${studioId}/${shootmatesAlbumId}`,
        headers: {
          Authorization: `Bearer ${tokenData}`,
          "Content-Type": "application/json",
        },
      };
      const response = await axios(config);
      if (response.status === 200) {
        setInvitationData(response?.data?.data);
        setPickedTemplate(response?.data?.data);
        setLoading(false);
      }
      setLoading(false);
    } catch (error) {
      console.log("error", error);
      setLoading(false);
    }
  };

  const handleSave = async () => {
    setUploadLoading(true);
    if (Object.keys(imagesToUpload).length === 0) {
      publishCrmInvitationTemplate(pickedTemplate);
      return;
    }
    try {
      let dataToTransfer = pickedTemplate;
      await Promise.all(
        Object.entries(imagesToUpload)?.map(async (obj, index) => {
          let mediaArray = [];
          // let key = obj[0]
          let value = obj[1];
          for (let i = 0; i < value?.images?.length; i++) {
            let file = null;
            const selectedFile = value?.images[i];
            if (selectedFile?.type?.startsWith("image")) {
              const fileSize = selectedFile?.size / 1048576;
              if (fileSize > 1) {
                file = await compressImage(selectedFile);
              } else {
                file = selectedFile;
              }
            } else {
              file = selectedFile;
            }

            if (file) {
              let randomId = Math.floor(Date.now() * Math.random());
              let fileName = `${randomId}_${file?.name?.replace(/ /g, "")}`;
              let filePath = `${studioId}/${projectId}/${fileName}`;
              const storageRef = ref(shootmatesalbumdata, filePath);
              const uploadTask = uploadBytesResumable(storageRef, file);
              uploadTask.on("state_changed", (snapshot) => {
                const uploadprogress =
                  (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                setProgress((prevProgress) => {
                  const newProgress = [...prevProgress];
                  newProgress[index] = {
                    progress: uploadprogress,
                    type: value?.type,
                  };
                  return newProgress;
                });
              });
              await uploadTask;
              const url = `https://storage.googleapis.com/shootmatesalbumdata/${filePath}`;
              if (value?.type === "GALLERY") {
                mediaArray.push({ url });
              } else mediaArray.push(url);
            } else {
              let orginalFile =
                dataToTransfer?.sections[value?.index]?.sectionData || [];
              if (value?.type === "GALLERY") {
                mediaArray.push({ url: orginalFile?.media[i]?.url });
              } else mediaArray.push(orginalFile?.coverImage);
            }
          }
          if (value?.type === "COVERIMAGE" || value?.type === "THANKING") {
            dataToTransfer.sections[value?.index].sectionData.coverImage =
              mediaArray[0];
          } else if (value?.type === "STUDIO") {
            dataToTransfer.sections[value?.index].sectionData.studioLogo =
              mediaArray[0];
          } else if (value?.type === "GALLERY") {
            dataToTransfer.sections[value?.index].sectionData.media =
              mediaArray;
          }
        })
      );
      return publishCrmInvitationTemplate(dataToTransfer);
    } catch (error) {
      console.log(error.message);
    }
  };

  const publishCrmInvitationTemplate = async (dataToTransfer) => {
    try {
      setPublishCrmAlbumLoading(true);
      setLoadingText("Saving invitation..");
      verifyUser();
      let config = {
        method: "patch",
         url: `https://asia-south1-shootmates.cloudfunctions.net/shootmatesMemshotsServices/api/shootmates/memshotsservices/publishShootmatesAlbumTemplate/${projectId}/${studioId}/${shootmatesAlbumId}`,
        // url: `https://asia-south1-shootmates.cloudfunctions.net/test_shootmatesMemshotsServices/api/shootmates/memshotsservices/publishShootmatesAlbumTemplate/${projectId}/${studioId}/${shootmatesAlbumId}`,

        data: dataToTransfer,
        headers: {
          Authorization: `Bearer ${tokenData}`,
          "Content-Type": "application/json",
        },
      };
      const response = await axios(config);
      if (response.status === 200) {
        setPublishCrmAlbumLoading(false);
        setMessagePopup({
          visible: true,
          type: "success",
          message: "Invitation templated saved successfully..",
        });
        setImagesToUpload((prevState) => {
          const transformedState = dataToTransfer?.sections?.reduce(
            (acc, section, index) => {
              if (section?.sectionProps?.sectionType === "GALLERY") {
                acc[index] = {
                  index,
                  type: section?.sectionProps?.sectionType,
                  images: section?.sectionData?.media.map(() => ""),
                };
              }
              return acc;
            },
            {}
          );

          return transformedState;
        });
      } else {
        setPublishCrmAlbumLoading(false);
        setMessagePopup({
          visible: true,
          type: "error",
          message: "Please try again..",
        });
      }
      setUploadLoading(false);
    } catch (error) {
      setPublishCrmAlbumLoading(false);
      setUploadLoading(false);
      console.log("error", error);
    }
  };
  const publishCrmInvitationToMemshots = async () => {
    setPublishCrmAlbumLoading(true);
    setLoadingText("Publishing invitation..");
    try {
      verifyUser();
      let config = {
        method: "post",
         url: `https://asia-south1-shootmates.cloudfunctions.net/shootmatesMemshotsServices/api/shootmates/albumoperations/publish/album/${projectId}/${studioId}/${shootmatesAlbumId}`,

        // url: `https://asia-south1-shootmates.cloudfunctions.net/test_shootmatesMemshotsServices/api/shootmates/albumoperations/publish/album/${projectId}/${studioId}/${shootmatesAlbumId}`,

        headers: {
          Authorization: `Bearer ${tokenData}`,
          "Content-Type": "application/json",
        },
      };
      const response = await axios(config);
      if (response.status === 200) {
        setPublishCrmAlbumLoading(false);
        setMessagePopup({
          visible: true,
          type: "success",
          message: response?.data?.msg,
        });
      } else {
        setPublishCrmAlbumLoading(false);
        setMessagePopup({
          visible: true,
          type: "error",
          message: "Please try again..",
        });
      }
    } catch (error) {
      console.log("error", error);
      setPublishCrmAlbumLoading(false);
    }
  };
  const handleAlbumMetaDataButton = () => {
    if (!invitationData?.memshotAlbumId) {
      setError({
        errorMessage: "Memshots Album is Processing Please Wait",
        errorStatus: true,
        header: "Message",
      });
      return;
    }
    setMetaDataPopup(true);
  };

  const copyToClipboard = () => {
    const linkText = `${invitationData?.slugId}`;
    const linkToCopy = `https://${
      roleSelected?.studioUniqueText || "invitation"
    }.memshots.com/${linkText}`;
    const tempInput = document.createElement("input");
    tempInput.value = linkToCopy;
    document.body.appendChild(tempInput);
    tempInput.select();
    tempInput.setSelectionRange(0, 99999);
    document.execCommand("copy");
    document.body.removeChild(tempInput);
    setCopy(true);
    setTimeout(() => {
      setCopy(false);
    }, 1000);
  };

  return (
    <div className="invitationDetailContainer min-h-[100vh]">
      <Breadcrumb
        update={update}
        setUpdate={setUpdate}
        items={[
          { label: "Event", url: "/Event" },
          { label: "Event Detail", url: `/Event/${projectId}` },
        ]}
      />
      {loading ? (
        <>
          <Lottie />
        </>
      ) : (
        <div>
          {publishCrmAlbumLoading && <Lottie1 loadingText={loadingText} />}
          {messagePopup?.visible && (
            <MessagePopup
              messagePopup={messagePopup}
              setMessagePopup={setMessagePopup}
            />
          )}
          {editAlbumTemplate?.mode && (
            <AlbumEditForm
              update={update}
              setUpdate={setUpdate}
              visible={editAlbumTemplate?.mode}
              setAlbum={setEditAlbumTemplate}
              projectId={projectId}
              albumId={editAlbumTemplate?.albumId}
            />
          )}
          {addUserPopup && (
            <AddUser
              addUserPopup={addUserPopup}
              setAddUserPopup={setAddUserPopup}
              projectId={projectId}
              albumId={shootmatesAlbumId}
            />
          )}
          {error.errorStatus === true && (
            <PopupWrapper visible={error.errorStatus}>
              <ErrorPopup error={error} setError={setError} />
            </PopupWrapper>
          )}
          {metaDataPopup && (
            <MetaDataPopupInvitation
              update={update}
              setUpdate={setUpdate}
              visible={metaDataPopup}
              setView={setMetaDataPopup}
              data={invitationData}
              studioId={studioId}
              shootmatesAlbumId={shootmatesAlbumId}
              projectId={projectId}
            />
          )}
          <div className="flex justify-between items-center  px-[20px] py-[12px]">
            <div className="flex gap-[8px] items-center">
              <img src={invitation} alt="" className="w-[62px] h-[62px]" />
              <div className=" flex flex-col gap-[8px]">
                <p className="font_semibold_20 text-[#161616]">
                  {invitationData?.albumName}
                </p>
                <p className="font_normal_14  text-[#8C8C8C] ">
                  {invitationData?.albumName}
                </p>
              </div>
            </div>
            <div className="flex items-center">
              {/* <div className=' cursor-pointer mr-[14px] py-[5px] pl-[5px] pr-[10px] border-[1px] border-[#8C8C8C4D] flex items-center gap-[10px] rounded-[4px]'>
								<img src={albumDetailPreviewIcon} alt="" />
								<p className='font_normal_11 text-[#161616]'>Preview</p>
							</div> */}
              <button
                onClick={publishCrmInvitationToMemshots}
                className="eventButton font_semibold_12"
              >
                Publish Invitation
              </button>
            </div>
          </div>
          <div className="seperatorHorizontalNew mt-[6px]"></div>
          <div className="px-[20px] py-[16px] flex justify-between items-center">
            {/* <div className='flex items-center gap-[16px]'>
							<div className='shortButton'>
								<img src={options} alt="" className='w-[16px] h-[16px]' />
								<p className='font_normal_11 text-[#161616]'>Sections</p>
							</div>
							<div
								onClick={() => {
									setAddUserPopup(true)
								}}
								className='shortButton'>
								<img src={userIcon} alt="" />
								<p className='font_normal_11 text-[#161616]'>Add User</p>
							</div>
						</div> */}
            <div className="flex items-center gap-[16px]">
              <div onClick={handleAlbumMetaDataButton} className="shortButton">
                <img src={detailsIcon} alt="" className="w-[16px] h-[16px]" />
                <p className="font_normal_11 text-[#161616]">Settings</p>
              </div>
              <div
                onClick={() => {
                  setUpdate(!update);
                }}
                className="shortButton"
              >
                <img src={refresshIcon} alt="" className="w-[16px] h-[16px]" />
                <p className="font_normal_11 text-[#161616]">Refresh</p>
              </div>
              <div
                onClick={handleSave}
                className="px-[10px] py-[5px] rounded-[4px] bg-[#4D58E3] cursor-pointer"
              >
                <p className="font_normal_11 text-[#FFFFFF]">Save Changes</p>
              </div>
              <div
                onClick={() => copyToClipboard("live")}
                className="shortButtonBlack"
              >
                <img src={qrCodeIcon} alt="" className="w-[16px] h-[16px]" />
                <p className="font_normal_11 text-[#F6F6F6]">
                  {copy ? "Invitation Link Copied!" : "Invitation Link "}
                </p>
              </div>
            </div>
          </div>
          <div className="seperatorHorizontalNew"></div>

          <div>
            <TemplatePurchase
              templateJson={pickedTemplate}
              setTemplateJson={setPickedTemplate}
              preview={false}
              setImagesToUpload={setImagesToUpload}
              uploadLoading={uploadLoading}
              progress={progress}
            />
            {/* <InvitationEditForm
              setUpdate={setUpdate}
              update={update}
              formValues={formValues}
              setFormValues={setFormValues}
              invitationData={invitationData}
              setInvitationData={setInvitationData}
              pickedTemplate={pickedTemplate}
              setPickedTemplate={setPickedTemplate}
            /> */}
          </div>
        </div>
      )}
    </div>
  );
};
export default InvitationView;
