import React from "react";
import { PopupWrapper } from "../../UI/PopupWrapper/PopupWrapper";
import { NewPopUpWrapper } from "../../UI/PopupWrapper/NewPopUpWrapper";

function ServicePayPopUp({
  visible,
  details,
  onClose,
  flexiPackagePaymentInitialise,
  loading,
}) {
  return (
    <NewPopUpWrapper visible={visible}>
      {console.log(details,"ser pay")}
      <div className="bg-[#fff] popUpShadow min-h-[300px] max-h-[350px] min-w-[400px] rounded-[10px] flex flex-col trialPop mt-[5%]   ">
        <div className="flex  w-full border-opacity-20 justify-between pb-[20px] items-start border-b-[1px] border-[#475467] ">
          <div className=" flex items-center gap-[12px]">
            <button className=" headDiv">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
              >
                <path d="M11 15H6L13 1V9H18L11 23V15Z" fill="#373CBD" />
              </svg>
            </button>
            <div>
              <p className="FreeTrial flex items-center gap-[4px]">
                {details?.packageDetails?.packageName}
                <span className="days">
                  {details?.packageDetails?.packageType}
                </span>
              </p>
              <p className="">
                Total Storage{" "}
                <span className="font-[600]">
                  {details?.packageDetails?.storageInGb}{" "}
                  {typeof details?.packageDetails?.storageInGb === "number"
                    ? "GB"
                    : ""}
                </span>{" "}
              </p>
            </div>
          </div>
          <button onClick={() => onClose(false)}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
            >
              <path
                d="M18 6L6 18M6 6L18 18"
                stroke="#667085"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </button>
        </div>
        <div
          className={`mt-[25px]  PaySelBox  p-[20px] w-full flex justify-between items-start `}
        >
          <div className="w-full ">
            <div className="rupees justify-between items-start flex">
              <p>₹ {details?.packageDetails?.price}</p>
              <button className="iconSel">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="14"
                  height="14"
                  viewBox="0 0 14 14"
                  fill="none"
                >
                  <path
                    d="M11.6666 3.5L5.24992 9.91667L2.33325 7"
                    stroke="white"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </button>
            </div>
            <div className="flex justify-between w-full items-start">
              <p className="oneTimeOrEmi"> One Time Pay </p>
              <p className="expiry">
                {details?.packageDetails?.storageTenureInMonth} Month Expiry
              </p>
            </div>
          </div>
        </div>

        <div
          className={`flex  mt-[25px]
               
              items-center w-full gap-[12px] justify-center`}
        >
          <button onClick={() => onClose(false)} className="cancelButtonPop">
            Cancel
          </button>
          <button
            disabled={loading}
            onClick={() =>
              flexiPackagePaymentInitialise(
                details?.studioId,
                details?.eventId,
                details?.invoiceId,
                details?.albumId
              )
            }
            className="continueButton"
          >
            {loading ? "Loading" : "Continue"}
          </button>
        </div>
      </div>
    </NewPopUpWrapper>
  );
}

export default ServicePayPopUp;
