import React, { useContext, useEffect, useRef, useState } from "react";
import hamburger from "../../Assets/NavbarIcons/hamburger.svg";
import "./HamburgerDropDown.css";
import { userAuthContext } from "../../Context/UserAuthContext";

function HamburgerDropDown({ googleSignOut }) {
  const [dropdown, setDropdown] = useState(false);
  const modalRef = useRef();
  const { currentUser } = useContext(userAuthContext);
  useEffect(() => {
    const checkIfClickedOutside = (e) => {
      // If the modal is open and the clicked target is not within the modal,
      //then close the modal
      if (
        dropdown &&
        modalRef.current &&
        !modalRef.current.contains(e.target)
      ) {
        setDropdown(false);
      }
    };
    document.addEventListener("mousedown", checkIfClickedOutside);
    // Cleanup the event listener
    return () => {
      document.removeEventListener("mousedown", checkIfClickedOutside);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dropdown]);
  return (
    <div className="dropdown z-[100]" ref={modalRef}>
      <img
        onClick={(e) => {
          setDropdown((prevState) => {
            e.stopPropagation();
            return !prevState;
          });
        }}
        src={hamburger}
        alt=""
        className="w-[24px] h-[24px]"
      />
      {dropdown && (
        <div className="signOutdropdownContent  z-[100] ">
          <div className="flex relative items-center justify-end gap-[10px] px-[20px] shrink-0">
            <svg
              onClick={(e) => {
                setDropdown((prevState) => {
                  e.stopPropagation();
                  return !prevState;
                });
              }}
              className="absolute -top-2 z-[10]  opacity-70 right-2"
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
            >
              <path
                d="M12 12L7 17M12 12L17 7M12 12L17 17M12 12L7 7"
                stroke="#161616"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              ></path>
            </svg>

            <p className="font_medium_13 mr-9 text-[#828282]">
              {currentUser?.email}
            </p>
          </div>
          <div className="mr-9">
            <button
              onClick={googleSignOut}
              className="font_medium_16  whitespace-nowrap"
            >
              Log Out
            </button>
          </div>
        </div>
      )}
    </div>
  );
}

export default HamburgerDropDown;
