import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { dataContext } from "../../Context/DataContext";
import { userAuthContext } from "../../Context/UserAuthContext";
import "./EventDetailView.css";
import ClientForm from "../../Components/Forms/ClientForm/ClientForm";
import AlbumCreateForm from "../../Components/AlbumSection/AlbumCreateForm";
import MessagePopupAlert from "../../Components/MessagePopupAlert/MessagePopupAlert";
import ConfirmationPopup from "../../Components/ConfirmationPopup/ConfirmationPopup";
import SelectionToolForm from "../../Components/SelectionToolSection/SelectionToolForm";
import FaceDetectionForm from "../../Components/FaceDetectionSection/FaceDetectionForm";
import PhotoShareToolForm from "../../Components/PhotoShareToolForm/PhotoShareToolForm";
import Breadcrumb from "../../Components/BreadCrumb/BreadCrumb";
import Lottie from "../../Assets/Animation/Loading";
import LeadForm from "../../Components/Forms/LeadForm/LeadForm";
import eventSignatureAlbum from "../../Assets/eventSignatureAlbum.svg";
import photoShare from "../../Assets/photoshare.svg";
import invitation from "../../Assets/invitation.svg";
import eventInvitation from "../../Assets/eventInvitation.svg";
import eventFaceDetection from "../../Assets/eventFaceDetection.svg";
import InvitationCreateFormNew from "../../Components/AlbumSection/InvitationCreateFormNew";
import SelectionToolSectionDuplication from "../../Components/SelectionToolSectionDuplication/SelectionToolSectionDuplication";
import ServicePayPopUp from "../../Components/ServicePayPopUp/ServicePayPopUp";
import NoData from "../../Components/NoData/NoData";

function EventDetailView() {
  const { userIdToken, currentUser, verifyUser } = useContext(userAuthContext);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [alertPopup, setAlertPopup] = useState(false);
  const [pricingData, setPricingData] = useState({});
  const tokenData = userIdToken || currentUser?.accessToken;
  const {
    studioId,
    getStudioStorageOfAService,
    roleSelected,
    bytesToGB,
    loading,
    setLoading,
    flexiPackagePayment,
    usedSize,
    activePackages,
    flexiPackagePaymentInitialise,
  } = useContext(dataContext);
  const { eventId } = useParams();
  const [update, setUpdate] = useState(false);
  // const [loading, setLoading] = useState(false);
  const [leadDetails, setLeadDetails] = useState([]);
  const [clientEdit, setClientEdit] = useState(false);
  const [leadEdit, setLeadEdit] = useState(false);
  const [createAlbum, setCreateAlbum] = useState(false);
  const [selectionToolDuplication, setSelectionDuplication] = useState(false);
  const [selectedTool, setSelectedTool] = useState({});
  const [createInvitation, setCreateInvitation] = useState(false);
  const [createSelectionTool, setCreateSelectionTool] = useState(false);
  const [createLiveFaceDetection, setCreateLiveFaceDetection] = useState(false);
  const [createPhotoShare, setCreatePhotoShare] = useState(false);
  const [storageData, setStorageData] = useState({});
  const [storageLoading, setStorageLoading] = useState(false);
  const [usedPercentage, setUsedPercentage] = useState();
  const [forPayment, setForPayment] = useState({
    data: {},
    bool: false,
  });

  const navigate = useNavigate();
  const values = {
    hasEmailTask: false,
    isDrag: true,
    isCrew: false,
    workFlowTaskColor: "#000000",
  };

  useEffect(() => {
    verifyUser();
    if (studioId) {
      getData();
      getProjectDetails();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [studioId, update]);
  useEffect(() => {
    if (leadDetails?.albums) {
      leadDetails?.albums.forEach((album) => {
        handleStorageByService(album?.shootmatesalbumId);
      });
    }
  }, [leadDetails]);

  const getData = async () => {
    try {
      const response = await axios.get(`/json/pricing.json`);
      setPricingData(response?.data?.packages);
    } catch (err) {
      console.log(err);
    }
  };

  const getProjectDetails = async () => {
    try {
      setLoading(true);
      let config = {
        method: "get",
        url: `https://asia-south1-shootmates.cloudfunctions.net/shootMatesProject/api/shootmates/project/eventdetails/${eventId}/${studioId}`,
        headers: {
          Authorization: `Bearer ${tokenData}`,
          "Content-Type": "application/json",
        },
      };
      const response = await axios(config);
      if (response.status === 200) {
        setLeadDetails(response?.data?.data);
        setLoading(false);
      }
    } catch (error) {
      console.log("error", error);
      setLoading(false);
    }
  };

  const clientObj = leadDetails?.client;
  const leadObj = leadDetails;

  const dayOfWeek = (date) => {
    const currentDate = new Date(date);
    let dayOfWeek = currentDate.toLocaleString("default", { weekday: "long" });
    if (dayOfWeek === "Monday") {
      dayOfWeek = "MON";
    } else if (dayOfWeek === "Tuesday") {
      dayOfWeek = "TUE";
    } else if (dayOfWeek === "Wednesday") {
      dayOfWeek = "WED";
    } else if (dayOfWeek === "Thursday") {
      dayOfWeek = "THU";
    } else if (dayOfWeek === "Friday") {
      dayOfWeek = "FRI";
    } else if (dayOfWeek === "Saturday") {
      dayOfWeek = "SAT";
    } else if (dayOfWeek === "Sunday") {
      dayOfWeek = "SUN";
    } else dayOfWeek = "";
    return dayOfWeek;
  };

  const day = (date) => {
    const currentDate = new Date(date);
    const day = currentDate.getDate();
    return day;
  };

  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
  };

  const toggleAlert = () => {
    setAlertPopup(!isModalOpen);
  };

  const navigateToAlbum = (eventId, obj) => {
    const basePath = `/Event/${eventId}`;
    let path = "";
    switch (obj?.albumType) {
      case "FACEDETECTION":
        path = `${basePath}/facedetection/${obj?.shootmatesalbumId}`;
        break;
      case "INVITATION":
        path = `${basePath}/invitationdetails/${obj?.shootmatesalbumId}`;
        break;
      case "PHOTOSHARETOOL":
        path = `${basePath}/photosharetool/${obj?.shootmatesalbumId}`;
        break;
      case "SELECTIONTOOL":
        path = `${basePath}/selectiontool/${obj?.shootmatesalbumId}`;
        break;
      default:
        path = `${basePath}/albumdetail/${obj?.shootmatesalbumId}`;
    }
    navigate(path);
  };

  const getAlbumLinkText = (albumType) => {
    switch (albumType) {
      case "FACEDETECTION":
        return "AI PHOTO DELIVERY";
      case "SELECTIONTOOL":
        return "PHOTO SELECTION";
      case "INVITATION":
        return "INVITATION";
      case "PHOTOSHARETOOL":
        return "PHOTOSHARE";
      default:
        return "ALBUM";
    }
  };

  const handleStorageByService = async (id) => {
    try {
      setStorageLoading(true);
      const res = await getStudioStorageOfAService(roleSelected?.studioId, id);
      setStorageData((prev) => ({
        ...prev,
        [id]: res?.data,
      }));
      setStorageLoading(false);
    } catch (error) {
      console.error("Error fetching storage data:", error);
    }
  };

  const handlePayment = (
    studioId,
    albumId,
    packageCode,
    eventId,
    packageDetails,
    invoiceId
  ) => {
    const newData = {
      studioId,
      albumId,
      packageCode,
      eventId,
      packageDetails,
      invoiceId,
    };

    setForPayment((prevState) => ({
      ...prevState,
      data: {
        ...prevState.data,
        ...newData,
      },
      bool: true,
    }));
  };

  const handleCloseForPaymentPop = () => {
    setForPayment((prevState) => ({
      data: {},
      bool: false,
    }));
  };
  const bytesToMB = (bytes) => bytes / 1024 ** 2;

  return (
    <div className="eventDetailContainer min-h-[100vh]">
      {forPayment?.bool && Object?.keys(forPayment?.data)?.length > 0 && (
        <ServicePayPopUp
          flexiPackagePaymentInitialise={flexiPackagePaymentInitialise}
          details={forPayment?.data}
          visible={forPayment?.bool}
          loading={loading}
          onClose={handleCloseForPaymentPop}
        />
      )}

      <Breadcrumb
        update={update}
        setUpdate={setUpdate}
        items={[{ label: "Event", url: "/Event" }]}
      />
      {clientEdit && (
        <ClientForm
          visible={clientEdit}
          setpopUp={setClientEdit}
          clientObj={clientObj}
          idkey="update"
        />
      )}
      {leadEdit && (
        <LeadForm
          visible={leadEdit}
          setpopUp={setLeadEdit}
          leadObj={leadObj}
          idkey="update"
        />
      )}

      <ConfirmationPopup isOpen={isModalOpen} toggleModal={toggleModal} />
      <MessagePopupAlert isOpen={alertPopup} toggleModal={toggleAlert} />
      {createAlbum && (
        <AlbumCreateForm
          setUpdate={setUpdate}
          priceData={pricingData}
          update={update}
          visible={createAlbum}
          setAlbum={setCreateAlbum}
          values={values}
          eventType={
            leadDetails?.eventType === "CORPORATE EVENTS" ||
            leadDetails?.eventType === "OTHER"
              ? "CORPORATE_EVENTS"
              : leadDetails?.eventType
          }
          packageType={leadDetails?.packageType}
        />
      )}

      {createInvitation && (
        <InvitationCreateFormNew
          setUpdate={setUpdate}
          priceData={pricingData}
          update={update}
          visible={createInvitation}
          setAlbum={setCreateInvitation}
          values={values}
          eventType={
            leadDetails?.eventType === "CORPORATE EVENTS"
              ? "CORPORATE_EVENTS"
              : leadDetails?.eventType
          }
          // eventType={leadDetails?.eventType}
          packageType={leadDetails?.packageType}
        />
      )}
      {createLiveFaceDetection && (
        <FaceDetectionForm
          setUpdate={setUpdate}
          priceData={pricingData}
          update={update}
          visible={createLiveFaceDetection}
          setAlbum={setCreateLiveFaceDetection}
          values={values}
          packageType={leadDetails?.packageType}
        />
      )}
      {createPhotoShare && (
        <PhotoShareToolForm
          setUpdate={setUpdate}
          priceData={pricingData}
          update={update}
          visible={createPhotoShare}
          setAlbum={setCreatePhotoShare}
          values={values}
          packageType={leadDetails?.packageType}
        />
      )}
      {createSelectionTool && (
        <SelectionToolForm
          setUpdate={setUpdate}
          priceData={pricingData}
          update={update}
          visible={createSelectionTool}
          setAlbum={setCreateSelectionTool}
          values={values}
          packageType={leadDetails?.packageType}
        />
      )}
      {selectionToolDuplication && (
        <SelectionToolSectionDuplication
          update={update}
          priceData={pricingData}
          setUpdate={setUpdate}
          visible={selectionToolDuplication}
          setAlbum={setSelectionDuplication}
          selectedToolData={selectedTool}
          packageType={leadDetails?.packageType}
        />
      )}

      {loading ? (
        <>
          <Lottie />
        </>
      ) : (
        <>
          <div className="flex gap-[65px] px-[20px] py-[12px] ">
            <div className="flex">
              <div className=" eventDetailSideSeperator pr-[7px] flex flex-col gap-[3px] items-center h-fit">
                <p
                  className={`font_medium_14 ${
                    dayOfWeek(leadDetails?.startDate) === "SUN"
                      ? "text-[#E34D4D]"
                      : "text-[#161616B2]"
                  }`}
                >
                  {dayOfWeek(leadDetails?.startDate)}
                </p>
                <p
                  className={`font_medium_32 ${
                    dayOfWeek(leadDetails?.startDate) === "SUN"
                      ? "text-[#E34D4D]"
                      : "text-[#161616B2]"
                  }`}
                >
                  {day(leadDetails?.startDate)}
                </p>
              </div>
              <div className=" pl-[13px] flex flex-col gap-[13px]">
                <p className="font_medium_14 text-[#8C8C8C]">
                  {leadDetails?.eventType}
                </p>
                <p className="font_semibold_20 text-[#161616] ">
                  {leadDetails?.leadName}
                </p>
                <p className="text-xs  text-[#161616] font-[400] ">
                  {leadDetails?.packageType?.charAt(0).toUpperCase() +
                    leadDetails?.packageType?.slice(1).toLowerCase()}{" "}
                  Package{" "}
                </p>
              </div>
            </div>
          </div>
          <div className="seperatorHorizontalNew mt-[6px]"></div>
          <div className=" px-5 py-4 flex flex-col sm:flex-row sm:justify-between gap-4 w-full">
            <div
              className={`${
                leadDetails?.packageType === "TRIAL" && "opacity-40"
              } cursor-pointer p-[7px] h-[70px] flex items-center justify-between rounded-lg border border-gray-300 flex-1 hover:bg-gray-50 transition-colors`}
              onClick={() =>
                leadDetails?.packageType === "TRIAL" ? "" : setCreateAlbum(true)
              }
            >
              <div className="flex gap-[6px] items-center">
                <div className="bg-red-100 flex-shrink-0 rounded-lg p-2">
                  <img
                    src={eventSignatureAlbum}
                    alt="album"
                    className="w-[20px] h-[20px]"
                  />
                </div>
                <p className="font-medium text-[14px]  text-black">
                  Signature Album
                </p>
              </div>
              <div>
                <p className="font-medium  text-[14px] text-red-500">Add</p>
              </div>
            </div>

            <div
              className={`${
                leadDetails?.packageType === "TRIAL" && "opacity-40"
              }  cursor-pointer p-[7px] h-[70px] flex items-center justify-between rounded-lg border border-gray-300 flex-1 hover:bg-gray-50 transition-colors`}
              onClick={() =>
                leadDetails?.packageType === "TRIAL"
                  ? ""
                  : setCreateInvitation(true)
              }
            >
              <div className="flex gap-[6px] items-center">
                <div className="bg-blue-100 rounded-lg p-2">
                  <img
                    src={invitation}
                    alt="invitation"
                    className="w-[20px] h-[20px]"
                  />
                </div>
                <p className="font-medium text-[14px] text-black">
                  Online Invitation
                </p>
              </div>
              <div>
                <p className="font-medium text-[14px] text-blue-500">Add</p>
              </div>
            </div>

            <div
              className="cursor-pointer p-[7px] h-[70px] flex items-center justify-between rounded-lg border border-gray-300 flex-1 hover:bg-gray-50 transition-colors"
              onClick={() => setCreateSelectionTool(true)}
            >
              <div className="flex gap-[6px] items-center">
                <div className="bg-blue-100 rounded-lg p-2">
                  <img
                    src={eventInvitation}
                    alt="invitation"
                    className="w-7 h-7"
                  />
                </div>
                <p className="font-medium text-[14px] text-black">
                  Photo Selection
                </p>
              </div>
              <div>
                <p className="font-medium text-[14px] text-blue-500">Add</p>
              </div>
            </div>

            <div
              className="cursor-pointer p-[7px] h-[70px] flex items-center justify-between rounded-lg border border-gray-300 flex-1 hover:bg-gray-50 transition-colors"
              onClick={() => setCreateLiveFaceDetection(true)}
            >
              <div className="flex gap-[6px] items-center">
                <div className="bg-yellow-100 rounded-lg p-2">
                  <img
                    src={eventFaceDetection}
                    alt="faceDetection"
                    className="w-[20px] h-[20px]"
                  />
                </div>
                <p className="font-medium text-[14px] text-black">
                  AI Photo Delivery
                </p>
              </div>
              <div>
                <p className="font-medium text-sm text-yellow-500">Add</p>
              </div>
            </div>

            <div
              className={`${
                leadDetails?.packageType === "TRIAL" && "opacity-40"
              } cursor-pointer p-[7px] h-[70px] flex items-center justify-between rounded-lg border border-gray-300 flex-1 hover:bg-gray-50 transition-colors`}
              onClick={() =>
                leadDetails?.packageType === "TRIAL"
                  ? ""
                  : setCreatePhotoShare(true)
              }
            >
              <div className="flex gap-[6px] items-center">
                <div className="bg-yellow-100 rounded-lg p-2">
                  <img
                    src={photoShare}
                    className="w-[20px] h-[20px]"
                    alt="faceDetection"
                  />
                </div>
                <p className="font-medium text-[14px] text-black">
                  Photo Share Tool
                </p>
              </div>
              <div>
                <p className="font-medium text-[14px] text-yellow-500">Add</p>
              </div>
            </div>
          </div>
          <div className="seperatorHorizontalNew mt-[6px]"></div>
          {/* <div className="eventDetailsTable space-y-4">
            {leadDetails?.albums?.map((obj, i) => {
              return (
                <div
                  key={i}
                  className="flex justify-between gap-[20px] items-center p-3 bg-white rounded-lg shadow-md hover:bg-blue-100 cursor-pointer transition-colors"
                  onClick={() =>
                    obj?.paymentStatus == "PENDING"
                      ? ""
                      : navigateToAlbum(eventId, obj)
                  }
                >
                  <div className="flex justify-between gap-[30px] items-start">
                    <div
                      className={`rounded-lg p-3 ${
                        obj?.albumType === "FACEDETECTION"
                          ? "bg-yellow-100"
                          : obj?.albumType === "SELECTIONTOOL"
                          ? "bg-blue-100"
                          : obj?.albumType === "INVITATION"
                          ? "bg-blue-100"
                          : obj?.albumType === "PHOTOSHARETOOL"
                          ? "bg-blue-100"
                          : "bg-red-100"
                      }`}
                    >
                      <img
                        src={
                          obj?.albumType === "FACEDETECTION"
                            ? eventFaceDetection
                            : obj?.albumType === "SELECTIONTOOL"
                            ? eventInvitation
                            : obj?.albumType === "INVITATION"
                            ? invitation
                            : obj?.albumType === "PHOTOSHARETOOL"
                            ? photoShare
                            : eventSignatureAlbum
                        }
                        alt={obj?.albumType}
                        className="w-7 h-7"
                      />
                    </div>

                    <div className="ml-3 flex flex-grow flex-col gap-1">
                      <p className="font-medium text-lg text-gray-900">
                        {obj?.albumName}
                      </p>
                      <p className={`text-base transition-colors `}>
                        {getAlbumLinkText(obj?.albumType)}
                      </p>
                    </div>

                    {obj?.packageType == "FLEXI" && storageLoading ? (
                      <p className="text-[16px] font-[400] ">Loading...</p>
                    ) : (
                      <div className="storage ">
                        <p>My Storage</p>

                        <div className="w-[300px] mt-[16px] bg-[#DDD]  h-[15px] rounded-[4px]">
                          <div
                            className="bg-blue-600 h-4 rounded-full"
                            style={{
                              width: `${
                                (storageData[obj?.shootmatesalbumId]
                                  ?.totalMediaCount /
                                  storageData[obj?.shootmatesalbumId]
                                    ?.totalMediaSize) *
                                  100 || 0
                              }%`,
                            }}
                          ></div>
                        </div>
                        <div className="storageusedText mt-[9px]">
                          {bytesToGB(
                            storageData[obj?.shootmatesalbumId]
                              ?.totalMediaCount || 0
                          )}{" "}
                          GB used,{" "}
                          {bytesToGB(
                            storageData[obj?.shootmatesalbumId]
                              ?.totalMediaSize || 0
                          )}{" "}
                          GB available
                        </div>
                      </div>
                    )}
                  </div>

                  {obj?.albumType === "SELECTIONTOOL" && (
                    <button
                      className="ml-auto py-1 px-2 bg-blue-500 text-white rounded-md hover:bg-blue-600 transition-colors"
                      onClick={(e) => {
                        e.stopPropagation();
                        setSelectionDuplication(true);
                        setSelectedTool(obj);
                      }}
                    >
                      Duplicate
                    </button>
                  )}

                  {obj?.packageDetails?.packageCode &&
                  obj?.packageType == "FLEXI" &&
                  obj?.paymentStatus == "PENDING" ? (
                    <div className="flex-col flex">
                      <p className="whitespace-nowrap">
                        Payment Status - {obj?.paymentStatus}
                      </p>

                      <button
                        onClick={(e) => {
                          e.stopPropagation();
                          flexiPackagePayment(
                            studioId,
                            obj?.shootmatesalbumId,
                            obj?.packageDetails?.packageCode?.replace(/'/g, ""),
                            eventId
                          );
                        }}
                        className="rounded-[10px] mt-2 text-[#fff] px-[15px] py-[8px] bg-[#3780ee]"
                      >
                        Pay Now
                      </button>
                    </div>
                  ) : (
                    ""
                    // <p className="whitespace-nowrap">
                    //   Payment Status - {obj?.paymentStatus}
                    // </p>
                  )}
                </div>
              );
            })}
          </div> */}
          <div className="px-5 py-4 ">
            {!loading && leadDetails?.albums?.length > 0 ? (
              <table className="w-full text-left bg-white shadow-md rounded-lg">
                <thead className="bg-gray-200">
                  <tr>
                    <th className="p-3">Service</th>
                    {/* <th className="p-3">Link Text</th> */}
                    <th className="p-3">Storage</th>
                    {/* <th className="p-3">Dulpicate</th> */}
                    <th className="p-3">Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {leadDetails?.albums?.map((obj, i) => (
                    <tr
                      key={i}
                      className={`cursor-pointer hover:bg-blue-100 border-b-[1px] border-[#787878] border-opacity-40 transition-colors ${
                        obj?.paymentStatus === "PENDING" ? "" : "clickable"
                      }`}
                      // onClick={()=>navigateToAlbum(eventId, obj)}
                      onClick={() =>
                        obj?.packageType === "FLEXI"
                          ? obj?.paymentPlan === "ONETIME" ||
                            obj?.paymentPlan === ""
                            ? obj?.paymentStatus !== "PAYMENT_SUCCESS"
                              ? ""
                              : navigateToAlbum(eventId, obj)
                            : navigateToAlbum(eventId, obj)
                          : navigateToAlbum(eventId, obj)
                      }
                    >
                     {/* Album Name and Icon */}
                      <td className="p-3 flex  items-center gap-4">
                        <div
                          className={`rounded-lg p-3 ${
                            obj?.albumType === "FACEDETECTION"
                              ? "bg-yellow-100"
                              : obj?.albumType === "SELECTIONTOOL" ||
                                obj?.albumType === "INVITATION" ||
                                obj?.albumType === "PHOTOSHARETOOL"
                              ? "bg-blue-100"
                              : "bg-red-100"
                          }`}
                        >
                          <img
                            src={
                              obj?.albumType === "FACEDETECTION"
                                ? eventFaceDetection
                                : obj?.albumType === "SELECTIONTOOL"
                                ? eventInvitation
                                : obj?.albumType === "INVITATION"
                                ? invitation
                                : obj?.albumType === "PHOTOSHARETOOL"
                                ? photoShare
                                : eventSignatureAlbum
                            }
                            alt={obj?.albumType}
                            className="w-7 h-7"
                          />
                        </div>
                        <div>
                          <p className="font-medium text-lg text-gray-900">
                            {obj?.albumName}
                          </p>
                          <p className=" text-md">
                            {getAlbumLinkText(obj?.albumType)}
                          </p>
                        </div>
                      </td>
                      {/* Album Link Text */}
                      {/* <td className="p-3">
                    
                  </td> */}
                      {/* Storage Details */}
                      <td className="p-3">
                        {obj?.packageType === "FLEXI" && storageLoading ? (
                          <p className="text-[16px] font-[400]">Loading...</p>
                        ) : (
                          <div className="w-[300px]">
                            <div className="w-full bg-gray-300 rounded h-[15px]">
                              <div
                                className="bg-blue-600 h-full rounded-full"
                                style={{
                                  width: `${
                                    obj?.packageDetails?.storageInGb &&
                                    storageData[obj?.shootmatesalbumId]
                                      ?.totalMediaSize
                                      ? (
                                          (storageData[obj?.shootmatesalbumId]
                                            ?.totalMediaSize /
                                            (obj?.packageDetails?.storageInGb *
                                              1024 ** 3)) *
                                          100
                                        )?.toFixed(2)
                                      : 0
                                  }%`,
                                }}
                              ></div>
                            </div>

                            <p className="mt-2 text-lg">
                              {storageData[obj?.shootmatesalbumId]
                                ?.totalMediaSize
                                ? storageData[obj?.shootmatesalbumId]
                                    ?.totalMediaSize >=
                                  1024 ** 3
                                  ? `${bytesToGB(
                                      storageData[obj?.shootmatesalbumId]
                                        ?.totalMediaSize
                                    )?.toFixed(2)} GB used`
                                  : `${bytesToMB(
                                      storageData[obj?.shootmatesalbumId]
                                        ?.totalMediaSize
                                    )?.toFixed(2)} MB used`
                                : "0 MB used"}
                              {obj?.packageType === "FLEXI" &&
                                ` of  ${
                                  obj?.packageDetails?.storageInGb || 0
                                }  GB
                           `}
                            </p>

                            {/* {obj?.packageDetails?.storageInGb && (
                            <p>
                              {" "}
                              Total Storage : {
                                obj?.packageDetails?.storageInGb
                              }{" "}
                              GB
                            </p>
                          )} */}
                          </div>
                        )}
                      </td>
                      {/* Actions */}
                      <td className=" p-3">
                        {obj?.packageType != "YEARLY"
                          ? obj?.paymentStatus === "PAYMENT_SUCCESS" &&
                            obj?.albumType === "SELECTIONTOOL" && (
                              <button
                                className="py-1 px-2 bg-blue-500 text-white rounded-md hover:bg-blue-600 transition-colors"
                                onClick={(e) => {
                                  e.stopPropagation();
                                  setSelectionDuplication(true);
                                  setSelectedTool(obj);
                                }}
                              >
                                Duplicate
                              </button>
                            )
                          : obj?.albumType === "SELECTIONTOOL" && (
                              <button
                                className="py-1 px-2 bg-blue-500 text-white rounded-md hover:bg-blue-600 transition-colors"
                                onClick={(e) => {
                                  e.stopPropagation();
                                  setSelectionDuplication(true);
                                  setSelectedTool(obj);
                                }}
                              >
                                Duplicate
                              </button>
                            )}
                        {obj?.packageType != "YEARLY"
                          ? obj?.packageDetails?.packageCode &&
                            obj?.packageType === "FLEXI" &&
                            obj?.paymentStatus !== "PAYMENT_SUCCESS" && (
                              <div className="justify-end items-end flex flex-col">
                                <p className="whitespace-nowrap text-lg">
                                  Payment Status -{" "}
                                  {obj?.paymentStatus === "INVOICE_CREATED"
                                    ? "Pending"
                                    : obj?.paymentStatus?.toLowerCase()}
                                </p>

                                <p className="whitespace-nowrap text-lg">
                                  Payment Plan -
                                  {obj?.paymentPlan?.toLowerCase()}
                                </p>
                                <button
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    handlePayment(
                                      studioId,
                                      obj?.shootmatesalbumId,
                                      obj?.packageDetails?.packageCode?.replace(
                                        /'/g,
                                        ""
                                      ),
                                      Number(eventId),
                                      obj?.packageDetails,
                                      obj?.invoiceId
                                    );
                                  }}
                                  className=" payNowBut  mt-2 text-white px-[15px] py-[8px] bg-blue-600 rounded-[10px]"
                                >
                                  Pay Now
                                </button>
                              </div>
                            )
                          : ""}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            ) : (
              <div className="mt-24 relative">
                <NoData
                  top={"22%"}
                  left={"-3%"}
                  text={
                    "No Services Created ,  create  any service to continue"
                  }
                />
              </div>
            )}
          </div>
        </>
      )}
    </div>
  );
}

export default EventDetailView;
