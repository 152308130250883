import { PopupWrapper } from "../../UI/PopupWrapper/PopupWrapper";
import React, { useContext, useState, useRef } from "react";
import "./MetaDataPopup.css";
import axios from "axios";
import { userAuthContext } from "../../Context/UserAuthContext";
import { shootmatesalbumdata } from "../../store/firebase-config";
import { ref, uploadBytes } from "firebase/storage";
import popupsCloseIcon from "../../Assets/popupsCloseIcon.svg";
import coverImageIcon from "../../Assets/coverImageIcon.svg";
import { dataContext } from "../../Context/DataContext";
function MetaDataPopup({
  update,
  setUpdate,
  visible,
  setView,
  data,
  studioId,
  shootmatesAlbumId,
  projectId,
}) {
  const inputRefStudioLogo = useRef(null);
  const selectedOption = {
    rid: "noconsent",
    label: "Without Login & No Whatsapp Consent",
    faceRegType: "NOCONSENT_REQUEST",
  };
  const inputRefAlbumCover = useRef(null);
  const { userIdToken, currentUser, verifyUser } = useContext(userAuthContext);
  const { studioDetails } = useContext(dataContext);
  const tokenData = userIdToken || currentUser?.accessToken;
  const [studioLogoLoading, setstudioLogoLoading] = useState(false);
  const [metaDataButtonLoading, setMetaDataButtonLoading] = useState(false);
  const [albumCoverImageLoading, setAlbumCoverImageLoading] = useState(false);
  const [validationErrors, setValidationErrors] = useState({});
  const [albumMetaDataDetails, setAlbumMetaDataDetails] = useState({
    albumId: data?.memshotAlbumId,
    albumCover: data?.albumMetaData?.albumCover,
    whatsappPlaceHolder: data?.albumMetaData?.whatsappPlaceHolder ?? true,
    whatsappNotification: data?.albumMetaData?.whatsappNotification ?? true,
    albumName: data?.albumMetaData?.albumName,
    brideGroomName: data?.albumMetaData?.brideGroomName,
    brideName: data?.albumMetaData?.brideName,
    faceRegEnded: data?.albumMetaData?.faceRegEnded ?? false,
    groomName: data?.albumMetaData?.groomName,
    //allPhotosRequired: data?.albumMetaData?.allPhotosRequired ?? false,
    enableAllPhotosLive: data?.albumMetaData?.enableAllPhotosLive ?? false,
    enableAllPhotosRegister: data?.albumMetaData?.enableAllPhotosRegister ?? false,
    demoVideoCover:
      "https://storage.googleapis.com/memshotalbum_images/Solid-Color-Background-Wallpapers-for-Phone.png",
    demoVideo:
      "https://storage.googleapis.com/memshotalbum_images/Album%20How-To.mp4",
    cardUrl:
      "https://storage.googleapis.com/memshotalbum_templates/wishesCard.json",
    studio: {
      whatsAppNumber:
        data?.albumMetaData?.studio?.whatsAppNumber ||
        studioDetails?.whatsAppNumber,
      studioLogo:
        data?.albumMetaData?.studio?.studioLogo || studioDetails?.studioLogo,
      studioName:
        data?.albumMetaData?.studio?.studioName || studioDetails?.studioName,
      studioLocation:
        data?.albumMetaData?.studio?.studioLocation ||
        studioDetails?.studioLocation,
      phoneNumber:
        data?.albumMetaData?.studio?.phoneNumber || studioDetails?.phoneNumber,
      website: data?.albumMetaData?.studio?.website || studioDetails?.website,
      email: data?.albumMetaData?.studio?.email || studioDetails?.email,
      instagram:
        data?.albumMetaData?.studio?.instagram || studioDetails?.instagram,
      socialMedia: [],
      transitionFiles: data?.albumMetaData?.studio?.transitionFiles
        ? data?.albumMetaData?.studio?.transitionFiles
        : [],
    },
    faceDetectionButtonText: data?.albumMetaData?.faceDetectionButtonText,
    faceDetectionButtonUrl: data?.albumMetaData?.faceDetectionButtonUrl,
    song: data?.albumMetaData?.song,
  });
  console.log("albumMetaDataDetails", albumMetaDataDetails);

  const handleStudioLogoChange = async (e) => {
    const selectedFile = e.target.files[0];
    if (selectedFile) {
      try {
        setstudioLogoLoading(true);
        const fileName = selectedFile.name;
        const storageRef = ref(
          shootmatesalbumdata,
          `${data?.memshotAlbumId}/studioLogos/${fileName}`
        );
        await uploadBytes(storageRef, selectedFile);
        const bucketName = "shootmatesalbumdata";
        const filePath = data?.memshotAlbumId + "/studioLogos/" + fileName;
        const gcsBucketUrl = `https://storage.googleapis.com/${bucketName}/${filePath}`;
        setAlbumMetaDataDetails((prevDetails) => ({
          ...prevDetails,
          studio: {
            ...prevDetails.studio,
            studioLogo: gcsBucketUrl,
          },
        }));
        setstudioLogoLoading(false);
      } catch (error) {
        console.error("Error uploading studio logo:", error);
        setstudioLogoLoading(false);
      }
    }
  };

  const handleAlbumCoverPage = async (e) => {
    const selectedFile = e.target.files[0];
    if (selectedFile) {
      const validTypes = ["image/jpeg", "image/jpg"];

      if (!validTypes.includes(selectedFile.type)) {
        alert("Only .jpg or .jpeg images are allowed.");
        return;
      }

      try {
        setAlbumCoverImageLoading(true);
        let fileToUpload = selectedFile;
        if (selectedFile.size > 400 * 1024) {
          fileToUpload = await compressImage(selectedFile, 0.5);
        }
        const fileName = `${shootmatesAlbumId}albumcover.jpeg`;
        const storageRef = ref(
          shootmatesalbumdata,
          `${data?.memshotAlbumId}/albumCover/${fileName}`
        );
        const metadata = {
          contentType: fileToUpload.type,
          cacheControl: "no-cache, no-store, must-revalidate",
        };
        await uploadBytes(storageRef, fileToUpload, metadata);
        const bucketName = "shootmatesalbumdata";
        const filePath = `${data?.memshotAlbumId}/albumCover/${fileName}`;
        const gcsBucketUrl = `https://storage.googleapis.com/${bucketName}/${filePath}`;
        setAlbumMetaDataDetails((prevDetails) => ({
          ...prevDetails,
          albumCover: gcsBucketUrl,
        }));
        setAlbumCoverImageLoading(false);
      } catch (error) {
        console.error("Error uploading album cover:", error);
        setAlbumCoverImageLoading(false);
      }
    }
  };

  const compressImage = (file, quality) => {
    return new Promise((resolve, reject) => {
      const canvas = document.createElement("canvas");
      const ctx = canvas.getContext("2d");
      const img = new Image();
      img.onload = () => {
        canvas.width = img.width;
        canvas.height = img.height;
        ctx.drawImage(img, 0, 0, img.width, img.height);
        canvas.toBlob(
          (blob) => {
            const compressedFile = new File([blob], file.name, {
              type: file.type,
              lastModified: Date.now(),
            });
            resolve(compressedFile);
          },
          file.type,
          quality
        );
      };
      img.onerror = (err) => reject(err);
      const reader = new FileReader();
      reader.onload = (event) => {
        img.src = event.target.result;
      };
      reader.readAsDataURL(file);
    });
  };

  const removeImageStudioLogo = () => {
    setAlbumMetaDataDetails((prevDetails) => ({
      ...prevDetails,
      studio: {
        ...prevDetails.studio,
        studioLogo: "",
      },
    }));
  };

  const removeAlbumCoverImage = () => {
    setAlbumMetaDataDetails((prevDetails) => ({
      ...prevDetails,
      albumCover: "",
    }));
  };

  const handleAlbumMetaDataDetails = (e, type) => {
    let { name, value } = e.target;

    if (value === "true") {
      value = true;
    } else if (value === "false") {
      value = false;
    }
    if (type === "album") {
      setAlbumMetaDataDetails((prevDetails) => ({
        ...prevDetails,
        [name]: value,
      }));
    } else {
      setAlbumMetaDataDetails((prevDetails) => ({
        ...prevDetails,
        studio: {
          ...prevDetails.studio,
          [name]: value,
        },
      }));

      // Clear validation error for this studio field when it's updated
      setValidationErrors((prevErrors) => ({
        ...prevErrors,
        studio: {
          ...prevErrors.studio,
          [name]: undefined,
        },
      }));
    }
  };

  const handleSubmit = async () => {
    try {
      setMetaDataButtonLoading(true);
      const payload = {
        ...albumMetaDataDetails,
        studio: {
          ...albumMetaDataDetails?.studio,
        },
        ...selectedOption,
      };
      verifyUser();
      let config = {
        method: "post",
        url: `https://asia-south1-shootmates.cloudfunctions.net/shootmatesMemshotsServices/api/shootmates/memshotsservices/saveAlbumMetaData/${projectId}/${studioId}/${shootmatesAlbumId}`,
        // url: `https://asia-south1-shootmates.cloudfunctions.net/test_shootmatesMemshotsServices/api/shootmates/memshotsservices/saveAlbumMetaData/${projectId}/${studioId}/${shootmatesAlbumId}`,
        data: {
          albumMetaData: payload,
        },
        headers: {
          Authorization: `Bearer ${tokenData}`,
          "Content-Type": "application/json",
        },
      };
      const response = await axios(config);
      if (response.status === 200) {
        setUpdate(!update);
        setMetaDataButtonLoading(false);
        setView(false);
      } else {
        setUpdate(!update);
        setMetaDataButtonLoading(false);
        setView(false);
      }
    } catch (error) {
      console.log("error", error);
      setUpdate(!update);
      setMetaDataButtonLoading(false);
      setView(false);
    }
  };
  return (
    <PopupWrapper visible={visible}>
      <div className="metadataFormContainer mt-[78px] min-w-[768px]">
        <div className="flex justify-between p-[24px] items-center">
          <p className="font_semibold_20 text-[#161616]">
            Album Metadata Details
          </p>
          <img
            src={popupsCloseIcon}
            alt=""
            onClick={() => {
              setView(false);
            }}
            className="cursor-pointer"
          />
        </div>
        <div className="seperatorHorizontalNew"></div>
        <div className="w-full h-full px-[24px] py-[20px]">
          <p className="font_semibold_16 text-[#161616] mb-[24px]">
            Album Details
          </p>
          <div className="w-full grid grid-cols-2 gap-[24px]">
            <div>
              <p className="font_normal_12 text-[#8C8C8C] mb-[8px]">
                Album Name
              </p>
              <input
                type="text"
                id="albumName"
                name="albumName"
                placeholder="Please enter Album name"
                className={`w-full font_normal_14 border-[1px] p-[10px] rounded-[8px] border-[#8C8C8C4D]`}
                value={albumMetaDataDetails.albumName}
                onChange={(e) => handleAlbumMetaDataDetails(e, "album")}
              />
            </div>
            <div>
              <div className="rounded-[8px] border-[#8C8C8C4D] border-[1px] h-[100px] flex justify-center items-center ">
                {albumMetaDataDetails?.albumCover ? (
                  <div className="w-full h-full">
                    <img
                      src={albumMetaDataDetails?.albumCover}
                      alt=""
                      className="w-full h-full object-cover"
                    />
                  </div>
                ) : (
                  <div
                    onClick={() => inputRefAlbumCover.current.click()}
                    className="flex gap-[10px] items-center border-[1px] border-[#8C8C8C33] cursor-pointer"
                  >
                    <input
                      type="file"
                      accept="image/*"
                      onChange={handleAlbumCoverPage}
                      className="hidden"
                      id="albumCover"
                      name="albumCover"
                      ref={inputRefAlbumCover}
                    />
                    <img src={coverImageIcon} alt="" />
                    <p className="font_normal_14 text-[#161616]">
                      {albumCoverImageLoading
                        ? "Uploading..."
                        : "Choose Album Cover"}
                    </p>
                  </div>
                )}
              </div>
              <div className="flex justify-end px-[24px] mt-[8px]">
                {albumMetaDataDetails?.albumCover && (
                  <p
                    onClick={removeAlbumCoverImage}
                    className="font_normal_12 text-[#4D58E3] cursor-pointer"
                  >
                    Change Cover image
                  </p>
                )}
              </div>
            </div>
          </div>
        </div>

        <div className="w-full h-full relative">
          {data?.packageType === "YEARLY" && (
            <div className="absolute w-full h-full bg-[#fff]  bg-opacity-10"></div>
          )}

          <div className="w-full h-full px-[24px] py-[20px]">
            <p className="font_semibold_16 text-[#161616] mb-[24px]">
              Studio Details
            </p>
            <div className="w-full grid grid-cols-2 gap-[24px]">
              <div>
                <p className="font_normal_12 text-[#8C8C8C] mb-[8px]">
                  Studio Name<span className="text-red-500">*</span>
                </p>
                <input
                  type="text"
                  id="studioName"
                  name="studioName"
                  placeholder="Please enter Studio name"
                  className={`w-full font_normal_14 border-[1px] p-[10px] rounded-[8px] border-[#8C8C8C4D]`}
                  value={albumMetaDataDetails?.studio?.studioName}
                  onChange={(e) => handleAlbumMetaDataDetails(e, "studio")}
                />
                {validationErrors?.studio?.studioName && (
                  <p className="font-normal text-[12px] leading-[14px] text-[#fc3535] mt-[5px]">
                    {validationErrors?.studio?.studioName}
                  </p>
                )}
              </div>
              <div>
                <p className="font_normal_12 text-[#8C8C8C] mb-[8px]">
                  Studio Email<span className="text-red-500">*</span>
                </p>
                <input
                  type="text"
                  id="email"
                  name="email"
                  placeholder="Please enter Studio email"
                  className={`w-full font_normal_14 border-[1px] p-[10px] rounded-[8px] border-[#8C8C8C4D]`}
                  value={albumMetaDataDetails?.studio?.email}
                  onChange={(e) => handleAlbumMetaDataDetails(e, "studio")}
                />
                {validationErrors?.studio?.email && (
                  <p className="font-normal text-[12px] leading-[14px] text-[#fc3535] mt-[5px]">
                    {validationErrors?.studio?.email}
                  </p>
                )}
              </div>
              <div>
                <p className="font_normal_12 text-[#8C8C8C] mb-[8px]">
                  Studio Location
                </p>
                <input
                  type="text"
                  id="studioLocation"
                  name="studioLocation"
                  placeholder="Please enter Studio location"
                  className={`w-full font_normal_14 border-[1px] p-[10px] rounded-[8px] border-[#8C8C8C4D]`}
                  value={albumMetaDataDetails?.studio?.studioLocation}
                  onChange={(e) => handleAlbumMetaDataDetails(e, "studio")}
                />
              </div>
              <div>
                <p className="font_normal_12 text-[#8C8C8C] mb-[8px]">
                  Website Url
                </p>
                <input
                  type="text"
                  id="website"
                  name="website"
                  placeholder="Please enter Website Url"
                  className={`w-full font_normal_14 border-[1px] p-[10px] rounded-[8px] border-[#8C8C8C4D]`}
                  value={albumMetaDataDetails?.studio?.website}
                  onChange={(e) => handleAlbumMetaDataDetails(e, "studio")}
                />
              </div>
              <div>
                <p className="font_normal_12 text-[#8C8C8C] mb-[8px]">
                  Instagram
                </p>
                <input
                  type="text"
                  id="instagram"
                  name="instagram"
                  placeholder="Please enter Instagram url"
                  className={`w-full font_normal_14 border-[1px] p-[10px] rounded-[8px] border-[#8C8C8C4D]`}
                  value={albumMetaDataDetails?.studio?.instagram}
                  onChange={(e) => handleAlbumMetaDataDetails(e, "studio")}
                />
              </div>
              <div>
                <p className="font_normal_12 text-[#8C8C8C] mb-[8px]">
                  Mobile Number
                </p>
                <input
                  type="number"
                  id="phoneNumber"
                  name="phoneNumber"
                  placeholder="Please enter Mobile Number"
                  className={`w-full font_normal_14 border-[1px] p-[10px] rounded-[8px] border-[#8C8C8C4D]`}
                  value={albumMetaDataDetails?.studio?.phoneNumber}
                  onChange={(e) => handleAlbumMetaDataDetails(e, "studio")}
                />
              </div>
              <div>
                <p className="font_normal_12 text-[#8C8C8C] mb-[8px]">
                  WhatsApp Number
                </p>
                <input
                  type="number"
                  id="whatsAppNumber"
                  name="whatsAppNumber"
                  placeholder="Please enter WhatsApp Number"
                  className={`w-full font_normal_14 border-[1px] p-[10px] rounded-[8px] border-[#8C8C8C4D]`}
                  value={albumMetaDataDetails?.studio?.whatsAppNumber}
                  onChange={(e) => handleAlbumMetaDataDetails(e, "studio")}
                />
              </div>
              <div>
                <div className="rounded-[8px] border-[#8C8C8C4D] border-[1px] h-[100px] flex justify-center items-center ">
                  {albumMetaDataDetails?.studio?.studioLogo ? (
                    <div className="w-full h-full">
                      <img
                        src={albumMetaDataDetails?.studio?.studioLogo}
                        alt=""
                        className="w-full h-full object-cover"
                      />
                    </div>
                  ) : (
                    <div
                      onClick={() => inputRefStudioLogo.current.click()}
                      className="flex gap-[10px] items-center border-[1px] border-[#8C8C8C33] cursor-pointer"
                    >
                      <input
                        type="file"
                        accept="image/*"
                        onChange={handleStudioLogoChange}
                        className="hidden"
                        id="studioLogo"
                        name="studioLogo"
                        ref={inputRefStudioLogo}
                      />
                      <img src={coverImageIcon} alt="" />
                      <p className="font_normal_14 text-[#161616]">
                        {studioLogoLoading
                          ? "Uploading..."
                          : "Choose Studio Logo"}
                      </p>
                    </div>
                  )}
                </div>
                <div className="flex justify-end px-[24px] mt-[8px]">
                  {albumMetaDataDetails?.studio?.studioLogo && (
                    <p
                      onClick={removeImageStudioLogo}
                      className="font_normal_12 text-[#4D58E3] cursor-pointer"
                    >
                      Change Cover image
                    </p>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="seperatorHorizontalNew"></div>

        <div className="w-full h-full px-[24px] py-[20px]">
          <div className="mb-4">
            <label
              className="block text-gray-700 text-sm font-bold mb-2"
              htmlFor="uploadType"
            >
              Face Registration
            </label>
            <select
              id="faceRegEnded"
              name="faceRegEnded"
              value={albumMetaDataDetails?.faceRegEnded}
              onChange={(e) => handleAlbumMetaDataDetails(e, "album")}
              className="border rounded p-2 w-10/12"
            >
              <option value="false">False</option>
              <option value="true">True</option>
            </select>
          </div>
          <div className="mb-4">
            <label
              className="block text-gray-700 text-sm font-bold mb-2"
              htmlFor="uploadType"
            >
              Ask WhatsApp Number
            </label>
            <select
              id="whatsappPlaceHolder"
              name="whatsappPlaceHolder"
              value={albumMetaDataDetails?.whatsappPlaceHolder}
              onChange={(e) => handleAlbumMetaDataDetails(e, "album")}
              className="border rounded p-2 w-10/12"
            >
              <option value="true">True</option>
              <option value="false">False</option>
            </select>
          </div>
          <div className="mb-4">
            <label
              className="block text-gray-700 text-sm font-bold mb-2"
              htmlFor="uploadType"
            >
              Enable WhatsApp Notification
            </label>
            <select
              id="whatsappNotification"
              name="whatsappNotification"
              value={albumMetaDataDetails?.whatsappNotification}
              onChange={(e) => handleAlbumMetaDataDetails(e, "album")}
              className="border rounded p-2 w-10/12"
            >
              <option value={true}>True</option>
              <option value={false}>False</option>
            </select>
          </div>
          {/* <div className="mb-4">
            <label
              className="block text-gray-700 text-sm font-bold mb-2"
              htmlFor="uploadType"
            >
              Show All Photos
            </label>
            <select
              id="allPhotosRequired"
              name="allPhotosRequired"
              value={albumMetaDataDetails?.allPhotosRequired}
              onChange={(e) => handleAlbumMetaDataDetails(e, "album")}
              className="border rounded p-2 w-10/12"
            >
              <option value={true}>True</option>
              <option value={false}>False</option>
            </select>
          </div> */}
          <div className="mb-4">
            <label
              className="block text-gray-700 text-sm font-bold mb-2"
              htmlFor="uploadType"
            >
              Show All Photos Register
            </label>
            <select
              id="enableAllPhotosRegister"
              name="enableAllPhotosRegister"
              value={albumMetaDataDetails?.enableAllPhotosRegister}
              onChange={(e) => handleAlbumMetaDataDetails(e, "album")}
              className="border rounded p-2 w-10/12"
            >
              <option value={true}>True</option>
              <option value={false}>False</option>
            </select>
          </div>
          <div className="mb-4">
            <label
              className="block text-gray-700 text-sm font-bold mb-2"
              htmlFor="uploadType"
            >
              Show All Photos Live
            </label>
            <select
              id="enableAllPhotosLive"
              name="enableAllPhotosLive"
              value={albumMetaDataDetails?.enableAllPhotosLive}
              onChange={(e) => handleAlbumMetaDataDetails(e, "album")}
              className="border rounded p-2 w-10/12"
            >
              <option value={true}>True</option>
              <option value={false}>False</option>
            </select>
          </div>
        </div>
        <div className="seperatorHorizontalNew"></div>

        <div className="seperatorHorizontalNew "></div>
        <div className="px-[24px] w-full flex gap-[16px] items-center justify-end pt-[12px] pb-[24px]">
          <button
            className="cancelButton font_semibold_12"
            onClick={() => {
              setView(false);
            }}
          >
            Cancel
          </button>
          {metaDataButtonLoading ? (
            <button class=" eventButton">
              <p className="text-[#F6F6F6] font_semibold_12">Saving Settings</p>
            </button>
          ) : (
            <button class=" eventButton" onClick={handleSubmit}>
              <p className="text-[#F6F6F6] font_semibold_12">Save Settings</p>
            </button>
          )}
        </div>
        <div className="container mx-auto p-0 bg-[#ffff] mt-0 mb-0">
          <div className="mb-0"></div>
        </div>
      </div>
    </PopupWrapper>
  );
}

export default MetaDataPopup;
