import React, { useContext, useState, useEffect } from "react";
import { PopupWrapper } from "../../UI/PopupWrapper/PopupWrapper";
import "./FaceDetectionForm.css";
import { dataContext } from "../../Context/DataContext";
import axios from "axios";
import { userAuthContext } from "../../Context/UserAuthContext";
import _ from "lodash";
//import Pricing from '../Pricing/Pricing'
import { createFaceDetection } from "../WorkFlowLeads_AddWorkflowTask/apiCallsLeads/apiCallsLeads";
import { useParams } from "react-router-dom";
import popupsCloseIcon from "../../Assets/popupsCloseIcon.svg";
import Pricing from "../PricingNew/Pricing";
function FaceDetectionForm({
  update,
  setUpdate,
  priceData,
  visible,
  setAlbum,
  values,
  packageType,
}) {
  const [slugIdValidation, setSlugIdValidation] = useState(false);
  const [slugIdValidationLoading, setSlugIdValidationLoading] = useState(false);
  const [selectedPhotosharePackage, setSelectedPhotosharePackage] = useState(
    priceData?.FaceDetection[0]
  );
  const [pricingDataApi, setPricingDataApi] = useState(
    priceData?.FaceDetection[0]?.filter[0]
  );
  const [totalPrice, setTotalPrice] = useState(
    priceData?.FaceDetection[0]?.filter[0]?.price
  );
  const { eventId } = useParams();
  const [errorCatch, setErrorCatch] = useState("");

  const {
    studioId,
    activePackages,
    setPaymentPlan,
    handlepaymentPlanChange,
    paymentPlan,
    flexiPackagePayment,
    setPayPlanError,
    payPlanError,
  } = useContext(dataContext);
  const [packageTypes, setPackageTypes] = useState(packageType || "FLEXI");
  const initialValues = {
    albumName: null,
    templateName: null,
    albumTemplateId: null,
    purchasedFromCRM: true,
    name: "",
    compress: true,
    canAssignCrew: false,
    paidMode: false,
    hasTaskDueDate: false,
    workflowTaskType: "PRODUCTION",
    workflowEventType: "ALBUM",
    dueDate: "",
  };

  const { userIdToken, currentUser } = useContext(userAuthContext);
  const tokenData = userIdToken || currentUser?.accessToken;
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState({});
  const [formValues, setFormValues] = useState(initialValues);
  const albumType = "FACEDETECTION";
  let newNumber = 0;

  useEffect(() => {
    setFormValues((prevValues) => ({
      ...prevValues,
      packageType: packageTypes,
    }));
  }, [packageTypes]);
  useEffect(() => {
    if (errorCatch) {
      setErrorCatch("");
    }
  }, [visible]);
  // useEffect(() => {
  //   setPaymentPlan("");
  // }, [visible]);

  useEffect(() => {
    let CancelToken = axios.CancelToken;
    let source = CancelToken.source();
    if (formValues?.slugId) {
      checkSlugIdValidation(formValues?.slugId, source);
    }
    return () => {
      source.cancel("Cancelled due to stale request");
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formValues?.slugId]);

  const checkSlugIdValidation = async (slugId, source) => {
    try {
      setSlugIdValidationLoading(true);
      let config = {
        method: "post",
        url: `https://asia-south1-shootmates.cloudfunctions.net/shootmatesMemshotsServices/api/shootmates/memshotsservices/album/checkslugid/${eventId}/${studioId}`,
        // url: `https://asia-south1-shootmates.cloudfunctions.net/test_shootmatesMemshotsServices/api/shootmates/memshotsservices/album/checkslugid/${eventId}/${studioId}`,

        headers: {
          Authorization: `Bearer ${tokenData}`,
          "Content-Type": "application/json",
        },
        data: {
          slugId: slugId,
        },
        cancelToken: source.token,
      };
      const response = await axios(config);
      if (response.status === 200) {
        let slugIdError = "";
        if (response?.data?.error === false) {
          setSlugIdValidation(true);
        } else {
          setSlugIdValidation(false);
          slugIdError = "Unique text is already used.Please choose other..";
        }
        setErrors((prevState) => ({
          ...prevState,
          slugId: slugIdError,
        }));
      }
      setSlugIdValidationLoading(false);
    } catch (error) {
      setSlugIdValidationLoading(false);
      if (axios.isCancel(error)) {
      } else {
        throw error;
      }
    }
  };
  const validateForm = () => {
    let errors = {};
    if (_.isEmpty(formValues?.slugId)) {
      errors.slugId = "Unique text is empty";
    }
    if (slugIdValidation === false) {
      errors.slugId = "Unique text is already used.Please choose other..";
    }
    if (_.isEmpty(formValues.albumName)) {
      errors.albumName = "Please enter album name";
    }
    return errors;
  };

  const handleClickAdd = async () => {
    const validationErrors = validateForm();
    if (Object.keys(validationErrors).length === 0) {
      // if (
      //   packageTypes === "FLEXI" &&
      //   activePackages?.paylater &&
      //   activePackages?.paylater === "ACTIVE" &&
      //   paymentPlan === ""
      // )
      //   return setPayPlanError("Select Your Payment Plan");
      const albumIdRes = await createFaceDetection(
        pricingDataApi,
        totalPrice,
        albumType,
        setLoading,
        eventId,
        studioId,
        newNumber,
        tokenData,
        formValues,
        values,
        setAlbum,
        setUpdate,
        update,
        packageType === "TRIAL" ? "TRIAL" : "FLEXI",
        paymentPlan || "ONETIME"
      );
      if (albumIdRes?.errorMsg) return setErrorCatch(albumIdRes?.errorMsg);
      if (albumIdRes && packageType !== "TRIAL") {
        flexiPackagePayment(
          studioId,
          albumIdRes?.albumId,
          albumIdRes?.packageCode?.replace(/'/g, ""),
          Number(eventId),
          paymentPlan
        );
      } else {
        setAlbum(false);
        setUpdate(!update);
      }
    } else {
      setErrors(validationErrors);
    }
  };

  const generateLink = (slugId) => {
    let link = "";
    if (!_.isEmpty(slugId)) {
      link = `https://app.memshots.com/${slugId}`;
    } else {
      link = "";
    }
    return link;
  };
  const handleSlugIdChange = (e) => {
    let temp = e.target.value;
    temp = temp.replace(/\s+/g, "").substring(0, 50);
    setFormValues({
      ...formValues,
      slugId: temp,
    });
  };
  const handleFrequencyChange = (event) => {
    setPackageTypes(event.target.value);
  };
  return (
    <PopupWrapper visible={visible}>
      <div className="CreateFaceDetectionContainer mt-[78px] min-w-[540px]">
        <div className="flex justify-between p-[24px] items-center">
          <p className="font_semibold_20 text-[#161616]">
            Face Detection Section
          </p>{" "}
          <div className="items-center gap-4  flex">
            <label>
              {" "}
              {packageType === "TRIAL" ? "Trial" : "Flexi"} Package
            </label>
            <img
              src={popupsCloseIcon}
              alt=""
              onClick={() => {
                setAlbum(false);
              }}
              className="cursor-pointer"
            />
          </div>{" "}
        </div>
        {errorCatch && (
          <p className="text-[18px] text-end mr-[10%] text-[red]">
            {errorCatch}
          </p>
        )}
        <div className="seperatorHorizontalNew"></div>
        <div className="w-full h-full px-[24px] py-[20px]">
          <p className="font_semibold_16 text-[#161616] mb-[24px]">
            AI-Face detection details
          </p>
          <div className="mb-[24px]">
            <p className="font_normal_12 text-[#8C8C8C] mb-[8px]">
              Name <span className="text-red-500">*</span>
            </p>
            <input
              type="text"
              placeholder="Please enter a name"
              className={`w-full font_normal_14 border-[1px] p-[10px] rounded-[8px] border-[#8C8C8C4D]`}
              onChange={(e) => {
                setFormValues({
                  ...formValues,
                  albumName: e.target.value,
                });
              }}
            />
            {errors?.albumName && (
              <p className="font-normal text-[12px] leading-[14px] text-[#fc3535] mt-[5px]">
                {errors?.albumName}
              </p>
            )}
          </div>
          <div>
            <p className="font_normal_12 text-[#8C8C8C] mb-[8px]">
              Slug ID <span className="text-red-500">*</span>
            </p>
            <input
              type="text"
              placeholder="Please enter an unique Id"
              className={`w-full font_normal_14 border-[1px] p-[10px] rounded-[8px] border-[#8C8C8C4D]`}
              value={formValues?.slugId}
              onChange={handleSlugIdChange}
            />
            {formValues?.slugId && (
              <p className="font-normal text-[19px] leading-[19px] text-[#145DA0] mt-[5px]">
                {generateLink(formValues?.slugId)}
                {slugIdValidation && <span> &#9989;</span>}
                {/* <span> &#10060;</span> */}
                {slugIdValidationLoading && (
                  <div role="status">
                    <svg
                      aria-hidden="true"
                      className="inline w-4 h-4 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600"
                      viewBox="0 0 100 101"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                        fill="currentColor"
                      />
                      <path
                        d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                        fill="currentFill"
                      />
                    </svg>
                    <span className="text-black p-2 text-sm">Checking</span>
                  </div>
                )}
              </p>
            )}
            {errors?.slugId && (
              <p className="font-normal text-[12px] leading-[14px] text-[#fc3535] mt-[5px]">
                {errors?.slugId}
              </p>
            )}
          </div>

          {/* <div className="items-center  flex">
            <label htmlFor="paymentFrequency " className="  text-md">
              Package type -
            </label>

            <div
              className={`p-2 
                    `}
            >
              <label>FLEXI</label>
            </div>
          </div> */}
          {/* Compression Needed Input */}
          <div className="col-span-2 mt-[24px]">
            <p className="font_normal_12 text-[#8C8C8C] mb-[8px]">
              Compression Needed? <span className="text-red-500">*</span>
            </p>
            <div className="flex items-center">
              <label className="mr-[24px]">
                <input
                  type="radio"
                  name="compression"
                  value="yes"
                  checked={formValues.compress === true}
                  onChange={(e) =>
                    setFormValues({
                      ...formValues,
                      compress: true,
                    })
                  }
                />
                <span className="ml-[8px]">Yes</span>
              </label>
              <label>
                <input
                  type="radio"
                  name="compression"
                  value="no"
                  checked={formValues.compress === false}
                  onChange={(e) =>
                    setFormValues({
                      ...formValues,
                      compress: false,
                    })
                  }
                />
                <span className="ml-[8px]">No</span>
              </label>
            </div>
            {errors?.compress && (
              <p className="font-normal text-[12px] leading-[14px] text-[#fc3535] mt-[5px]">
                {errors.compress}
              </p>
            )}
          </div>

          {/* AI Paid*/}
          <div className="col-span-2 mt-[24px]">
            <p className="font_normal_12 text-[#8C8C8C] mb-[8px]">
              Paid Photos Mode <span className="text-red-500">*</span>
            </p>
            <div className="flex items-center">
              <label className="mr-[24px]">
                <input
                  type="radio"
                  name="paidMode"
                  value="yes"
                  checked={formValues.paidMode === true}
                  onChange={(e) =>
                    setFormValues({
                      ...formValues,
                      paidMode: true,
                    })
                  }
                />
                <span className="ml-[8px]">Yes</span>
              </label>
              <label>
                <input
                  type="radio"
                  name="paidMode"
                  value="no"
                  checked={formValues.paidMode === false}
                  onChange={(e) =>
                    setFormValues({
                      ...formValues,
                      paidMode: false,
                    })
                  }
                />
                <span className="ml-[8px]">No</span>
              </label>
            </div>
            {errors?.compress && (
              <p className="font-normal text-[12px] leading-[14px] text-[#fc3535] mt-[5px]">
                {errors.compress}
              </p>
            )}
          </div>
        </div>
        <Pricing
          albumType={albumType}
          priceData={priceData}
          setPricingDataApi={setPricingDataApi}
          setTotalPrice={setTotalPrice}
          selectedPhotosharePackage={selectedPhotosharePackage}
          setSelectedPhotosharePackage={setSelectedPhotosharePackage}
        />
        {activePackages?.paylater && activePackages?.paylater === "ACTIVE" && (
          <div className="flex justify-end items-center mt-2 mr-[5%] space-x-4">
            <label className="text-[14px]">Choose to Payment Plan</label>{" "}
            <label className="flex items-center">
              <input
                type="checkbox"
                name="paymentOption"
                value="Paylater"
                checked={paymentPlan === "PAYLATER"}
                onChange={handlepaymentPlanChange}
                className="mr-2"
              />
              Pay Later
            </label>
            {/* <label className="flex items-center">
              <input
                type="radio"
                name="paymentOption"
                value="oneTime"
                checked={paymentPlan === "ONETIME"}
                onChange={handlepaymentPlanChange}
                className="mr-2"
              />
              One Time
            </label> */}
          </div>
        )}

        {payPlanError && (
          <p className="text-[14px] text-end mr-[5%] text-[red]">
            {payPlanError}
          </p>
        )}
        <div className="px-[24px] w-full flex gap-[16px] items-center justify-end pt-[16px] pb-[24px]">
          <button
            onClick={() => {
              setAlbum(false);
            }}
            className="cancelButton font_semibold_12"
          >
            Cancel
          </button>
          {/* {packageType === "YEARLY" ? (
            activePackages?.packageStatus === "ACTIVE" ? (
              loading ? (
                <button className="eventButton font_semibold_12">
                  Loading..
                </button>
              ) : (
                <button
                  className="eventButton font_semibold_12"
                  onClick={handleClickAdd}
                >
                  Create
                </button>
              )
            ) : (
              <p>Your Package is {activePackages?.packageStatus}</p>
            )
          ) : loading ? (
            <button className="eventButton font_semibold_12">Loading..</button>
          ) : (
            <button
              className="eventButton font_semibold_12"
              onClick={handleClickAdd}
            >
              Create
            </button>
          )} */}
          {loading ? (
            <button className="eventButton font_semibold_12 ">
              Loading...
            </button>
          ) : (
            <button
              className="eventButton font_semibold_12 "
              onClick={handleClickAdd}
            >
              Create
            </button>
          )}
        </div>
      </div>
    </PopupWrapper>
  );
}

export default FaceDetectionForm;
