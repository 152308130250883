import React, { useContext, useEffect, useState } from "react";
import { dataContext } from "../../Context/DataContext";
import { isEmpty } from "lodash";
import { useNavigate } from "react-router-dom";
import Lottie from "../../Assets/Animation/Loading";
import NoData from "../../Components/NoData/NoData";
import "./ActivePackages.css";
import { connectStorageEmulator } from "firebase/storage";

function ActivePackages() {
  const albumTypes = [
    {
      id: 1,
      name: "Signature Album",
      key: "ALBUM",
    },
    {
      id: 2,
      name: "Online Invitation",
      key: "INVITATION",
    },
    {
      id: 3,
      name: "Photo Selection",
      key: "SELECTIONTOOL",
    },
    {
      id: 4,
      name: "AI Photo Delivery",
      key: "FACEDETECTION",
    },
    {
      id: 5,
      name: "Photoshare Tool",
      key: "PHOTOSHARETOOL",
    },
  ];
  const {
    getActivePackages,
    roleSelected,
    activePackages,
    loading,
    usedPercentage,
    storageInfo,
    bytesToGB,
    PackagePayment,
    usedSize,
    totalStorageGb,
    getActiveFlexiPackages,
    flexiPackageDetails,
    flexiPackagePaymentInitialise,
  } = useContext(dataContext);

  const navigate = useNavigate();
  const [selectedService, setSelectedService] = useState("");
  useEffect(() => {
    if (roleSelected?.studioId) {
      getActiveFlexiPackages(roleSelected?.studioId, selectedService);
    }
  }, [roleSelected?.studioId, selectedService]);
  console.log(flexiPackageDetails, "flexi");

  function formatDate(dateString, renewal, show) {
    const date = new Date(dateString);

    const options = {
      month: "short",
      day: "2-digit",
      weekday: "long",
      year: "numeric",
      hour: "2-digit",
      minute: "2-digit",
      hour12: true,
    };

    const formattedDate = date.toLocaleString("en-US", options);

    const parts = formattedDate.split(", ");
    return `${parts[1]} ${parts[2]}  ${
      !show ? parts[0] : !renewal ? `at ${parts[3]}` : ""
    }
     `;
  }

  return (
    <div className="p-[20px] ">
      {activePackages?.activePackage === true && (
        <p className="activePackageHead">Account Settings</p>
      )}

      {loading ? (
        <Lottie />
      ) : activePackages?.activePackage === true ? (
        <>
          <div className="activePackagesMain mt-[22px]">
            <div className=" flex items-start">
              <p className="CurrentPlan">
                Current Plan :{" "}
                {activePackages?.packageDetails?.packageType
                  ?.charAt(0)
                  .toUpperCase() +
                  activePackages?.packageDetails?.packageType
                    ?.slice(1)
                    .toLowerCase()}{" "}
                <span className="font-[600] ml-1 text-[16px]">
                  {activePackages?.packageDetails?.packageName}
                </span>
              </p>
            </div>
            <div className="activePackagesSub mt-[20px]">
              <div className="flex justify-between items-start">
                <p className="pacckageDetails flex flex-col">
                  Packages Details :{" "}
                  {activePackages?.packageDetails?.packageName}
                  <span className="paymentPlanType ">
                    Activated Date :{" "}
                    {formatDate(
                      activePackages?.packageDetails?.dateOfActivation
                    )}
                  </span>
                </p>
                {activePackages?.packageDetails?.packageType !== "TRIAL" && (
                  <p className="pacckageDetailsPrice">
                    ₹ {activePackages?.packageDetails?.price}
                  </p>
                )}
              </div>
              {activePackages?.packageDetails?.packageType !== "TRIAL" && (
                <div className=" mt-[20px]  box px-[20px] py-[16px] ">
                  <p className="pacckageDetails">Payment Plan</p>
                  <div className="justify-between items-start flex">
                    <p className="paymentPlanType mt-[2px]">
                      One Time Payment
                      {/* {activePackages?.packageDetails?.paymentPlan
                        ?.charAt(0)
                        .toUpperCase() +
                        activePackages?.packageDetails?.paymentPlan
                          ?.slice(1)
                          .toLowerCase()} */}
                    </p>

                    {activePackages?.packageDetails?.paymentPlan ===
                      "ONETIME" && (
                      <p className="paymentPlanType ">
                        {activePackages?.invoices?.[0]?.status
                          ?.charAt(0)
                          .toUpperCase() +
                          activePackages?.invoices?.[0]?.status
                            ?.slice(1)
                            .toLowerCase()}
                      </p>
                    )}
                  </div>
                </div>
              )}
              {/* {activePackages?.packageDetails?.packageType !== "TRIAL" && (
                <div className="accordion mt-[20px]">
                  {activePackages?.invoices?.map((invoice, index) => (
                    <details
                      key={invoice.invoiceId}
                      className="border rounded mb-2"
                    >
                      <summary className="px-[16px] flex justify-between items-center py-[8px] cursor-pointer bg-gray-100 hover:bg-gray-200">
                        <p className="">
                          {" "}
                          Invoice #{invoice.invoiceSlNo} - ₹{invoice.amount}{" "}
                        </p>
                        <p>
                          Status :
                          {invoice.status === "CREATED" ? " Not paid" : " Paid"}
                        </p>
                      </summary>
                      <div className="p-[16px] bg-white">
                        <p>
                          <strong>Date of Creation:</strong>{" "}
                          {formatDate(invoice.dateOfCreation)}
                        </p>
                        <p>
                          <strong>Due Date:</strong>{" "}
                          {formatDate(invoice.dueDate, true)}
                        </p>
                        <p>
                          <strong>Status:</strong>{" "}
                          {invoice.status === "CREATED" ? "Not paid" : "Paid"}
                        </p>
                        {invoice.status === "CREATED" && (
                          <button
                            onClick={() =>
                              PackagePayment(
                                roleSelected?.studioId,
                                invoice?.subscriptionId,
                                invoice?.invoiceId
                              )
                            }
                            className="eventButton mt-4"
                          >
                            Pay Now
                          </button>
                        )}
                      </div>
                    </details>
                  ))}
                </div>
              )} */}

              <div className=" mt-[20px]  box px-[20px] py-[16px] ">
                <p className="pacckageDetails">Storage</p>
                <div className="flex gap-[12px] items-start mt-[12px]">
                  <div className="storageSpace">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      viewBox="0 0 16 16"
                      fill="none"
                    >
                      <path
                        d="M9.33317 1.51314V4.26683C9.33317 4.6402 9.33317 4.82688 9.40583 4.96949C9.46975 5.09493 9.57173 5.19692 9.69718 5.26083C9.83978 5.3335 10.0265 5.3335 10.3998 5.3335H13.1535M13.3332 6.65898V11.4668C13.3332 12.5869 13.3332 13.147 13.1152 13.5748C12.9234 13.9511 12.6175 14.2571 12.2412 14.4488C11.8133 14.6668 11.2533 14.6668 10.1332 14.6668H5.8665C4.7464 14.6668 4.18635 14.6668 3.75852 14.4488C3.3822 14.2571 3.07624 13.9511 2.88449 13.5748C2.6665 13.147 2.6665 12.5869 2.6665 11.4668V4.5335C2.6665 3.41339 2.6665 2.85334 2.88449 2.42552C3.07624 2.04919 3.3822 1.74323 3.75852 1.55148C4.18635 1.3335 4.7464 1.3335 5.8665 1.3335H8.00769C8.49687 1.3335 8.74146 1.3335 8.97163 1.38876C9.17571 1.43775 9.3708 1.51856 9.54974 1.62822C9.75157 1.7519 9.92453 1.92485 10.2704 2.27075L12.3959 4.39624C12.7418 4.74214 12.9148 4.91509 13.0385 5.11693C13.1481 5.29587 13.2289 5.49096 13.2779 5.69503C13.3332 5.92521 13.3332 6.1698 13.3332 6.65898Z"
                        stroke="#4D58E3"
                        stroke-width="1.33333"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                  </div>
                  <div className="w-full ">
                    {" "}
                    <div className="flex items-start justify-between">
                      <p className="storageSpaceText">
                        {activePackages?.packageDetails?.packageName} Storage
                        Space
                      </p>
                      <p className="storageSpaceText">
                        {activePackages?.packageDetails?.storageInGb} GB
                      </p>
                    </div>
                    <div className="w-full mt-[12px] storageSeekingBar">
                      <div
                        className="w-full h-full rounded-[4px] bg-[#4D58E3]"
                        style={{ width: `${usedPercentage || 0}%` }}
                      ></div>
                    </div>
                    <div className="storageusedText mt-[9px]">
                      {usedSize} used of
                      {totalStorageGb === null
                        ? " Unlimited GB "
                        : ` ${totalStorageGb} GB  `}
                    </div>
                  </div>
                </div>
              </div>

              <div className=" mt-[20px]  box px-[20px] py-[16px] ">
                <p className="pacckageDetails">Package Expiry</p>
                <div className="justify-between items-start flex flex-col">
                  {/* {activePackages?.packageDetails?.paymentPlan ===
                    "ONETIME" && ( */}

                  <p className="paymentPlanType ">
                    Next Renewal Date :{" "}
                    {formatDate(
                      activePackages?.packageDetails?.dateOfExpiry,
                      true
                    )}
                  </p>
                  {/* )} */}
                </div>
              </div>
            </div>
            {activePackages?.packageDetails?.paymentPlan === "QUATERLY" && (
              <div className="activePackagesMain mt-[12px]">
                <div className="justify-between items-center flex">
                  <p className="EmiOfPackage">Emi Payment Of Package</p>
                  <p className="EmiOfPackage">
                    ₹ {activePackages?.packageDetails?.price}
                  </p>
                </div>

                <div className="justify-between items-center flex">
                  <p className="totalAmt">Quarterly Payment</p>
                  <p className="EmiOfPackage">
                    ₹ {Math.round(activePackages?.packageDetails?.price / 4)}{" "}
                  </p>
                </div>
                <div className="justify-between items-center flex">
                  <p className="totalAmt">Emi Processing Fee</p>
                  <p className="EmiOfPackage">₹ 0</p>
                </div>
                <div className="justify-between items-center flex">
                  <p className="totalAmt">Total</p>
                  <p className="EmiOfPackage">
                    ₹ {activePackages?.packageDetails?.price}
                  </p>
                </div>

                {activePackages?.invoices?.map((invoice, index) => {
                  const lastPaidIndex = activePackages?.invoices?.reduce(
                    (lastIndex, item, i) =>
                      item.status === "PAID" ? i : lastIndex,
                    -1
                  );

                  const isNextUnpaidAfterPaid =
                    index === lastPaidIndex + 1 && invoice.status !== "PAID";

                  const dueDate = new Date(invoice?.dueDate);
                  const currentDate = new Date();
                  const daysRemaining = Math.ceil(
                    (dueDate - currentDate) / (1000 * 60 * 60 * 24)
                  );
                  return (
                    <div className="box mt-[24px] py-[16px] px-[20px] w-full">
                      <div className="flex w-full justify-start items-start">
                        <div className="w-full justify-between  gap-[10px] items-start flex">
                          {invoice?.status !== "PAID" ? (
                            <svg
                              className="mt-1"
                              xmlns="http://www.w3.org/2000/svg"
                              width="16"
                              height="16"
                              viewBox="0 0 16 16"
                              fill="none"
                            >
                              <g clip-path="url(#clip0_922_1139)">
                                <path
                                  d="M8 0C5.87827 0 3.84344 0.842855 2.34315 2.34315C0.842855 3.84344 0 5.87827 0 8C0 10.1217 0.842855 12.1566 2.34315 13.6569C3.84344 15.1571 5.87827 16 8 16C10.1217 16 12.1566 15.1571 13.6569 13.6569C15.1571 12.1566 16 10.1217 16 8C16 5.87827 15.1571 3.84344 13.6569 2.34315C12.1566 0.842855 10.1217 0 8 0ZM8 14C7.21207 14 6.43185 13.8448 5.7039 13.5433C4.97595 13.2417 4.31451 12.7998 3.75736 12.2426C3.20021 11.6855 2.75825 11.0241 2.45672 10.2961C2.15519 9.56815 2 8.78793 2 8C2 7.21207 2.15519 6.43185 2.45672 5.7039C2.75825 4.97595 3.20021 4.31451 3.75736 3.75736C4.31451 3.20021 4.97595 2.75825 5.7039 2.45672C6.43185 2.15519 7.21207 2 8 2C9.5913 2 11.1174 2.63214 12.2426 3.75736C13.3679 4.88258 14 6.4087 14 8C14 9.5913 13.3679 11.1174 12.2426 12.2426C11.1174 13.3679 9.5913 14 8 14Z"
                                  fill="#BCBCBC"
                                />
                              </g>
                              <defs>
                                <clipPath id="clip0_922_1139">
                                  <rect width="16" height="16" fill="white" />
                                </clipPath>
                              </defs>
                            </svg>
                          ) : (
                            <svg
                              className="mt-1"
                              xmlns="http://www.w3.org/2000/svg"
                              width="16"
                              height="16"
                              viewBox="0 0 16 16"
                              fill="none"
                            >
                              <g clip-path="url(#clip0_922_1158)">
                                <mask
                                  id="mask0_922_1158"
                                  style={{ maskType: "luminance" }}
                                  maskUnits="userSpaceOnUse"
                                  x="0"
                                  y="0"
                                  width="16"
                                  height="16"
                                >
                                  <path
                                    d="M8.00017 14.6668C8.8758 14.6679 9.74303 14.496 10.552 14.1609C11.361 13.8258 12.0958 13.3341 12.7142 12.7142C13.3341 12.0958 13.8258 11.361 14.1609 10.552C14.496 9.74303 14.6679 8.8758 14.6668 8.00017C14.6679 7.12453 14.496 6.25731 14.1609 5.44833C13.8258 4.63935 13.3341 3.90456 12.7142 3.28617C12.0958 2.66622 11.361 2.17457 10.552 1.83947C9.74303 1.50437 8.8758 1.33242 8.00017 1.3335C7.12453 1.33242 6.25731 1.50437 5.44833 1.83947C4.63935 2.17457 3.90456 2.66622 3.28617 3.28617C2.66622 3.90456 2.17457 4.63935 1.83947 5.44833C1.50437 6.25731 1.33242 7.12453 1.3335 8.00017C1.33242 8.8758 1.50437 9.74303 1.83947 10.552C2.17457 11.361 2.66622 12.0958 3.28617 12.7142C3.90456 13.3341 4.63935 13.8258 5.44833 14.1609C6.25731 14.496 7.12453 14.6679 8.00017 14.6668Z"
                                    fill="white"
                                    stroke="white"
                                    stroke-width="2"
                                    stroke-linejoin="round"
                                  />
                                  <path
                                    d="M5.3335 8L7.3335 10L11.3335 6"
                                    stroke="black"
                                    stroke-width="2"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                  />
                                </mask>
                                <g mask="url(#mask0_922_1158)">
                                  <path d="M0 0H16V16H0V0Z" fill="#13BB82" />
                                </g>
                              </g>
                              <defs>
                                <clipPath id="clip0_922_1158">
                                  <rect width="16" height="16" fill="white" />
                                </clipPath>
                              </defs>
                            </svg>
                          )}
                          <div className="w-full">
                            <p className="oneOfEmi ">
                              {invoice?.invoiceSlNo} of{" "}
                              {activePackages?.invoices?.length} EMI
                            </p>
                            <div className="flex gap-[42px] ">
                              {(index === lastPaidIndex + 1 ||
                                invoice?.status === "PAID") && (
                                <div className="flex mt-[6px]  items-center gap-[8px]">
                                  <p className="BillCreated ">Bill Created</p>
                                  <button className="dateBut">
                                    {formatDate(
                                      invoice?.dateOfCreation,
                                      true,
                                      true
                                    )}
                                  </button>
                                </div>
                              )}
                              {isNextUnpaidAfterPaid &&
                                invoice?.status !== "PAID" && (
                                  <div className="flex mt-[6px] items-center justify-start gap-[8px]">
                                    <p className="BillCreated  ">Due Date</p>
                                    <button className="dateButDue">
                                      {formatDate(invoice?.dueDate, true, true)}
                                    </button>
                                  </div>
                                )}
                              {invoice?.status !== "PAID" &&
                                !isNextUnpaidAfterPaid && (
                                  <>
                                    <div className="flex mt-[6px] items-center justify-start gap-[8px]">
                                      <p className="BillCreated  ">
                                        Next bill expected in
                                      </p>
                                      <button className="daysExpected">
                                        {daysRemaining} days
                                      </button>
                                    </div>
                                  </>
                                )}
                            </div>
                            {/* <div className="flex  mt-[6px]  justify-between ">
                              <p className="oneOfEmi">EMI Amount</p>
                              {invoice?.status === "PAID" ? (
                                <div className={`   flex gap-[5px]`}>
                                  <p className="oneOfEmi ">
                                    {" "}
                                    ₹ {invoice?.amount}
                                  </p>
                                  <button className="payStatus">Paid</button>
                                </div>
                              ) : (
                                <div
                                  className={`justify-end items-end flex flex-col`}
                                >
                                  <p className="oneOfEmi ">
                                    {" "}
                                    ₹ {invoice?.amount}
                                  </p>
                                  <button
                                    onClick={() => {
                                      if (
                                        index === lastPaidIndex + 1 ||
                                        invoice?.status === "PAID"
                                      ) {
                                        PackagePayment(
                                          roleSelected?.studioId,
                                          invoice?.subscriptionId,
                                          invoice?.invoiceId
                                        );
                                      } else return null;
                                    }}
                                    className={`${
                                      index === lastPaidIndex + 1 ||
                                      invoice?.status === "PAID"
                                        ? "opacity-100"
                                        : "opacity-40"
                                    } payNowBut mt-[10px]`}
                                  >
                                    Pay Now
                                  </button>
                                </div>
                              )}
                            </div> */}
                          </div>

                          {invoice?.status === "PAID" ? (
                            <div className={`   flex gap-[5px]`}>
                              <p className="oneOfEmi whitespace-nowrap ">
                                {" "}
                                EMI Amount : ₹ {invoice?.amount}
                              </p>
                              <button className="payStatus ">Paid</button>
                            </div>
                          ) : (
                            <div
                              className={`justify-end items-end flex flex-col`}
                            >
                              <p className="oneOfEmi ">
                                {" "}
                                EMI Amount : ₹ {invoice?.amount}
                              </p>
                              <button
                                onClick={() => {
                                  if (
                                    index === lastPaidIndex + 1 ||
                                    invoice?.status === "PAID"
                                  ) {
                                    PackagePayment(
                                      roleSelected?.studioId,
                                      invoice?.subscriptionId,
                                      invoice?.invoiceId
                                    );
                                  } else return null;
                                }}
                                className={`${
                                  index === lastPaidIndex + 1 ||
                                  invoice?.status === "PAID"
                                    ? "opacity-100"
                                    : "opacity-40"
                                } payNowBut mt-[10px] whitespace-nowrap`}
                              >
                                Pay Now
                              </button>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            )}
          </div>

          {/* FLEXI */}
          <div className=" flex mt-[20px] items-start justify-between">
            <p className="activePackageHead">Flexi Package Details</p>
            <p className="text-[14px]">
              {flexiPackageDetails?.length} Flexi Services Created
            </p>
          </div>
          <div className="gap-[20px] flex mt-[20px]">
            {albumTypes?.map((item) => (
              <button
                key={item?.key} // Adding a key to avoid React warnings
                onClick={() => setSelectedService(item?.key)}
                className={`${
                  selectedService === item?.key
                    ? "bg-[#373cbd] text-[#fff]"
                    : "border-[1px] border-[#787878]"
                } text-[14px] px-[14px] py-[5px] rounded-[12px]`}
              >
                {item?.name}
              </button>
            ))}
          </div>
          {flexiPackageDetails?.length > 0 ? (
            <div className="activePackagesMain mt-[22px]">
              {flexiPackageDetails?.map((item) => (
                <div className="box mt-[24px] py-[16px] px-[20px] w-full">
                  <div className="flex w-full justify-start items-start">
                    <div className="w-full justify-between  gap-[10px] items-start flex">
                      <div className="w-full">
                        {console.log(item, "item")}
                        <p className="oneOfEmi ">
                          {" "}
                          Service Name : {item?.albumName}
                        </p>
                        <p className="text-[14px] lowercase ">
                          {" "}
                          Service Type : {item?.albumType}
                        </p>
                      </div>

                      {item?.paymentStatus === "INVOICE_CREATED" ||
                      item?.paymentStatus === "PAYMENT_ERROR" ? (
                        <div
                          className={`justify-end items-center gap-[20px] flex `}
                        >
                          <p className="oneOfEmi mt-[10px] whitespace-nowrap">
                            {" "}
                            Amount : ₹ {item?.invoiceAmount}
                          </p>
                          <button
                            onClick={() => {
                              flexiPackagePaymentInitialise(
                                item?.studioId,
                                item?.projectId,
                                item?.invoiceId,
                                item?.albumId
                              );
                            }}
                            className={`
                             
                            
                            payNowBut mt-[10px] whitespace-nowrap`}
                          >
                            {loading ? "Loading" : "Pay Now"}
                          </button>
                        </div>
                      ) : (
                        <div className={`   flex gap-[5px]`}>
                          <button className="payStatus ">Paid</button>
                        </div>
                      )}
                    </div>
                  </div>

                  <div className=" mt-[20px]  box  p-[8px] ">
                    {/* <p className="pacckageDetails">Storage</p> */}
                    <div className="flex gap-[12px] items-start mt-[12px]">
                      <div className="storageSpace">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="16"
                          viewBox="0 0 16 16"
                          fill="none"
                        >
                          <path
                            d="M9.33317 1.51314V4.26683C9.33317 4.6402 9.33317 4.82688 9.40583 4.96949C9.46975 5.09493 9.57173 5.19692 9.69718 5.26083C9.83978 5.3335 10.0265 5.3335 10.3998 5.3335H13.1535M13.3332 6.65898V11.4668C13.3332 12.5869 13.3332 13.147 13.1152 13.5748C12.9234 13.9511 12.6175 14.2571 12.2412 14.4488C11.8133 14.6668 11.2533 14.6668 10.1332 14.6668H5.8665C4.7464 14.6668 4.18635 14.6668 3.75852 14.4488C3.3822 14.2571 3.07624 13.9511 2.88449 13.5748C2.6665 13.147 2.6665 12.5869 2.6665 11.4668V4.5335C2.6665 3.41339 2.6665 2.85334 2.88449 2.42552C3.07624 2.04919 3.3822 1.74323 3.75852 1.55148C4.18635 1.3335 4.7464 1.3335 5.8665 1.3335H8.00769C8.49687 1.3335 8.74146 1.3335 8.97163 1.38876C9.17571 1.43775 9.3708 1.51856 9.54974 1.62822C9.75157 1.7519 9.92453 1.92485 10.2704 2.27075L12.3959 4.39624C12.7418 4.74214 12.9148 4.91509 13.0385 5.11693C13.1481 5.29587 13.2289 5.49096 13.2779 5.69503C13.3332 5.92521 13.3332 6.1698 13.3332 6.65898Z"
                            stroke="#4D58E3"
                            stroke-width="1.33333"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                        </svg>
                      </div>
                      <div className="w-full ">
                        {" "}
                        <div className="flex items-start justify-between">
                          <p className="storageSpaceText">
                            Flexi Storage Space
                          </p>
                          {/* <p className="storageSpaceText">100 GB</p> */}
                        </div>
                        <div className="w-full mt-[12px] storageSeekingBar">
                          <div
                            className="w-full h-full rounded-[4px] bg-[#4D58E3]"
                            style={{ width: `0%` }}
                          ></div>
                        </div>
                        <div className="storageusedText mt-[9px]">
                          0 mb used
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          ) : (
            <div className="my-[50px] ">
              <div className="w-[30px] mx-auto relative">
                <svg
                  className="absolute right-0 -top-2"
                  xmlns="http://www.w3.org/2000/svg"
                  width="8"
                  height="8"
                  viewBox="0 0 8 8"
                  fill="none"
                >
                  <circle
                    cx="4"
                    cy="4"
                    r="4"
                    transform="matrix(-1 0 0 1 8 0)"
                    fill="#13BB82"
                  />
                </svg>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="32"
                  height="32"
                  viewBox="0 0 32 32"
                  fill="none"
                >
                  <circle
                    cx="16"
                    cy="16"
                    r="15"
                    transform="matrix(-1 0 0 1 32 0)"
                    stroke="#161616"
                    stroke-width="2"
                  />
                </svg>
              </div>
              <p className="NoDataHead">{"Welcome to Memshots"}</p>
              <p className="NoDataText mx-[30%]">No Services Created</p>
            </div>
          )}
        </>
      ) : (
        <>
          {" "}
          <div className=" flex  items-start justify-between">
            <p className="activePackageHead">Flexi Package Details</p>
            <p className="text-[14px]">
              {flexiPackageDetails?.length} Flexi Services Created
            </p>
          </div>
          <div className="gap-[20px] flex mt-[20px]">
            {(activePackages?.packageDetails?.packageType === "YEARLY"
              ? albumTypes?.slice(3)
              : albumTypes
            )?.map((item) => (
              <button
                key={item?.key} // Adding a key to avoid React warnings
                onClick={() => setSelectedService(item?.key)}
                className={`${
                  selectedService === item?.key
                    ? "bg-[#373cbd] text-[#fff]"
                    : "border-[1px] border-[#787878]"
                } text-[14px] px-[14px] py-[5px] rounded-[12px]`}
              >
                {item?.name}
              </button>
            ))}
          </div>
          {flexiPackageDetails?.length > 0 ? (
            <div className="activePackagesMain mt-[22px]">
              {flexiPackageDetails?.map((item) => (
                <div className="box mt-[24px] py-[16px] px-[20px] w-full">
                  <div className="flex w-full justify-start items-start">
                    <div className="w-full justify-between  gap-[10px] items-start flex">
                      <div className="w-full">
                        {console.log(item, "item")}
                        <p className="oneOfEmi ">
                          {" "}
                          Service Name : {item?.albumName}
                        </p>
                        <p className="text-[14px] lowercase ">
                          {" "}
                          Service Type : {item?.albumType}
                        </p>
                      </div>

                      {item?.paymentStatus === "INVOICE_CREATED" ||
                      item?.paymentStatus === "PAYMENT_ERROR" ? (
                        <div
                          className={`justify-end items-center gap-[20px] flex `}
                        >
                          <p className="oneOfEmi mt-[10px] whitespace-nowrap">
                            {" "}
                            Amount : ₹ {item?.invoiceAmount}
                          </p>
                          <button
                            onClick={() => {
                              flexiPackagePaymentInitialise(
                                item?.studioId,
                                item?.projectId,
                                item?.invoiceId,
                                item?.albumId
                              );
                            }}
                            className={`
                         
                        
                        payNowBut mt-[10px] whitespace-nowrap`}
                          >
                            {loading ? "Loading" : "Pay Now"}
                          </button>
                        </div>
                      ) : (
                        <div className={`   flex gap-[5px]`}>
                          <button className="payStatus ">Paid</button>
                        </div>
                      )}
                    </div>
                  </div>

                  <div className=" mt-[20px]  box  p-[8px] ">
                    {/* <p className="pacckageDetails">Storage</p> */}
                    <div className="flex gap-[12px] items-start mt-[12px]">
                      <div className="storageSpace">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="16"
                          viewBox="0 0 16 16"
                          fill="none"
                        >
                          <path
                            d="M9.33317 1.51314V4.26683C9.33317 4.6402 9.33317 4.82688 9.40583 4.96949C9.46975 5.09493 9.57173 5.19692 9.69718 5.26083C9.83978 5.3335 10.0265 5.3335 10.3998 5.3335H13.1535M13.3332 6.65898V11.4668C13.3332 12.5869 13.3332 13.147 13.1152 13.5748C12.9234 13.9511 12.6175 14.2571 12.2412 14.4488C11.8133 14.6668 11.2533 14.6668 10.1332 14.6668H5.8665C4.7464 14.6668 4.18635 14.6668 3.75852 14.4488C3.3822 14.2571 3.07624 13.9511 2.88449 13.5748C2.6665 13.147 2.6665 12.5869 2.6665 11.4668V4.5335C2.6665 3.41339 2.6665 2.85334 2.88449 2.42552C3.07624 2.04919 3.3822 1.74323 3.75852 1.55148C4.18635 1.3335 4.7464 1.3335 5.8665 1.3335H8.00769C8.49687 1.3335 8.74146 1.3335 8.97163 1.38876C9.17571 1.43775 9.3708 1.51856 9.54974 1.62822C9.75157 1.7519 9.92453 1.92485 10.2704 2.27075L12.3959 4.39624C12.7418 4.74214 12.9148 4.91509 13.0385 5.11693C13.1481 5.29587 13.2289 5.49096 13.2779 5.69503C13.3332 5.92521 13.3332 6.1698 13.3332 6.65898Z"
                            stroke="#4D58E3"
                            stroke-width="1.33333"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                        </svg>
                      </div>
                      <div className="w-full ">
                        {" "}
                        <div className="flex items-start justify-between">
                          <p className="storageSpaceText">
                            Flexi Storage Space
                          </p>
                          {/* <p className="storageSpaceText">100 GB</p> */}
                        </div>
                        <div className="w-full mt-[12px] storageSeekingBar">
                          <div
                            className="w-full h-full rounded-[4px] bg-[#4D58E3]"
                            style={{ width: `0%` }}
                          ></div>
                        </div>
                        <div className="storageusedText mt-[9px]">
                          0 mb used
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          ) : (
            <div
              className={`justify-center ${
                activePackages?.activePackage === true ? "" : "h-[70vh]"
              }   items-center flex flex-col  `}
            >
              <div className="w-[30px] mx-auto relative">
                <svg
                  className="absolute right-0 -top-2"
                  xmlns="http://www.w3.org/2000/svg"
                  width="8"
                  height="8"
                  viewBox="0 0 8 8"
                  fill="none"
                >
                  <circle
                    cx="4"
                    cy="4"
                    r="4"
                    transform="matrix(-1 0 0 1 8 0)"
                    fill="#13BB82"
                  />
                </svg>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="32"
                  height="32"
                  viewBox="0 0 32 32"
                  fill="none"
                >
                  <circle
                    cx="16"
                    cy="16"
                    r="15"
                    transform="matrix(-1 0 0 1 32 0)"
                    stroke="#161616"
                    stroke-width="2"
                  />
                </svg>
              </div>
              <p className="NoDataHead">{"Welcome to Memshots"}</p>
              <p className="NoDataText mx-[30%]">No Services Created</p>
            </div>
          )}
        </>
        // <div className="justify-start mt-[20px] items-center flex flex-col">
        //   {activePackages?.packageStatus == "CREATED" ? (
        //     <>
        //       <NoData
        //         text={
        //           "Your package has been  created, please complete the payment to  continue"
        //         }
        //         url={`/choosepackage`}
        //         butText={"Make Payment"}
        //       />
        //       {/* <p className="text-[32px] font-[600]">{activePackages?.msg}</p> */}
        //     </>
        //   ) : activePackages?.packageStatus == "EXPIRED" ? (
        //     <>
        //       <NoData
        //         text={
        //           "Your package has been expired, please activate to continue"
        //         }
        //         url={`/choosepackage`}
        //         butText={"Activate Package"}
        //       />
        //     </>
        //   ) : (
        //     <>
        //       <NoData
        //         text={
        //           "Package is not selected, please select a package to continue"
        //         }
        //         url={`/choosepackage`}
        //         butText={"Choose Package"}
        //       />
        //     </>
        //   )}
        // </div>
      )}
    </div>
  );
}

export default ActivePackages;
