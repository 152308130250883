import React, { useContext, useEffect, useState } from "react";
import axios from "axios";
import { userAuthContext } from "../../Context/UserAuthContext";
import Slider from "../Slider/Slider";
import Loading from "../../Assets/Animation/Loading";

function LiveUsersPopup({
  studioId,
  memshotAlbumId,
  setViewLiveUsersPopup,
  projectId,
}) {
  const [filteredUsers, setFilteredUsers] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [refreshing, setRefreshing] = useState(true);
  const [registeredUsers, setRegisteredUsers] = useState([]);
  const [shareLinkLoading, setShareLinkLoading] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);
  const [currentResultIndex, setCurrentResultIndex] = useState(0);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [showResultsPopup, setShowResultsPopup] = useState(false);
  const { userIdToken, currentUser, verifyUser } = useContext(userAuthContext);
  const tokenData = userIdToken || currentUser?.accessToken;

  useEffect(() => {
    fetchRegisteredUsers();
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refreshing]);

  const updatedLiveRequests = (requestId, shareLink) => {
    const updatedregisteredUsers =
      registeredUsers &&
      registeredUsers?.liveRequests &&
      registeredUsers?.liveRequests.length >= 0 &&
      registeredUsers?.liveRequests.map((user) => {
        if (user?.requestId === requestId) {
          return {
            ...user,
            shareLinkedCreated: true,
            shareLink: shareLink,
          };
        }
        return user;
      });

    const updatedFilteredUsers =
      filteredUsers &&
      filteredUsers &&
      filteredUsers?.length >= 0 &&
      filteredUsers?.map((user) => {
        if (user?.requestId === requestId) {
          return {
            ...user,
            shareLinkedCreated: true,
            shareLink: shareLink,
          };
        }
        return user;
      });
    setFilteredUsers(updatedFilteredUsers);
    setRegisteredUsers({
      ...registeredUsers,
      liveRequests: updatedregisteredUsers,
    });
  };

  const fetchRegisteredUsers = async () => {
    try {
      await verifyUser();
      let config = {
        method: "get",
        url: `https://asia-south1-shootmates.cloudfunctions.net/shootmatesMemshotsServices/api/shootmates/aiservices/livefaces/${projectId}/${studioId}/${memshotAlbumId}`,
        headers: {
          Authorization: `Bearer ${tokenData}`,
          "Content-Type": "application/json",
        },
      };
      const response = await axios(config);
      setRegisteredUsers(response?.data?.data);
      setFilteredUsers(response?.data?.data?.liveRequests || []);
      setRefreshing(false);
    } catch (error) {
      console.error("Error fetching registered users:", error);
      setRefreshing(false);
    }
  };

  const handleSearchChange = (event) => {
    const query = event.target.value.toLowerCase();
    setSearchQuery(query);
    if (query.length >= 3) {
      const filtered = registeredUsers?.liveRequests?.filter((user) =>
        user.name.toLowerCase().includes(query)
      );
      setFilteredUsers(filtered);
    } else {
      setFilteredUsers(registeredUsers?.liveRequests);
    }
  };

  const convertMongoDBTimestampToIST = (mongodbTimestamp) => {
    const utcTime = new Date(mongodbTimestamp);
    const istTime = utcTime.toLocaleString("en-US", {
      timeZone: "Asia/Kolkata",
    });
    return istTime;
  };

  const handleViewResults = (user) => {
    setSelectedUser(user);
    setCurrentResultIndex(0);
    setShowResultsPopup(true);
  };

  const handlePreviousResult = () => {
    setCurrentResultIndex((prevIndex) =>
      prevIndex > 0 ? prevIndex - 1 : selectedUser.results.length - 1
    );
  };

  const handleNextResult = () => {
    setCurrentResultIndex((prevIndex) =>
      prevIndex < selectedUser.results.length - 1 ? prevIndex + 1 : 0
    );
  };

  const getThumbnail = (url) => {
    const replacements = ["aimemshots", "shootmatesprivatecloud", "albumdata"];
    const targetReplacement = "albumthumbnails";
    const urlParts = url.split("/");
    const updatedParts = urlParts.map((part) =>
      replacements.includes(part) ? targetReplacement : part
    );
    const lastPart = updatedParts.at(-1);
    const modifiedFilename = lastPart.includes(".")
      ? `d_${lastPart}`
      : `d_${lastPart}`;
    updatedParts[updatedParts.length - 1] = modifiedFilename;
    return updatedParts.join("/");
  };

  const handleCreateShareLink = async (requestId) => {
    setShareLinkLoading(true);
    try {
      const config = {
        method: "post",
        url: `https://asia-south1-shootmates.cloudfunctions.net/shootmatesMemshotsServices/api/shootmates/aiservices/generatesharelink/${projectId}/${studioId}/${memshotAlbumId}/${requestId}`,
        headers: {
          Authorization: `Bearer ${tokenData}`,
          "Content-Type": "application/json",
        },
      };

      const response = await axios(config);
      updatedLiveRequests(requestId, response.data?.shareLink);
    } catch (error) {
      console.error("Error creating share link:", error);
    } finally {
      setShareLinkLoading(false);
    }
  };
  // const handleViewFaces = (user) => {
  //   setSelectedUser(user);
  //   setCurrentImageIndex(0);
  // };

  // const handlePreviousImage = () => {
  //   setCurrentImageIndex((prevIndex) =>
  //     prevIndex > 0 ? prevIndex - 1 : selectedUser.faceUrl.length - 1
  //   );
  // };

  // const handleNextImage = () => {
  //   setCurrentImageIndex((prevIndex) =>
  //     prevIndex < selectedUser.faceUrl.length - 1 ? prevIndex + 1 : 0
  //   );
  // };

  // const handleCloseSlider = () => {
  //   setSelectedUser(null);
  // };
  const copyImageUrl = (index) => {
    const imageUrl = `https://storage.googleapis.com/facedetectionregistrationsselfies/${memshotAlbumId}/${selectedUser?.faceUrl[index]}`;
    navigator.clipboard
      .writeText(imageUrl)
      .then(() => {
        alert("Image URL copied to clipboard!");
      })
      .catch((err) => {
        console.error("Failed to copy image URL: ", err);
      });
  };

  const handleCopyLink = (link) => {
    navigator.clipboard
      .writeText(link)
      .then(() => {
        //setCopied(true);
        // setTimeout(() => setCopied(false), 2000);
      })
      .catch((err) => {
        console.error("Failed to copy: ", err);
      });
  };

  return (
    <div className="bg-gray-100 max-w-6xl w-full h-[600px] overflow-y-auto rounded-lg shadow-xl border border-gray-200">
      {/* {selectedUser && (
        <Slider
          memshotAlbumId={memshotAlbumId}
          images={selectedUser?.faceUrl}
          currentIndex={currentImageIndex}
          onPrev={handlePreviousImage}
          onNext={handleNextImage}
          onClose={handleCloseSlider}
        />
      )} */}
      <div className="flex justify-between items-center p-4 sticky top-0 bg-white border-b border-gray-300 z-10">
        <h2 className="text-2xl font-bold text-blue-600">Live Users</h2>
        <div className="flex items-center space-x-4">
          <span className="text-sm text-gray-500">
            {`Total Live Users: ${
              refreshing ? "Refreshing..." : registeredUsers?.reqCounter
            }`}
          </span>
          <input
            type="text"
            value={searchQuery}
            onChange={handleSearchChange}
            placeholder="Search by name (min 3 letters)"
            className="border rounded-lg px-3 py-2 text-sm focus:outline-none focus:ring focus:ring-blue-300"
          />
          <button
            onClick={() => setRefreshing(!refreshing)}
            className={`px-4 py-2 rounded-full font-medium text-sm transition-all duration-300 focus:outline-none focus:ring focus:ring-blue-300 ${
              refreshing
                ? "bg-blue-300 cursor-not-allowed"
                : "bg-blue-500 hover:bg-blue-600 text-white"
            }`}
            disabled={refreshing}
          >
            {refreshing ? "Refreshing..." : "Refresh"}
          </button>
          <button
            onClick={() => setViewLiveUsersPopup(false)}
            className="px-4 py-2 rounded-full bg-red-500 hover:bg-red-600 text-white text-sm font-medium transition-all duration-300 focus:outline-none focus:ring focus:ring-red-300"
          >
            Close
          </button>
        </div>
      </div>

      {refreshing ? (
        <div className="flex justify-center items-center h-full">
          <Loading />
        </div>
      ) : (
        <div className="overflow-x-auto px-6 pb-6">
          <table className="min-w-full bg-white rounded-lg shadow-lg">
            <thead>
              <tr className="bg-blue-600 text-white">
                <th className="py-3 px-4 text-left text-sm font-medium">
                  Name
                </th>
                {/* <th className="py-3 px-4 text-left text-sm font-medium">
                  Live Faces
                </th> */}
                <th className="py-3 px-4 text-left text-sm font-medium">
                  Total Results
                </th>
                <th className="py-3 px-4 text-left text-sm font-medium">
                  Results
                </th>
                <th className="py-3 px-4 text-left text-sm font-medium">
                  Search Time
                </th>
                <th className="py-3 px-4 text-left text-sm font-medium">
                  Share Link
                </th>
              </tr>
            </thead>
            <tbody>
              {filteredUsers?.map((user, index) => (
                <tr
                  key={index}
                  className={`${
                    index % 2 === 0 ? "bg-gray-100" : "bg-gray-200"
                  } hover:bg-gray-300 transition-all duration-300`}
                >
                  <td className="py-3 px-4 text-sm font-medium text-gray-700">
                    {user?.name}
                  </td>
                  {/* <td className="py-3 px-4">
                    {user?.faceUrl?.length > 0 ? (
                      <button
                        onClick={() => handleViewFaces(user)}
                        className="px-3 py-1 text-sm font-medium text-white bg-green-500 rounded-full hover:bg-green-600 transition-all duration-300"
                      >
                        Show Live Faces
                      </button>
                    ) : (
                      <button className="px-3 py-1 text-sm font-medium text-white bg-red-500 rounded-full hover:bg-red-600 transition-all duration-300">
                        No Faces
                      </button>
                    )}
                  </td> */}
                  <td className="py-3 px-4 text-sm font-medium text-gray-700">
                    {user?.results?.length}
                  </td>
                  <td className="py-3 px-4">
                    <button
                      onClick={() => handleViewResults(user)}
                      className="px-3 py-1 text-sm font-medium text-white bg-blue-500 rounded-full hover:bg-blue-600 transition-all duration-300"
                    >
                      View Results
                    </button>
                  </td>
                  <td className="py-3 px-4 text-sm text-gray-600">
                    {convertMongoDBTimestampToIST(user?.registeredAt)}
                  </td>
                  {user?.results?.length > 0 ? (
                    <td className="py-3 px-4 text-sm text-green-600">
                      {user?.shareLinkedCreated === true ? (
                        <button
                          onClick={() => {
                            handleCopyLink(user?.shareLink);
                          }}
                          className="px-3 py-1 text-sm font-medium text-white bg-red-500 rounded-full hover:bg-red-600 transition-all duration-300"
                        >
                          Copy Share Link
                        </button>
                      ) : (
                        <button
                          onClick={() => {
                            handleCreateShareLink(user?.requestId);
                          }}
                          className="px-3 py-1 text-sm font-medium text-white bg-green-500 rounded-full hover:bg-green-600 transition-all duration-300"
                        >
                          {shareLinkLoading === true
                            ? "Generating Share Link"
                            : "Create Share Link"}
                        </button>
                      )}
                    </td>
                  ) : (
                    <button className="px-3 py-1 text-sm font-medium text-black  rounded-full  transition-all duration-300">
                      No Results
                    </button>
                  )}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}

      {showResultsPopup && selectedUser && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
          <div className="bg-white w-4/5 h-4/5 rounded-lg shadow-2xl overflow-hidden relative">
            {/* Header */}
            <div className="flex justify-between items-center p-4 bg-blue-600 text-white">
              <h2 className="text-lg font-bold">
                Results for {selectedUser.name}
                <p className="text-sm">
                  Total Results - {selectedUser.results?.length}
                </p>
              </h2>
              <button
                onClick={() => setShowResultsPopup(false)}
                className="px-4 py-2 text-sm font-medium bg-red-500 rounded-full hover:bg-red-600 transition-all duration-300"
              >
                Close
              </button>
            </div>

            {/* Main Content */}
            <div className="flex flex-col lg:flex-row h-full">
              {/* User Info Section */}
              <div className="w-full lg:w-1/5 bg-gray-50 p-3 border-r">
                <div className="flex flex-col items-center">
                  <img
                    src={`https://storage.googleapis.com/facedetectionregistrationsselfies/${memshotAlbumId}/${encodeURIComponent(
                      selectedUser?.faceUrl[currentImageIndex]
                    )}`}
                    alt={`face ${currentImageIndex + 1}`}
                    className="w-60 h-60 object-cover rounded-md shadow-md"
                  />
                  <button
                    onClick={() => copyImageUrl(currentImageIndex)}
                    className="mt-2 px-2 py-2 bg-blue-500 text-white rounded-lg hover:bg-blue-600 transition-all duration-300"
                  >
                    Copy Image URL
                  </button>
                </div>
              </div>

              {/* Results Section */}
              {selectedUser.results?.length > 0 && (
                <div className="w-full lg:w-2/3 relative">
                  <div className="flex justify-center items-center h-full relative">
                    <button
                      onClick={handlePreviousResult}
                      className="absolute left-4 top-1/2 transform -translate-y-1/2 bg-blue-500 hover:bg-blue-600 text-white px-3 py-1 rounded-full transition-all duration-300 shadow-lg"
                    >
                      Previous
                    </button>
                    <img
                      src={getThumbnail(
                        selectedUser.results[currentResultIndex]
                      )}
                      alt="Result"
                      className="max-h-[500px] max-w-full object-contain border border-gray-300 rounded-md shadow-md"
                    />
                    <button
                      onClick={handleNextResult}
                      className="absolute right-4 top-1/2 transform -translate-y-1/2 bg-blue-500 hover:bg-blue-600 text-white px-3 py-1 rounded-full transition-all duration-300 shadow-lg"
                    >
                      Next
                    </button>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default LiveUsersPopup;
