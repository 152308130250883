import React, { useContext, useEffect, useState } from "react";
import Lottie from "../../../Assets/Animation/LoadingWhite";
import MessagePopup from "../../PopUps/MessagePopup/MessagePopup";
import axios from "axios";
import ErrorPopup from "../../PopUps/ErrorPopup/ErrorPopup";
import { dataContext } from "../../../Context/DataContext";
import { userAuthContext } from "../../../Context/UserAuthContext";
import { PopupWrapper } from "../../../UI/PopupWrapper/PopupWrapper";
import "./AddPinSelectionTool.css";
import popupsCloseIcon from "../../../Assets/popupsCloseIcon.svg";

const AddPinSelectionTool = ({
  setaddPinPopup,
  projectId,
  albumId,
  visible,
  privateKey,
  update,
  setUpdate,
}) => {
  const { studioId } = useContext(dataContext);
  const { userIdToken, currentUser, verifyUser } = useContext(userAuthContext);
  const tokenData = userIdToken || currentUser?.accessToken;
  const [messagePopup, setMessagePopup] = useState({
    visible: false,
    type: "",
    message: "",
  });
  const [loadingText, setLoadingText] = useState("Setting Pin...");
  const [loading, setLoading] = useState(false);
  const [pin, setPin] = useState();
  const [error, setError] = useState("");
  useEffect(() => {
    verifyUser();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSubmit = async () => {
    try {
      if (pin === "") {
        setError("Pin cannot be empty");
        return;
      }
      setLoading(true);
      verifyUser();
      let config = {
        method: "post",
         url: `https://asia-south1-shootmates.cloudfunctions.net/shootmatesMemshotsServices/api/shootmates/memshotsservices/privateKeySet/${projectId}/${studioId}/${albumId}`,

        // url: `https://asia-south1-shootmates.cloudfunctions.net/test_shootmatesMemshotsServices/api/shootmates/memshotsservices/privateKeySet/${projectId}/${studioId}/${albumId}`,

        data: {
          privateKey: pin,
        },
        headers: {
          Authorization: `Bearer ${tokenData}`,
          "Content-Type": "application/json",
        },
      };
      const response = await axios(config);
      if (response.status === 200) {
        setLoadingText("Adding Pin to memshots album..");
        setLoading(false);
        setPin("");
        setError("");
        setMessagePopup({
          visible: true,
          type: "success",
          message: response?.data?.data?.msg,
        });
        setaddPinPopup(false);
      } else {
        setLoading(false);
        setError("");
        setMessagePopup({
          visible: true,
          type: "error",
          message: "Please try again..",
        });
      }
    } catch (error) {
      setLoading(false);
      console.log("error", error);
    } finally {
      setUpdate(!update);
    }
  };

  return (
    <PopupWrapper visible={visible}>
      <>
        {loading ? (
          <Lottie loadingText={loadingText} />
        ) : (
          <>
            {error.errorStatus === true && (
              <ErrorPopup error={error} setError={setError} />
            )}
            {messagePopup?.visible && (
              <MessagePopup
                closeProperty={true}
                closeOuterPopup={setaddPinPopup}
                messagePopup={messagePopup}
                setMessagePopup={setMessagePopup}
              />
            )}
            <div className="AddPinFormContainer mt-[78px]">
              <div className="px-[16px] py-[16px]  sticky top-0 bg-white flex items-center justify-between rounded-t-[12px]">
                <p className="font_semibold_20 text-[#161616]">Set Pin</p>
                <img
                  onClick={() => {
                    setaddPinPopup(false);
                    setPin("");
                    setError("");
                  }}
                  src={popupsCloseIcon}
                  alt=""
                  className="cursor-pointer"
                />
              </div>
              <div className="seperatorHorizontalNew"></div>
              <div className="px-[16px] pt-[16px]">
                <p className="text-[16px] font-[600] text-[#161616]">
                  Current Pin
                </p>
                <p className="text-[16px] font-bold text-red-300 mb-[8px] p-2">
                  {privateKey ? privateKey : "Pin not set"}
                </p>
              </div>
              <div className="px-[16px] ">
                <p className="text-[16px] font-[600] text-[#161616] mb-[8px]">
                  Add a pin
                </p>
                <input
                  type="number"
                  pattern="\d{4}"
                  inputMode="numeric"
                  className={`w-full font_normal_14 border-[1px] p-[10px] rounded-[8px] ${
                    error ? "border-red-500" : "border-[#8C8C8C4D]"
                  }`}
                  placeholder="Enter pin"
                  value={parseInt(pin)}
                  onChange={(e) => {
                    // Limit input to 4 digits
                    const inputValue = e.target.value;
                    const newValue = inputValue.replace(/\D/g, "").slice(0, 4);
                    setPin(parseInt(newValue));
                  }}
                />
                {error && (
                  <p className="text-red-500 font_normal_12">{error}</p>
                )}
              </div>
              <div className="seperatorHorizontalNew mt-[16px] "></div>
              <div className="px-[16px] w-full flex gap-[16px] items-center justify-end pt-[12px] pb-[24px]">
                <button
                  className="cancelButton font_semibold_12"
                  onClick={() => {
                    setaddPinPopup(false);
                    setPin("");
                    setError("");
                  }}
                >
                  {" "}
                  Cancel
                </button>
                <button className="eventButton" onClick={handleSubmit}>
                  <p className="text-[#F6F6F6] font_semibold_12">Set Pin</p>
                </button>
              </div>
            </div>
          </>
        )}
      </>
    </PopupWrapper>
  );
};

export default AddPinSelectionTool;
