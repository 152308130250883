import React, { useContext, useEffect, useState } from "react";
import "./InvitationCreateForm.css";
import { dataContext } from "../../Context/DataContext";
import { userAuthContext } from "../../Context/UserAuthContext";
import Lottie from "../../Assets/Animation/LoadingWhite";
import _ from "lodash";
import axios from "axios";
import { createInvitationLatest } from "../WorkFlowLeads_AddWorkflowTask/apiCallsLeads/apiCallsLeads";
import { useParams } from "react-router-dom";
import { PopupWrapper } from "../../UI/PopupWrapper/PopupWrapper";
import popupsCloseIcon from "../../Assets/popupsCloseIcon.svg";
import TemplatePurchase from "../InvitationPurchase/TemplatePurchase";
import { ref, uploadBytesResumable } from "firebase/storage";
import { shootmatesalbumdata } from "../../store/firebase-config";
import compressImage from "../../utils/compressImage";
import inv from "../../Assets/inv.json";
//import PricingConfiguration from "../PricingConfiguration/PricingConfiguration";
function InvitationCreateFormNew({
  priceData,
  update,
  setUpdate,
  visible,
  setAlbum,
  values,
  eventType,
  packageType,
}) {
  // const [selectedPhotosharePackage, setSelectedPhotosharePackage] = useState(
  //   priceData?.SignatureAlbum[0]
  // );
  const [pricingDataApi, setPricingDataApi] = useState(
    priceData?.SignatureAlbum[0]?.config
  );
  const [totalPrice, setTotalPrice] = useState(
    priceData?.SignatureAlbum[0]?.config?.price
  );
  const { eventId } = useParams();
  const {
    studioId,
    activePackages,
    paymentPlan,
    setPaymentPlan,
    handlepaymentPlanChange,
    flexiPackagePayment,
    setPayPlanError,
    payPlanError,
  } = useContext(dataContext);
  const [packageTypes, setPackageTypes] = useState(packageType || "FLEXI");
  const [errorCatch, setErrorCatch] = useState("");
  const initialValues = {
    purchasedFromCRM: true,
    name: "",
    canAssignCrew: false,
    hasTaskDueDate: false,
    workflowTaskType: "PRODUCTION",
    workflowEventType: "ALBUM",
    dueDate: "",
    packageType: packageTypes,
  };

  const { userIdToken, currentUser } = useContext(userAuthContext);
  const tokenData = userIdToken || currentUser?.accessToken;
  const [loading, setLoading] = useState(false);
  const [pickedTemplate, setPickedTemplate] = useState({});
  const [albumData, setAlbumData] = useState([]);
  const [errors, setErrors] = useState({});
  const [preview, setPreview] = useState(false);
  const [formValues, setFormValues] = useState(initialValues);
  const [imagesToUpload, setImagesToUpload] = useState({});
  const [uploadLoading, setUploadLoading] = useState(false);
  const [progress, setProgress] = useState([]);
  const [slugIdValidation, setSlugIdValidation] = useState(false);
  const [slugIdValidationLoading, setSlugIdValidationLoading] = useState(false);
  let newNumber = 0;
  useEffect(() => {
    setFormValues((prevValues) => ({
      ...prevValues,
      packageType: packageTypes,
    }));
  }, [packageTypes]);

  useEffect(() => {
    getTemplates();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (errorCatch) {
      setErrorCatch("");
    }
  }, [visible]);
  // useEffect(() => {
  //   setPaymentPlan("");
  // }, [visible]);

  useEffect(() => {
    let CancelToken = axios.CancelToken;
    let source = CancelToken.source();
    if (formValues?.slugId) {
      checkSlugIdValidation(formValues?.slugId, source);
    }
    return () => {
      source.cancel("Cancelled due to stale request");
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formValues?.slugId]);
  console.log("inv", inv);

  const getTemplates = async () => {
    setLoading(true);
    try {
      let config = {
        method: "GET",
        url: `https://asia-south1-shootmates.cloudfunctions.net/shootmatesAlbums/api/shootmates/crmalbums/templates?templateType=INVITATION&platformType=SHOOTMATES&eventType=WEDDING`,
        headers: {
          Authorization: `Bearer ${tokenData}`,
          "Content-Type": "application/json",
        },
      };
      const response = await axios(config);
      if (response.status === 200) {
        let templateData = response?.data?.templates;
        setAlbumData(templateData);
        setPickedTemplate(templateData[0]);
        //setPickedTemplate(inv);
        setFormValues({
          ...formValues,
          albumTemplateId: templateData[0]?._id,
        });
        setLoading(false);
      }
    } catch (error) {
      console.log("error", error);
      setLoading(false);
    }
  };
  const checkSlugIdValidation = async (slugId, source) => {
    try {
      setSlugIdValidationLoading(true);
      setSlugIdValidation(false);
      let config = {
        method: "post",
        // url: `https://asia-south1-shootmates.cloudfunctions.net/test_shootmatesMemshotsServices/api/shootmates/memshotsservices/album/checkslugid/${eventId}/${studioId}`,
        url: `https://asia-south1-shootmates.cloudfunctions.net/shootmatesMemshotsServices/api/shootmates/memshotsservices/album/checkslugid/${eventId}/${studioId}`,
        headers: {
          Authorization: `Bearer ${tokenData}`,
          "Content-Type": "application/json",
        },
        data: {
          slugId: slugId,
        },
        cancelToken: source.token,
      };
      const response = await axios(config);
      if (response.status === 200) {
        let slugIdError = "";
        if (response?.data?.error === false) {
          setSlugIdValidation(true);
        } else {
          setSlugIdValidation(false);
          slugIdError = "Unique text is already used.Please choose other..";
        }
        setErrors((prevState) => ({
          ...prevState,
          slugId: slugIdError,
        }));
      }
      setSlugIdValidationLoading(false);
    } catch (error) {
      if (axios.isCancel(error)) {
      } else {
        setSlugIdValidationLoading(false);
        throw error;
      }
    }
  };

  function handlePreview() {
    setPreview((prev) => !prev);
    window.scrollTo(0, 0);
  }
  const validateForm = () => {
    let errors = {};
    if (_.isEmpty(pickedTemplate)) {
      errors.template = "Please choose any template..";
    }
    if (_.isEmpty(formValues.albumName)) {
      errors.albumName = "Please enter album name";
    }
    if (slugIdValidation === false) {
      errors.slugId = "Unique text is already used.Please choose other..";
    }
    if (_.isEmpty(formValues.slugId)) {
      errors.slugId = "Please enter slug Id";
    }
    return errors;
  };

  const albumType = "INVITATION";
  const handleClickAdd = async (formData) => {
    let dataToTransfer = { ...formData, ...formValues };

    const albumIdRes = await createInvitationLatest(
      pricingDataApi,
      totalPrice,
      albumType,
      setLoading,
      eventId,
      studioId,
      newNumber,
      tokenData,
      dataToTransfer,
      values,
      setAlbum,
      setUpdate,
      update,
      packageTypes,
      paymentPlan || "ONETIME"
    );

    if (albumIdRes?.errorMsg) return setErrorCatch(albumIdRes?.errorMsg);
    if (albumIdRes && packageTypes == "FLEXI") {
      flexiPackagePayment(
        studioId,
        albumIdRes?.albumId,
        albumIdRes?.packageCode?.replace(/'/g, ""),
        Number(eventId),
        paymentPlan
      );
    } else {
      setAlbum(false);
      setUpdate(!update);
    }
  };

  const generateLink = (slugId) => {
    let link = "";
    if (!_.isEmpty(slugId)) {
      link = `https://app.memshots.com/${slugId}`;
    } else {
      link = "";
    }
    return link;
  };

  function handleBuy() {
    const validationErrors = validateForm();
    if (Object.keys(validationErrors).length === 0) {
      // if (
      //   packageTypes === "FLEXI" &&
      //   activePackages?.paylater &&
      //   activePackages?.paylater === "ACTIVE" &&
      //   paymentPlan === ""
      // )
      //   return setPayPlanError("Select Your Payment Plan");
      uploadGalleryImages();
    } else {
      setErrors(validationErrors);
    }
  }

  const uploadGalleryImages = async () => {
    setUploadLoading(true);
    if (Object.keys(imagesToUpload).length === 0) {
      handleClickAdd(pickedTemplate);
      return;
    }
    console.log("data");
    try {
      let dataToTransfer = pickedTemplate;
      await Promise.all(
        Object.entries(imagesToUpload)?.map(async (obj, index) => {
          let mediaArray = [];
          // let key = obj[0]
          let value = obj[1];
          for (let i = 0; i < value?.images?.length; i++) {
            let file = null;
            const selectedFile = value?.images[i];
            if (selectedFile?.type?.startsWith("image")) {
              const fileSize = selectedFile?.size / 1048576;
              if (fileSize > 1) {
                file = await compressImage(selectedFile);
              } else {
                file = selectedFile;
              }
            } else {
              file = selectedFile;
            }

            let randomId = Math.floor(Date.now() * Math.random());
            let fileName = `${randomId}_${file?.name?.replace(/ /g, "")}`;
            let filePath = `${studioId}/${eventId}/${fileName}`;
            const storageRef = ref(shootmatesalbumdata, filePath);
            const uploadTask = uploadBytesResumable(storageRef, file);
            uploadTask.on("state_changed", (snapshot) => {
              const uploadprogress =
                (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
              setProgress((prevProgress) => {
                const newProgress = [...prevProgress];
                newProgress[index] = {
                  progress: uploadprogress,
                  type: value?.type,
                };
                return newProgress;
              });
            });
            await uploadTask;
            const url = `https://storage.googleapis.com/shootmatesalbumdata/${filePath}`;
            if (value?.type === "GALLERY") {
              mediaArray.push({ url });
            } else mediaArray.push(url);
          }
          if (value?.type === "COVERIMAGE" || value?.type === "THANKING") {
            dataToTransfer.sections[value?.index].sectionData.coverImage =
              mediaArray[0];
          } else if (value?.type === "STUDIO") {
            dataToTransfer.sections[value?.index].sectionData.studioLogo =
              mediaArray[0];
          } else if (value?.type === "GALLERY") {
            dataToTransfer.sections[value?.index].sectionData.media =
              mediaArray;
          }
        })
      );
      return handleClickAdd(dataToTransfer);
    } catch (error) {
      console.log(error.message);
    }
  };

  const handleSlugIdChange = (e) => {
    let temp = e.target.value;
    temp = temp.replace(/\s+/g, "").substring(0, 50);
    setFormValues({
      ...formValues,
      slugId: temp,
    });
  };
  const handleFrequencyChange = (event) => {
    setPackageTypes(event.target.value);
  };

  return (
    <PopupWrapper visible={visible}>
      {loading ? (
        <Lottie loadingText={"Loading Album Templates"} />
      ) : (
        <>
          {/* {error.errorStatus === true &&
            <ErrorPopup error={error} setError={setError} />
          } */}
          <div className="InvitationCreateFormContainer my-[78px] min-w-[768px]">
            <div className=" sticky top-0">
              {errorCatch && (
                <p className="text-[18px] text-end mr-[10%] text-[red]">
                  {errorCatch}
                </p>
              )}
              <div className="flex justify-between p-[24px] bg-white items-center rounded-t-[12px] z-[70]">
                <div className="flex items-center gap-[20px]">
                  <p className="font_semibold_20 text-[#161616]">
                    Creating Invitation
                  </p>
                  <p className="buyNowButton" onClick={handlePreview}>
                    {preview ? "Close Preview" : "Show Preview"}
                  </p>
                </div>
                <div className="flex items-center gap-[20px]">
                  {packageType === "YEARLY" ? (
                    activePackages?.packageStatus === "ACTIVE" ? (
                      loading ? (
                        <button className="eventButton font_semibold_12">
                          Loading..
                        </button>
                      ) : (
                        <>
                          {" "}
                          <div className="items-center  flex">
                            <p>
                              {" "}
                              {packageType?.charAt(0).toUpperCase() +
                                packageType?.slice(1).toLowerCase()}{" "}
                              Package{" "}
                            </p>
                          </div>{" "}
                          <button
                            className="eventButton font_semibold_12"
                            onClick={handleBuy}
                          >
                            Create
                          </button>
                        </>
                      )
                    ) : (
                      <p>
                        {" "}
                        {packageType?.charAt(0).toUpperCase() +
                          packageType?.slice(1).toLowerCase()}{" "}
                        Package{" "}
                      </p>
                    )
                  ) : loading ? (
                    <button className="eventButton font_semibold_12">
                      Loading..
                    </button>
                  ) : (
                    <>
                      {" "}
                      <div className="items-center  flex">
                        <label>
                          {" "}
                          {packageType?.charAt(0).toUpperCase() +
                            packageType?.slice(1).toLowerCase()}{" "}
                          Package{" "}
                        </label>
                      </div>{" "}
                      <button
                        className="eventButton font_semibold_12"
                        onClick={handleBuy}
                      >
                        Create
                      </button>
                    </>
                  )}

                  {/* {loading ? (
                  <button className="eventButton font_semibold_12">
                    Loading..
                  </button>
                ) : (
                  <button
                    className="eventButton font_semibold_12"
                    onClick={handleBuy}
                  >
                    Create
                  </button>
                )} */}
                  <img
                    src={popupsCloseIcon}
                    alt=""
                    onClick={() => {
                      setAlbum(false);
                    }}
                    className="cursor-pointer"
                  />
                </div>
              </div>

              {packageTypes === "FLEXI" &&
                activePackages?.paylater &&
                activePackages?.paylater === "ACTIVE" && (
                  <div className="flex justify-end items-center mb-2 mr-[5%] space-x-4">
                    <label className="text-[14px]">
                      Choose to Payment Plan
                    </label>{" "}
                    <label className="flex items-center">
                      <input
                        type="checkbox"
                        name="paymentOption"
                        value="Paylater"
                        checked={paymentPlan === "PAYLATER"}
                        onChange={handlepaymentPlanChange}
                        className="mr-2"
                      />
                      Pay Later
                    </label>
                    {/* <label className="flex items-center">
                      <input
                        type="radio"
                        name="paymentOption"
                        value="oneTime"
                        checked={paymentPlan === "ONETIME"}
                        onChange={handlepaymentPlanChange}
                        className="mr-2"
                      />
                      One Time
                    </label> */}
                  </div>
                )}

              {payPlanError && (
                <p className="text-[14px] text-end mr-[5%] text-[red]">
                  {payPlanError}
                </p>
              )}
            </div>
            {!preview && (
              <>
                <div className="seperatorHorizontalNew"></div>

                <div className="w-full h-full px-[24px] py-[20px]">
                  <p className="font_semibold_16 text-[#161616] mb-[24px]">
                    Invitation Details
                  </p>
                  <div className="w-full grid grid-cols-2 gap-x-[24px]">
                    <div>
                      <p className="font_normal_12 text-[#8C8C8C] mb-[8px]">
                        Invitation name<span className="text-red-500">*</span>
                      </p>
                      <input
                        type="text"
                        placeholder="Please enter invitation name"
                        className={`w-full font_normal_14 border-[1px] p-[10px] rounded-[8px] border-[#8C8C8C4D]`}
                        value={formValues.albumName}
                        onChange={(e) => {
                          setFormValues({
                            ...formValues,
                            albumName: e.target.value,
                          });
                        }}
                      />
                      {errors?.albumName && (
                        <p className="font-normal text-[12px] leading-[14px] text-[#fc3535] mt-[5px]">
                          {errors?.albumName}
                        </p>
                      )}
                    </div>
                    <div>
                      <p className="font_normal_12 text-[#8C8C8C] mb-[8px]">
                        Slug ID <span className="text-red-500">*</span>
                      </p>
                      <input
                        type="text"
                        placeholder="Please enter an unique Id"
                        className={`w-full font_normal_14 border-[1px] p-[10px] rounded-[8px] border-[#8C8C8C4D]`}
                        value={formValues.slugId}
                        onChange={handleSlugIdChange}
                      />
                      {errors?.slugId && (
                        <p className="font-normal text-[12px] leading-[14px] text-[#fc3535] mt-[5px]">
                          {errors?.slugId}
                        </p>
                      )}
                      {formValues?.slugId && (
                        <p className="font-normal text-[19px] leading-[19px] text-[#145DA0] mt-[5px] break-words">
                          {generateLink(formValues.slugId)}
                          {slugIdValidation && <span> &#9989;</span>}
                          {slugIdValidationLoading && (
                            <div role="status">
                              <svg
                                aria-hidden="true"
                                className="inline w-4 h-4 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600"
                                viewBox="0 0 100 101"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                                  fill="currentColor"
                                />
                                <path
                                  d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                                  fill="currentFill"
                                />
                              </svg>
                              <span className="text-black p-2 text-sm">
                                Checking
                              </span>
                            </div>
                          )}
                        </p>
                      )}
                    </div>

                    {/* <div className="items-center  flex">
                      <label htmlFor="paymentFrequency " className="  text-md">
                        Package type -
                      </label>

                      <div
                        className={`p-2 
                    `}
                      >
                        <label>{packageType}</label>
                      </div>
                    </div> */}
                  </div>
                </div>
                <div className="seperatorHorizontalNew"></div>

                <div className="w-full h-full px-[24px] py-[20px]">
                  <p className="font_semibold_16 text-[#161616] mb-[24px]">
                    Select Invitation Template
                  </p>
                  <p className="font_normal_12 text-[#8C8C8C] mb-[8px]">
                    Choose a template <span className="text-red-500">*</span>
                  </p>
                  <div className="w-full flex">
                    {albumData &&
                      albumData.length > 0 &&
                      albumData?.map((obj, index) => {
                        return (
                          <div
                            key={index}
                            onClick={() => {
                              setPickedTemplate(albumData[index]);
                              setFormValues({
                                ...formValues,
                                templateName: albumData[index]?.templateName,
                                albumTemplateId: albumData[index]?._id,
                                templateType: albumData[index]?.templateType,
                                sections: albumData[index]?.sections,
                              });
                            }}
                            className={`relative group rounded-lg overflow-hidden cursor-pointer w-fit transition-all duration-300 
                              ${
                                obj?._id === pickedTemplate?._id
                                  ? "border-4 border-blue-500 shadow-lg"
                                  : "border-2 border-gray-200"
                              }
                              hover:shadow-md hover:border-gray-300`}
                            // className={`hover:opacity-[0.8] relative rounded-[12px] overflow-hidden cursor-pointer ${
                            //   obj?._id === pickedTemplate?._id &&
                            //   "border-[2px] border-black"
                            // }`}
                          >
                            <img
                              src={obj?.templateCover}
                              alt=""
                              className="w-[153px] h-[217px] rounded-[12px] object-cover"
                            />
                            {obj?._id === pickedTemplate?._id && (
                              <div className="absolute top-2 right-2 bg-blue-500 text-white rounded-full p-1">
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  className="h-5 w-5"
                                  fill="none"
                                  viewBox="0 0 24 24"
                                  stroke="currentColor"
                                  strokeWidth={2}
                                >
                                  <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    d="M5 13l4 4L19 7"
                                  />
                                </svg>
                              </div>
                            )}
                            {obj?._id === pickedTemplate?._id && (
                              <a
                                href={obj?.previewUrl}
                                target="_blank"
                                rel="noopener noreferrer"
                                className="block bg-black text-white text-sm font-medium text-center px-4 py-2 mt-2 rounded-md transition-opacity duration-300 hover:bg-gray-800"
                              >
                                Preview
                              </a>
                            )}
                          </div>
                        );
                      })}
                    {errors?.template && (
                      <p className="font-normal text-[12px] leading-[14px] text-[#fc3535] mt-[5px]">
                        {errors.template}
                      </p>
                    )}
                  </div>
                </div>
              </>
            )}
            <div className="seperatorHorizontalNew"></div>
            <TemplatePurchase
              templateJson={pickedTemplate}
              setTemplateJson={setPickedTemplate}
              preview={preview}
              setImagesToUpload={setImagesToUpload}
              uploadLoading={uploadLoading}
              progress={progress}
              packageType={packageTypes}
            />
          </div>
        </>
      )}
    </PopupWrapper>
  );
}

export default InvitationCreateFormNew;
