import React, { useContext, useEffect, useState } from "react";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import "./Sidebar.css";
//import DashboardLogo from '../../Assets/SidebarIcons/DashboardLogo.svg';
import ClientLogo from "../../Assets/SidebarIcons/ClientLogo.svg";
import PaymentsLogo from "../../Assets/SidebarIcons/PaymentsLogo.svg";
import StudioLogo from "../../Assets/SidebarIcons/StudioLogo.svg";
import EventLogo from "../../Assets/SidebarIcons/EventLogo.svg";
import SettingsLogo from "../../Assets/SidebarIcons/SettingsLogo.svg";
import userIcon from "../../Assets/userIcon.svg";
import { dataContext } from "../../Context/DataContext";
import PackageStatusBut from "../packageStatusBut/PackageStatusBut";

function Sidebar() {
  const {
    usedPercentage,
    storageInfo,
    bytesToGB,
    activePackages,
    totalStorageGb,
    usedSize,
  } = useContext(dataContext);
  const location = useLocation();
  const navigate = useNavigate();
  const [openSection, setOpenSection] = useState(null);

  // Map routes to sections
  const sectionMap = {
    SERVICES: ["/Event"],
    "MY COMPANY": ["/organizations", "/clients", "/portfolio/create"],
    "DESKTOP TOOL": ["/crewusers", "/tools"],
    STORAGE: [], // Add paths related to storage if any
    PACKAGES: ["/choosepackage", "/activepackages"],
    SUBSCRIPTIONS: ["/subscriptions"],
  };

  // Determine the open section based on the current route
  useEffect(() => {
    const currentSection = Object.keys(sectionMap).find((section) =>
      sectionMap[section].some((path) => location.pathname.startsWith(path))
    );
    setOpenSection(currentSection || null); // Default to null if no match
  }, [location.pathname]);

  const toggleSection = (section) => {
    setOpenSection((prev) => (prev === section ? null : section)); // Toggle open/close
  };
  return (
    // <div className="sidebar h-auto relative">

    //   <div>
    //     <p
    //       onClick={() => toggleSection("SERVICES")}
    //       className="ml-[40px] mb-[12px] font_medium_12 text-[#111] cursor-pointer flex justify-between items-center"
    //     >
    //       SERVICES
    //       <span className={`opacity-70 transition-transform  mr-[10%]  `}>
    //         {openSection === "SERVICES" ? (
    //           <svg
    //             xmlns="http://www.w3.org/2000/svg"
    //             width="24"
    //             height="24"
    //             viewBox="0 0 24 24"
    //             fill="none"
    //           >
    //             <path
    //               d="M12 12L7 17M12 12L17 7M12 12L17 17M12 12L7 7"
    //               stroke="#161616"
    //               stroke-width="2"
    //               stroke-linecap="round"
    //               stroke-linejoin="round"
    //             ></path>
    //           </svg>
    //         ) : (
    //           <svg
    //             xmlns="http://www.w3.org/2000/svg"
    //             width="23"
    //             height="23"
    //             viewBox="0 0 18 18"
    //             fill="none"
    //           >
    //             <path
    //               d="M8.27361 4.63637V8.27273H4.63725C4.44437 8.27273 4.25938 8.34935 4.12299 8.48574C3.9866 8.62213 3.90998 8.80712 3.90998 9C3.90998 9.19289 3.9866 9.37787 4.12299 9.51426C4.25938 9.65065 4.44437 9.72728 4.63725 9.72728H8.27361V13.3636C8.27361 13.5565 8.35024 13.7415 8.48663 13.8779C8.62302 14.0143 8.808 14.0909 9.00089 14.0909C9.19377 14.0909 9.37876 14.0143 9.51515 13.8779C9.65154 13.7415 9.72816 13.5565 9.72816 13.3636V9.72728H13.3645C13.5574 9.72728 13.7424 9.65065 13.8788 9.51426C14.0152 9.37787 14.0918 9.19289 14.0918 9C14.0918 8.80712 14.0152 8.62213 13.8788 8.48574C13.7424 8.34935 13.5574 8.27273 13.3645 8.27273H9.72816V4.63637C9.72816 4.44348 9.65154 4.2585 9.51515 4.12211C9.37876 3.98572 9.19377 3.90909 9.00089 3.90909C8.808 3.90909 8.62302 3.98572 8.48663 4.12211C8.35024 4.2585 8.27361 4.44348 8.27361 4.63637Z"
    //               fill="#161616"
    //             ></path>
    //           </svg>
    //         )}
    //       </span>
    //     </p>
    //     {openSection === "SERVICES" && (
    //       <NavItem to="/Event" icon={EventLogo} title="Events" />
    //     )}
    //   </div>

    //   <div>
    //     <p
    //       onClick={() => toggleSection("MY COMPANY")}
    //       className="mt-[38px] ml-[40px] mb-[12px] font_medium_12 text-[#111] cursor-pointer flex justify-between items-center"
    //     >
    //       MY COMPANY
    //       <span className={`opacity-70 transition-transform  mr-[10%]  `}>
    //         {openSection === "MY COMPANY" ? (
    //           <svg
    //             xmlns="http://www.w3.org/2000/svg"
    //             width="24"
    //             height="24"
    //             viewBox="0 0 24 24"
    //             fill="none"
    //           >
    //             <path
    //               d="M12 12L7 17M12 12L17 7M12 12L17 17M12 12L7 7"
    //               stroke="#161616"
    //               stroke-width="2"
    //               stroke-linecap="round"
    //               stroke-linejoin="round"
    //             ></path>
    //           </svg>
    //         ) : (
    //           <svg
    //             xmlns="http://www.w3.org/2000/svg"
    //             width="23"
    //             height="23"
    //             viewBox="0 0 18 18"
    //             fill="none"
    //           >
    //             <path
    //               d="M8.27361 4.63637V8.27273H4.63725C4.44437 8.27273 4.25938 8.34935 4.12299 8.48574C3.9866 8.62213 3.90998 8.80712 3.90998 9C3.90998 9.19289 3.9866 9.37787 4.12299 9.51426C4.25938 9.65065 4.44437 9.72728 4.63725 9.72728H8.27361V13.3636C8.27361 13.5565 8.35024 13.7415 8.48663 13.8779C8.62302 14.0143 8.808 14.0909 9.00089 14.0909C9.19377 14.0909 9.37876 14.0143 9.51515 13.8779C9.65154 13.7415 9.72816 13.5565 9.72816 13.3636V9.72728H13.3645C13.5574 9.72728 13.7424 9.65065 13.8788 9.51426C14.0152 9.37787 14.0918 9.19289 14.0918 9C14.0918 8.80712 14.0152 8.62213 13.8788 8.48574C13.7424 8.34935 13.5574 8.27273 13.3645 8.27273H9.72816V4.63637C9.72816 4.44348 9.65154 4.2585 9.51515 4.12211C9.37876 3.98572 9.19377 3.90909 9.00089 3.90909C8.808 3.90909 8.62302 3.98572 8.48663 4.12211C8.35024 4.2585 8.27361 4.44348 8.27361 4.63637Z"
    //               fill="#161616"
    //             ></path>
    //           </svg>
    //         )}
    //       </span>
    //     </p>
    //     {openSection === "MY COMPANY" && (
    //       <>
    //         <NavItem
    //           to="/organizations"
    //           icon={StudioLogo}
    //           title="Organizations"
    //         />
    //         <NavItem to="/clients" icon={ClientLogo} title="Clients" />
    //         <NavItem
    //           to="/portfolio/create"
    //           icon={StudioLogo}
    //           title="Portfolio"
    //         />
    //       </>
    //     )}
    //   </div>

    //   <div>
    //     <p
    //       onClick={() => toggleSection("DESKTOP TOOL")}
    //       className="mt-[38px] ml-[40px] mb-[12px] font_medium_12 text-[#111] cursor-pointer flex justify-between items-center"
    //     >
    //       DESKTOP TOOL
    //       <span className={`opacity-70 transition-transform  mr-[10%]  `}>
    //         {openSection === "DESKTOP TOOL" ? (
    //           <svg
    //             xmlns="http://www.w3.org/2000/svg"
    //             width="24"
    //             height="24"
    //             viewBox="0 0 24 24"
    //             fill="none"
    //           >
    //             <path
    //               d="M12 12L7 17M12 12L17 7M12 12L17 17M12 12L7 7"
    //               stroke="#161616"
    //               stroke-width="2"
    //               stroke-linecap="round"
    //               stroke-linejoin="round"
    //             ></path>
    //           </svg>
    //         ) : (
    //           <svg
    //             xmlns="http://www.w3.org/2000/svg"
    //             width="23"
    //             height="23"
    //             viewBox="0 0 18 18"
    //             fill="none"
    //           >
    //             <path
    //               d="M8.27361 4.63637V8.27273H4.63725C4.44437 8.27273 4.25938 8.34935 4.12299 8.48574C3.9866 8.62213 3.90998 8.80712 3.90998 9C3.90998 9.19289 3.9866 9.37787 4.12299 9.51426C4.25938 9.65065 4.44437 9.72728 4.63725 9.72728H8.27361V13.3636C8.27361 13.5565 8.35024 13.7415 8.48663 13.8779C8.62302 14.0143 8.808 14.0909 9.00089 14.0909C9.19377 14.0909 9.37876 14.0143 9.51515 13.8779C9.65154 13.7415 9.72816 13.5565 9.72816 13.3636V9.72728H13.3645C13.5574 9.72728 13.7424 9.65065 13.8788 9.51426C14.0152 9.37787 14.0918 9.19289 14.0918 9C14.0918 8.80712 14.0152 8.62213 13.8788 8.48574C13.7424 8.34935 13.5574 8.27273 13.3645 8.27273H9.72816V4.63637C9.72816 4.44348 9.65154 4.2585 9.51515 4.12211C9.37876 3.98572 9.19377 3.90909 9.00089 3.90909C8.808 3.90909 8.62302 3.98572 8.48663 4.12211C8.35024 4.2585 8.27361 4.44348 8.27361 4.63637Z"
    //               fill="#161616"
    //             ></path>
    //           </svg>
    //         )}
    //       </span>
    //     </p>
    //     {openSection === "DESKTOP TOOL" && (
    //       <>
    //         <NavItem to="/crewusers" icon={userIcon} title="Crew Management" />
    //         <NavItem to="/tools" icon={SettingsLogo} title="Tools" />
    //       </>
    //     )}
    //   </div>

    //   <div>
    //     <p
    //       onClick={() => toggleSection("STORAGE")}
    //       className="mt-[38px] ml-[40px] mb-[12px] font_medium_12 text-[#111] cursor-pointer flex justify-between items-center"
    //     >
    //       STORAGE
    //       <span className={`opacity-70 transition-transform  mr-[10%]  `}>
    //         {openSection === "STORAGE" ? (
    //           <svg
    //             xmlns="http://www.w3.org/2000/svg"
    //             width="24"
    //             height="24"
    //             viewBox="0 0 24 24"
    //             fill="none"
    //           >
    //             <path
    //               d="M12 12L7 17M12 12L17 7M12 12L17 17M12 12L7 7"
    //               stroke="#161616"
    //               stroke-width="2"
    //               stroke-linecap="round"
    //               stroke-linejoin="round"
    //             ></path>
    //           </svg>
    //         ) : (
    //           <svg
    //             xmlns="http://www.w3.org/2000/svg"
    //             width="23"
    //             height="23"
    //             viewBox="0 0 18 18"
    //             fill="none"
    //           >
    //             <path
    //               d="M8.27361 4.63637V8.27273H4.63725C4.44437 8.27273 4.25938 8.34935 4.12299 8.48574C3.9866 8.62213 3.90998 8.80712 3.90998 9C3.90998 9.19289 3.9866 9.37787 4.12299 9.51426C4.25938 9.65065 4.44437 9.72728 4.63725 9.72728H8.27361V13.3636C8.27361 13.5565 8.35024 13.7415 8.48663 13.8779C8.62302 14.0143 8.808 14.0909 9.00089 14.0909C9.19377 14.0909 9.37876 14.0143 9.51515 13.8779C9.65154 13.7415 9.72816 13.5565 9.72816 13.3636V9.72728H13.3645C13.5574 9.72728 13.7424 9.65065 13.8788 9.51426C14.0152 9.37787 14.0918 9.19289 14.0918 9C14.0918 8.80712 14.0152 8.62213 13.8788 8.48574C13.7424 8.34935 13.5574 8.27273 13.3645 8.27273H9.72816V4.63637C9.72816 4.44348 9.65154 4.2585 9.51515 4.12211C9.37876 3.98572 9.19377 3.90909 9.00089 3.90909C8.808 3.90909 8.62302 3.98572 8.48663 4.12211C8.35024 4.2585 8.27361 4.44348 8.27361 4.63637Z"
    //               fill="#161616"
    //             ></path>
    //           </svg>
    //         )}
    //       </span>
    //     </p>
    //     {openSection === "STORAGE" && (
    //       <div className="storage">
    //         <div className="flex items-center gap-[15px]">
    //           <img src={userIcon} alt="" className="sidebar-icon" />
    //           <p>My Storage</p>
    //           <PackageStatusBut />
    //         </div>
    //         <div className="w-full mt-[16px] bg-[#DDD] h-[15px] rounded-[4px]">
    //           <div
    //             className="bg-blue-600 h-4 rounded-full"
    //             style={{ width: `${usedPercentage || 0}%` }}
    //           ></div>
    //         </div>
    //         <div className="storageusedText mt-[9px]">
    //           {bytesToGB(storageInfo?.totalMediaCount || 0)} GB used{" "}
    //           {activePackages?.packageDetails?.storageInGb !== "unlimited" &&
    //             `${bytesToGB(storageInfo?.totalMediaSize || 0)} GB available`}
    //         </div>
    //         {activePackages?.packageDetails?.storageInGb && (
    //           <p>
    //             Total Storage : {activePackages?.packageDetails?.storageInGb} GB
    //           </p>
    //         )}
    //       </div>
    //     )}
    //   </div>

    //   <div>
    //     <p
    //       onClick={() => toggleSection("PACKAGES")}
    //       className="mt-[38px] ml-[40px] mb-[12px] font_medium_12 text-[#111] cursor-pointer flex justify-between items-center"
    //     >
    //       PACKAGES
    //       <span className={`opacity-70 transition-transform  mr-[10%]  `}>
    //         {openSection === "PACKAGES" ? (
    //           <svg
    //             xmlns="http://www.w3.org/2000/svg"
    //             width="24"
    //             height="24"
    //             viewBox="0 0 24 24"
    //             fill="none"
    //           >
    //             <path
    //               d="M12 12L7 17M12 12L17 7M12 12L17 17M12 12L7 7"
    //               stroke="#161616"
    //               stroke-width="2"
    //               stroke-linecap="round"
    //               stroke-linejoin="round"
    //             ></path>
    //           </svg>
    //         ) : (
    //           <svg
    //             xmlns="http://www.w3.org/2000/svg"
    //             width="23"
    //             height="23"
    //             viewBox="0 0 18 18"
    //             fill="none"
    //           >
    //             <path
    //               d="M8.27361 4.63637V8.27273H4.63725C4.44437 8.27273 4.25938 8.34935 4.12299 8.48574C3.9866 8.62213 3.90998 8.80712 3.90998 9C3.90998 9.19289 3.9866 9.37787 4.12299 9.51426C4.25938 9.65065 4.44437 9.72728 4.63725 9.72728H8.27361V13.3636C8.27361 13.5565 8.35024 13.7415 8.48663 13.8779C8.62302 14.0143 8.808 14.0909 9.00089 14.0909C9.19377 14.0909 9.37876 14.0143 9.51515 13.8779C9.65154 13.7415 9.72816 13.5565 9.72816 13.3636V9.72728H13.3645C13.5574 9.72728 13.7424 9.65065 13.8788 9.51426C14.0152 9.37787 14.0918 9.19289 14.0918 9C14.0918 8.80712 14.0152 8.62213 13.8788 8.48574C13.7424 8.34935 13.5574 8.27273 13.3645 8.27273H9.72816V4.63637C9.72816 4.44348 9.65154 4.2585 9.51515 4.12211C9.37876 3.98572 9.19377 3.90909 9.00089 3.90909C8.808 3.90909 8.62302 3.98572 8.48663 4.12211C8.35024 4.2585 8.27361 4.44348 8.27361 4.63637Z"
    //               fill="#161616"
    //             ></path>
    //           </svg>
    //         )}
    //       </span>
    //     </p>
    //     {openSection === "PACKAGES" && (
    //       <>
    //         <NavItem
    //           to="/choosepackage"
    //           icon={PaymentsLogo}
    //           title="Choose Package"
    //         />
    //         <NavItem
    //           to="/activepackages"
    //           icon={StudioLogo}
    //           title="Active Packages"
    //         />
    //       </>
    //     )}
    //   </div>

    //   <div>
    //     <p
    //       onClick={() => toggleSection("SUBSCRIPTIONS")}
    //       className=" mt-[38px] ml-[40px] mb-[12px] font_medium_12 text-[#111] cursor-pointer flex justify-between items-center"
    //     >
    //       SUBSCRIPTIONS
    //       <span className={`opacity-70 transition-transform  mr-[10%]  `}>
    //         {openSection === "SUBSCRIPTIONS" ? (
    //           <svg
    //             xmlns="http://www.w3.org/2000/svg"
    //             width="24"
    //             height="24"
    //             viewBox="0 0 24 24"
    //             fill="none"
    //           >
    //             <path
    //               d="M12 12L7 17M12 12L17 7M12 12L17 17M12 12L7 7"
    //               stroke="#161616"
    //               stroke-width="2"
    //               stroke-linecap="round"
    //               stroke-linejoin="round"
    //             ></path>
    //           </svg>
    //         ) : (
    //           <svg
    //             xmlns="http://www.w3.org/2000/svg"
    //             width="23"
    //             height="23"
    //             viewBox="0 0 18 18"
    //             fill="none"
    //           >
    //             <path
    //               d="M8.27361 4.63637V8.27273H4.63725C4.44437 8.27273 4.25938 8.34935 4.12299 8.48574C3.9866 8.62213 3.90998 8.80712 3.90998 9C3.90998 9.19289 3.9866 9.37787 4.12299 9.51426C4.25938 9.65065 4.44437 9.72728 4.63725 9.72728H8.27361V13.3636C8.27361 13.5565 8.35024 13.7415 8.48663 13.8779C8.62302 14.0143 8.808 14.0909 9.00089 14.0909C9.19377 14.0909 9.37876 14.0143 9.51515 13.8779C9.65154 13.7415 9.72816 13.5565 9.72816 13.3636V9.72728H13.3645C13.5574 9.72728 13.7424 9.65065 13.8788 9.51426C14.0152 9.37787 14.0918 9.19289 14.0918 9C14.0918 8.80712 14.0152 8.62213 13.8788 8.48574C13.7424 8.34935 13.5574 8.27273 13.3645 8.27273H9.72816V4.63637C9.72816 4.44348 9.65154 4.2585 9.51515 4.12211C9.37876 3.98572 9.19377 3.90909 9.00089 3.90909C8.808 3.90909 8.62302 3.98572 8.48663 4.12211C8.35024 4.2585 8.27361 4.44348 8.27361 4.63637Z"
    //               fill="#161616"
    //             ></path>
    //           </svg>
    //         )}
    //       </span>
    //     </p>
    //     {openSection === "SUBSCRIPTIONS" && (
    //       <NavItem
    //         to="/subscriptions"
    //         icon={StudioLogo}
    //         title="Subscriptions"
    //       />
    //     )}
    //   </div>
    //   <div
    //     style={{ borderTop: "1px solid rgba(140, 140, 140, 0.10)" }}
    //     className="absolute pt-[17px] justify-center items-center flex left-0 right-0 bottom-0"
    //   >
    //     <p className="poweredBy">Powered by memshots.com</p>{" "}
    //   </div>
    // </div>

    <div className="sidebar pt-[16px] ">
      <div>
        <p className="ml-[40px] mb-[8px] text-[12px] text-[#8C8C8C]">
          SERVICES
        </p>
        <NavItem
          to="/Event"
          icon2={
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
            >
              <path
                d="M7 5.75C6.59 5.75 6.25 5.41 6.25 5V2C6.25 1.59 6.59 1.25 7 1.25C7.41 1.25 7.75 1.59 7.75 2V5C7.75 5.41 7.41 5.75 7 5.75Z"
                fill="#4D58E3"
              />
              <path
                d="M15 5.75C14.59 5.75 14.25 5.41 14.25 5V2C14.25 1.59 14.59 1.25 15 1.25C15.41 1.25 15.75 1.59 15.75 2V5C15.75 5.41 15.41 5.75 15 5.75Z"
                fill="#4D58E3"
              />
              <path
                opacity="0.4"
                d="M20 8.5V17C20 20 18.5 22 15 22H7C3.5 22 2 20 2 17V8.5C2 5.5 3.5 3.5 7 3.5H15C18.5 3.5 20 5.5 20 8.5Z"
                fill="#4D58E3"
              />
              <path
                d="M13 11.75H7C6.59 11.75 6.25 11.41 6.25 11C6.25 10.59 6.59 10.25 7 10.25H13C13.41 10.25 13.75 10.59 13.75 11C13.75 11.41 13.41 11.75 13 11.75Z"
                fill="#4D58E3"
              />
              <path
                d="M10 16.75H7C6.59 16.75 6.25 16.41 6.25 16C6.25 15.59 6.59 15.25 7 15.25H10C10.41 15.25 10.75 15.59 10.75 16C10.75 16.41 10.41 16.75 10 16.75Z"
                fill="#4D58E3"
              />
              <path
                d="M21 13.63C20.11 12.92 18.98 12.5 17.75 12.5C16.52 12.5 15.37 12.93 14.47 13.66C13.26 14.61 12.5 16.1 12.5 17.75C12.5 18.73 12.78 19.67 13.26 20.45C13.63 21.06 14.11 21.59 14.68 22C15.54 22.63 16.6 23 17.75 23C19.08 23 20.28 22.51 21.2 21.69C21.61 21.35 21.96 20.93 22.24 20.45C22.72 19.67 23 18.73 23 17.75C23 16.08 22.22 14.59 21 13.63ZM17.75 20.25C17.75 18.87 16.63 17.75 15.25 17.75C16.63 17.75 17.75 16.63 17.75 15.25C17.75 16.63 18.87 17.75 20.25 17.75C18.87 17.75 17.75 18.87 17.75 20.25Z"
                fill="#4D58E3"
              />
            </svg>
          }
          icon={
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
            >
              <path
                d="M7 5.75C6.59 5.75 6.25 5.41 6.25 5V2C6.25 1.59 6.59 1.25 7 1.25C7.41 1.25 7.75 1.59 7.75 2V5C7.75 5.41 7.41 5.75 7 5.75Z"
                fill="#8C8C8C"
              />
              <path
                d="M15 5.75C14.59 5.75 14.25 5.41 14.25 5V2C14.25 1.59 14.59 1.25 15 1.25C15.41 1.25 15.75 1.59 15.75 2V5C15.75 5.41 15.41 5.75 15 5.75Z"
                fill="#8C8C8C"
              />
              <path
                opacity="0.4"
                d="M20 8.5V17C20 20 18.5 22 15 22H7C3.5 22 2 20 2 17V8.5C2 5.5 3.5 3.5 7 3.5H15C18.5 3.5 20 5.5 20 8.5Z"
                fill="#8C8C8C"
              />
              <path
                d="M13 11.75H7C6.59 11.75 6.25 11.41 6.25 11C6.25 10.59 6.59 10.25 7 10.25H13C13.41 10.25 13.75 10.59 13.75 11C13.75 11.41 13.41 11.75 13 11.75Z"
                fill="#8C8C8C"
              />
              <path
                d="M10 16.75H7C6.59 16.75 6.25 16.41 6.25 16C6.25 15.59 6.59 15.25 7 15.25H10C10.41 15.25 10.75 15.59 10.75 16C10.75 16.41 10.41 16.75 10 16.75Z"
                fill="#8C8C8C"
              />
              <path
                d="M21 13.63C20.11 12.92 18.98 12.5 17.75 12.5C16.52 12.5 15.37 12.93 14.47 13.66C13.26 14.61 12.5 16.1 12.5 17.75C12.5 18.73 12.78 19.67 13.26 20.45C13.63 21.06 14.11 21.59 14.68 22C15.54 22.63 16.6 23 17.75 23C19.08 23 20.28 22.51 21.2 21.69C21.61 21.35 21.96 20.93 22.24 20.45C22.72 19.67 23 18.73 23 17.75C23 16.08 22.22 14.59 21 13.63ZM17.75 20.25C17.75 18.87 16.63 17.75 15.25 17.75C16.63 17.75 17.75 16.63 17.75 15.25C17.75 16.63 18.87 17.75 20.25 17.75C18.87 17.75 17.75 18.87 17.75 20.25Z"
                fill="#8C8C8C"
              />
            </svg>
          }
          title="Events"
        />
        <p className="mt-[13px] ml-[40px] mb-[8px] text-[12px] text-[#8C8C8C]">
          MY COMPANY
        </p>
        <NavItem
          to="/organizations"
          icon2={
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
            >
              <path
                opacity="0.4"
                d="M22 7.81V16.19C22 19 20.71 20.93 18.44 21.66C17.78 21.89 17.02 22 16.19 22H7.81C6.98 22 6.22 21.89 5.56 21.66C3.29 20.93 2 19 2 16.19V7.81C2 4.17 4.17 2 7.81 2H16.19C19.83 2 22 4.17 22 7.81Z"
                fill="#4D58E3"
              />
              <path
                d="M18.4401 21.6597C17.7801 21.8897 17.0201 21.9998 16.1901 21.9998H7.81006C6.98006 21.9998 6.22006 21.8897 5.56006 21.6597C5.91006 19.0197 8.67006 16.9697 12.0001 16.9697C15.3301 16.9697 18.0901 19.0197 18.4401 21.6597Z"
                fill="#4D58E3"
              />
              <path
                d="M15.5799 11.58C15.5799 13.56 13.9799 15.17 11.9999 15.17C10.0199 15.17 8.41992 13.56 8.41992 11.58C8.41992 9.60002 10.0199 8 11.9999 8C13.9799 8 15.5799 9.60002 15.5799 11.58Z"
                fill="#4D58E3"
              />
            </svg>
          }
          icon={
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
            >
              <path
                opacity="0.4"
                d="M22 7.81V16.19C22 19 20.71 20.93 18.44 21.66C17.78 21.89 17.02 22 16.19 22H7.81C6.98 22 6.22 21.89 5.56 21.66C3.29 20.93 2 19 2 16.19V7.81C2 4.17 4.17 2 7.81 2H16.19C19.83 2 22 4.17 22 7.81Z"
                fill="#8C8C8C"
              />
              <path
                d="M18.4401 21.6597C17.7801 21.8897 17.0201 21.9998 16.1901 21.9998H7.81006C6.98006 21.9998 6.22006 21.8897 5.56006 21.6597C5.91006 19.0197 8.67006 16.9697 12.0001 16.9697C15.3301 16.9697 18.0901 19.0197 18.4401 21.6597Z"
                fill="#8C8C8C"
              />
              <path
                d="M15.5799 11.58C15.5799 13.56 13.9799 15.17 11.9999 15.17C10.0199 15.17 8.41992 13.56 8.41992 11.58C8.41992 9.60002 10.0199 8 11.9999 8C13.9799 8 15.5799 9.60002 15.5799 11.58Z"
                fill="#8C8C8C"
              />
            </svg>
          }
          title="Profile"
        />
        <NavItem
          to="/clients"
          icon2={
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
            >
              <path d="M16 8H8V16H16V8Z" fill="#4D58E3" />
              <path
                opacity="0.4"
                d="M5 22C6.65 22 8 20.65 8 19V16H5C3.35 16 2 17.35 2 19C2 20.65 3.35 22 5 22Z"
                fill="#4D58E3"
              />
              <path
                opacity="0.4"
                d="M5 8H8V5C8 3.35 6.65 2 5 2C3.35 2 2 3.35 2 5C2 6.65 3.35 8 5 8Z"
                fill="#4D58E3"
              />
              <path
                opacity="0.4"
                d="M16 8H19C20.65 8 22 6.65 22 5C22 3.35 20.65 2 19 2C17.35 2 16 3.35 16 5V8Z"
                fill="#4D58E3"
              />
              <path
                opacity="0.4"
                d="M19 22C20.65 22 22 20.65 22 19C22 17.35 20.65 16 19 16H16V19C16 20.65 17.35 22 19 22Z"
                fill="#4D58E3"
              />
            </svg>
          }
          icon={
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
            >
              <path d="M16 8H8V16H16V8Z" fill="#8C8C8C" />
              <path
                opacity="0.4"
                d="M5 22C6.65 22 8 20.65 8 19V16H5C3.35 16 2 17.35 2 19C2 20.65 3.35 22 5 22Z"
                fill="#8C8C8C"
              />
              <path
                opacity="0.4"
                d="M5 8H8V5C8 3.35 6.65 2 5 2C3.35 2 2 3.35 2 5C2 6.65 3.35 8 5 8Z"
                fill="#8C8C8C"
              />
              <path
                opacity="0.4"
                d="M16 8H19C20.65 8 22 6.65 22 5C22 3.35 20.65 2 19 2C17.35 2 16 3.35 16 5V8Z"
                fill="#8C8C8C"
              />
              <path
                opacity="0.4"
                d="M19 22C20.65 22 22 20.65 22 19C22 17.35 20.65 16 19 16H16V19C16 20.65 17.35 22 19 22Z"
                fill="#8C8C8C"
              />
            </svg>
          }
          title="Clients"
        />
        <NavItem
          to="/portfolio/create"
          icon2={
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
            >
              <path
                opacity="0.4"
                d="M16.24 3.6499H7.76004C5.29004 3.6499 3.29004 5.6599 3.29004 8.1199V17.5299C3.29004 19.9899 5.30004 21.9999 7.76004 21.9999H16.23C18.7 21.9999 20.7 19.9899 20.7 17.5299V8.1199C20.71 5.6499 18.7 3.6499 16.24 3.6499Z"
                fill="#4D58E3"
              />
              <path
                d="M14.3498 2H9.64977C8.60977 2 7.75977 2.84 7.75977 3.88V4.82C7.75977 5.86 8.59977 6.7 9.63977 6.7H14.3498C15.3898 6.7 16.2298 5.86 16.2298 4.82V3.88C16.2398 2.84 15.3898 2 14.3498 2Z"
                fill="#4D58E3"
              />
              <path
                d="M15 12.9502H8C7.59 12.9502 7.25 12.6102 7.25 12.2002C7.25 11.7902 7.59 11.4502 8 11.4502H15C15.41 11.4502 15.75 11.7902 15.75 12.2002C15.75 12.6102 15.41 12.9502 15 12.9502Z"
                fill="#4D58E3"
              />
              <path
                d="M12.38 16.9502H8C7.59 16.9502 7.25 16.6102 7.25 16.2002C7.25 15.7902 7.59 15.4502 8 15.4502H12.38C12.79 15.4502 13.13 15.7902 13.13 16.2002C13.13 16.6102 12.79 16.9502 12.38 16.9502Z"
                fill="#4D58E3"
              />
            </svg>
          }
          icon={
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
            >
              <path
                opacity="0.4"
                d="M16.24 3.6499H7.76004C5.29004 3.6499 3.29004 5.6599 3.29004 8.1199V17.5299C3.29004 19.9899 5.30004 21.9999 7.76004 21.9999H16.23C18.7 21.9999 20.7 19.9899 20.7 17.5299V8.1199C20.71 5.6499 18.7 3.6499 16.24 3.6499Z"
                fill="#8C8C8C"
              />
              <path
                d="M14.3498 2H9.64977C8.60977 2 7.75977 2.84 7.75977 3.88V4.82C7.75977 5.86 8.59977 6.7 9.63977 6.7H14.3498C15.3898 6.7 16.2298 5.86 16.2298 4.82V3.88C16.2398 2.84 15.3898 2 14.3498 2Z"
                fill="#8C8C8C"
              />
              <path
                d="M15 12.9502H8C7.59 12.9502 7.25 12.6102 7.25 12.2002C7.25 11.7902 7.59 11.4502 8 11.4502H15C15.41 11.4502 15.75 11.7902 15.75 12.2002C15.75 12.6102 15.41 12.9502 15 12.9502Z"
                fill="#8C8C8C"
              />
              <path
                d="M12.38 16.9502H8C7.59 16.9502 7.25 16.6102 7.25 16.2002C7.25 15.7902 7.59 15.4502 8 15.4502H12.38C12.79 15.4502 13.13 15.7902 13.13 16.2002C13.13 16.6102 12.79 16.9502 12.38 16.9502Z"
                fill="#8C8C8C"
              />
            </svg>
          }
          title="Portfolio"
        />
        {/* <p className="mt-[38px] ml-[40px] mb-[12px] font_medium_12 text-[#8C8C8C]">
          ANALYTICS
        </p>
        <NavItem to="/portfolioLeads" icon={StudioLogo} title="Leads" />
        <NavItem to="" icon={ClientLogo} title="Customer Loyality" /> */}

        <p className="mt-[13px]  ml-[40px] mb-[8px] text-[12px] text-[#8C8C8C]">
          PACKAGES
        </p>
        <NavItem
          to="/choosepackage"
          icon2={
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
            >
              <path
                d="M22 15.7C22 15.69 21.99 15.68 21.98 15.67C21.94 15.61 21.89 15.55 21.84 15.5C21.83 15.49 21.82 15.47 21.81 15.46C21 14.56 19.81 14 18.5 14C17.24 14 16.09 14.52 15.27 15.36C14.48 16.17 14 17.28 14 18.5C14 19.34 14.24 20.14 14.65 20.82C14.87 21.19 15.15 21.53 15.47 21.81C15.49 21.82 15.5 21.83 15.51 21.84C15.56 21.89 15.61 21.93 15.67 21.98C15.67 21.98 15.67 21.98 15.68 21.98C15.69 21.99 15.7 22 15.71 22C16.46 22.63 17.43 23 18.5 23C20.14 23 21.57 22.12 22.35 20.82C22.58 20.43 22.76 20 22.87 19.55C22.96 19.21 23 18.86 23 18.5C23 17.44 22.63 16.46 22 15.7ZM20.18 19.23H19.25V20.2C19.25 20.61 18.91 20.95 18.5 20.95C18.09 20.95 17.75 20.61 17.75 20.2V19.23H16.82C16.41 19.23 16.07 18.89 16.07 18.48C16.07 18.07 16.41 17.73 16.82 17.73H17.75V16.84C17.75 16.43 18.09 16.09 18.5 16.09C18.91 16.09 19.25 16.43 19.25 16.84V17.73H20.18C20.59 17.73 20.93 18.07 20.93 18.48C20.93 18.89 20.6 19.23 20.18 19.23Z"
                fill="#4D58E3"
              />
              <path
                opacity="0.4"
                d="M19.3499 5.65995L13.0599 2.27C12.3999 1.91 11.5999 1.91 10.9299 2.27L4.63991 5.65995C4.17991 5.90995 3.8999 6.39998 3.8999 6.93998C3.8999 7.47998 4.17991 7.96995 4.63991 8.21995L10.9299 11.61C11.2599 11.79 11.6299 11.88 11.9899 11.88C12.3499 11.88 12.7199 11.79 13.0499 11.61L19.3399 8.21995C19.7999 7.96995 20.0799 7.47998 20.0799 6.93998C20.0999 6.39998 19.8099 5.90995 19.3499 5.65995Z"
                fill="#4D58E3"
              />
              <path
                opacity="0.4"
                d="M9.90021 12.7899L4.05022 9.85989C3.60022 9.62989 3.08021 9.65989 2.65021 9.91989C2.22021 10.1799 1.97021 10.6399 1.97021 11.1399V16.6699C1.97021 17.6299 2.50021 18.4899 3.36021 18.9199L9.21022 21.8399C9.41022 21.9399 9.63022 21.9899 9.85022 21.9899C10.1102 21.9899 10.3702 21.9199 10.6002 21.7699C11.0302 21.5099 11.2802 21.0499 11.2802 20.5499V15.0199C11.2902 14.0799 10.7602 13.2199 9.90021 12.7899Z"
                fill="#4D58E3"
              />
              <path
                opacity="0.4"
                d="M22.03 11.1502V15.7401C22.02 15.7301 22.01 15.7101 22 15.7001C22 15.6901 21.99 15.6801 21.9799 15.6701C21.9399 15.6101 21.89 15.5501 21.84 15.5001C21.83 15.4901 21.82 15.4702 21.81 15.4602C21 14.5602 19.81 14.0001 18.5 14.0001C17.24 14.0001 16.09 14.5201 15.27 15.3601C14.48 16.1701 14 17.2801 14 18.5001C14 19.3401 14.2399 20.1401 14.6499 20.8201C14.8199 21.1101 15.0299 21.3701 15.2599 21.6101L14.7899 21.8501C14.5899 21.9501 14.3699 22.0001 14.1499 22.0001C13.8899 22.0001 13.63 21.9302 13.39 21.7802C12.97 21.5202 12.71 21.0601 12.71 20.5601V15.0401C12.71 14.0801 13.24 13.2201 14.1 12.7901L19.95 9.87013C20.4 9.64013 20.92 9.66012 21.35 9.93012C21.77 10.1901 22.03 10.6502 22.03 11.1502Z"
                fill="#4D58E3"
              />
            </svg>
          }
          icon={
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
            >
              <path
                d="M22 15.7C22 15.69 21.99 15.68 21.98 15.67C21.94 15.61 21.89 15.55 21.84 15.5C21.83 15.49 21.82 15.47 21.81 15.46C21 14.56 19.81 14 18.5 14C17.24 14 16.09 14.52 15.27 15.36C14.48 16.17 14 17.28 14 18.5C14 19.34 14.24 20.14 14.65 20.82C14.87 21.19 15.15 21.53 15.47 21.81C15.49 21.82 15.5 21.83 15.51 21.84C15.56 21.89 15.61 21.93 15.67 21.98C15.67 21.98 15.67 21.98 15.68 21.98C15.69 21.99 15.7 22 15.71 22C16.46 22.63 17.43 23 18.5 23C20.14 23 21.57 22.12 22.35 20.82C22.58 20.43 22.76 20 22.87 19.55C22.96 19.21 23 18.86 23 18.5C23 17.44 22.63 16.46 22 15.7ZM20.18 19.23H19.25V20.2C19.25 20.61 18.91 20.95 18.5 20.95C18.09 20.95 17.75 20.61 17.75 20.2V19.23H16.82C16.41 19.23 16.07 18.89 16.07 18.48C16.07 18.07 16.41 17.73 16.82 17.73H17.75V16.84C17.75 16.43 18.09 16.09 18.5 16.09C18.91 16.09 19.25 16.43 19.25 16.84V17.73H20.18C20.59 17.73 20.93 18.07 20.93 18.48C20.93 18.89 20.6 19.23 20.18 19.23Z"
                fill="#8C8C8C"
              />
              <path
                opacity="0.4"
                d="M19.3499 5.65995L13.0599 2.27C12.3999 1.91 11.5999 1.91 10.9299 2.27L4.63991 5.65995C4.17991 5.90995 3.8999 6.39998 3.8999 6.93998C3.8999 7.47998 4.17991 7.96995 4.63991 8.21995L10.9299 11.61C11.2599 11.79 11.6299 11.88 11.9899 11.88C12.3499 11.88 12.7199 11.79 13.0499 11.61L19.3399 8.21995C19.7999 7.96995 20.0799 7.47998 20.0799 6.93998C20.0999 6.39998 19.8099 5.90995 19.3499 5.65995Z"
                fill="#8C8C8C"
              />
              <path
                opacity="0.4"
                d="M9.90021 12.7899L4.05022 9.85989C3.60022 9.62989 3.08021 9.65989 2.65021 9.91989C2.22021 10.1799 1.97021 10.6399 1.97021 11.1399V16.6699C1.97021 17.6299 2.50021 18.4899 3.36021 18.9199L9.21022 21.8399C9.41022 21.9399 9.63022 21.9899 9.85022 21.9899C10.1102 21.9899 10.3702 21.9199 10.6002 21.7699C11.0302 21.5099 11.2802 21.0499 11.2802 20.5499V15.0199C11.2902 14.0799 10.7602 13.2199 9.90021 12.7899Z"
                fill="#8C8C8C"
              />
              <path
                opacity="0.4"
                d="M22.03 11.1502V15.7401C22.02 15.7301 22.01 15.7101 22 15.7001C22 15.6901 21.99 15.6801 21.9799 15.6701C21.9399 15.6101 21.89 15.5501 21.84 15.5001C21.83 15.4901 21.82 15.4702 21.81 15.4602C21 14.5602 19.81 14.0001 18.5 14.0001C17.24 14.0001 16.09 14.5201 15.27 15.3601C14.48 16.1701 14 17.2801 14 18.5001C14 19.3401 14.2399 20.1401 14.6499 20.8201C14.8199 21.1101 15.0299 21.3701 15.2599 21.6101L14.7899 21.8501C14.5899 21.9501 14.3699 22.0001 14.1499 22.0001C13.8899 22.0001 13.63 21.9302 13.39 21.7802C12.97 21.5202 12.71 21.0601 12.71 20.5601V15.0401C12.71 14.0801 13.24 13.2201 14.1 12.7901L19.95 9.87013C20.4 9.64013 20.92 9.66012 21.35 9.93012C21.77 10.1901 22.03 10.6502 22.03 11.1502Z"
                fill="#8C8C8C"
              />
            </svg>
          }
          title="Choose Package"
        />
        {/* <NavItem to="/paymentpage" icon={PaymentsLogo} title="Payment" /> */}
        <NavItem
          to="/activepackages"
          icon2={
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
            >
              <path
                opacity="0.4"
                d="M19.3499 5.65995L13.0599 2.27C12.3999 1.91 11.5999 1.91 10.9299 2.27L4.63992 5.65995C4.17992 5.90995 3.8999 6.39998 3.8999 6.93998C3.8999 7.47998 4.17992 7.96995 4.63992 8.21995L10.9299 11.61C11.2599 11.79 11.6299 11.88 11.9899 11.88C12.3499 11.88 12.7199 11.79 13.0499 11.61L19.3399 8.21995C19.7999 7.96995 20.0799 7.47998 20.0799 6.93998C20.0999 6.39998 19.8099 5.90995 19.3499 5.65995Z"
                fill="#4D58E3"
              />
              <path
                opacity="0.4"
                d="M9.90021 12.7899L4.0502 9.85989C3.6002 9.62989 3.08021 9.65989 2.65021 9.91989C2.22021 10.1799 1.97021 10.6399 1.97021 11.1399V16.6699C1.97021 17.6299 2.5002 18.4899 3.3602 18.9199L9.21021 21.8399C9.4102 21.9399 9.63022 21.9899 9.85022 21.9899C10.1102 21.9899 10.3702 21.9199 10.6002 21.7699C11.0302 21.5099 11.2802 21.0499 11.2802 20.5499V15.0199C11.2902 14.0799 10.7602 13.2199 9.90021 12.7899Z"
                fill="#4D58E3"
              />
              <path
                opacity="0.4"
                d="M22.03 11.1502V15.7401C22.02 15.7301 22.01 15.7101 22 15.7001C22 15.6901 21.99 15.6801 21.98 15.6701C21.94 15.6101 21.89 15.5501 21.84 15.5001C21.83 15.4901 21.82 15.4702 21.81 15.4602C21 14.5602 19.81 14.0001 18.5 14.0001C17.24 14.0001 16.09 14.5201 15.27 15.3601C14.48 16.1701 14 17.2801 14 18.5001C14 19.3401 14.24 20.1401 14.65 20.8201C14.82 21.1101 15.03 21.3701 15.26 21.6101L14.79 21.8501C14.59 21.9501 14.37 22.0001 14.15 22.0001C13.89 22.0001 13.63 21.9302 13.39 21.7802C12.97 21.5202 12.71 21.0601 12.71 20.5601V15.0401C12.71 14.0801 13.24 13.2201 14.1 12.7901L19.95 9.87013C20.4 9.64013 20.92 9.66012 21.35 9.93012C21.77 10.1901 22.03 10.6502 22.03 11.1502Z"
                fill="#4D58E3"
              />
              <path
                d="M21.98 15.67C21.16 14.66 19.91 14.02 18.5 14.02C17.44 14.02 16.46 14.39 15.69 15.01C14.65 15.83 14 17.1 14 18.52C14 19.36 14.24 20.16 14.65 20.84C14.92 21.29 15.26 21.68 15.66 22H15.67C16.44 22.64 17.43 23.02 18.5 23.02C19.64 23.02 20.67 22.6 21.46 21.9C21.81 21.6 22.11 21.24 22.35 20.84C22.76 20.16 23 19.36 23 18.52C23 17.44 22.62 16.44 21.98 15.67ZM20.76 17.96L18.36 20.18C18.22 20.31 18.03 20.38 17.85 20.38C17.66 20.38 17.47 20.31 17.32 20.16L16.21 19.05C15.92 18.76 15.92 18.28 16.21 17.99C16.5 17.7 16.98 17.7 17.27 17.99L17.87 18.59L19.74 16.86C20.04 16.58 20.52 16.6 20.8 16.9C21.09 17.21 21.07 17.68 20.76 17.96Z"
                fill="#4D58E3"
              />
            </svg>
          }
          icon={
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
            >
              <path
                opacity="0.4"
                d="M19.3499 5.65995L13.0599 2.27C12.3999 1.91 11.5999 1.91 10.9299 2.27L4.63992 5.65995C4.17992 5.90995 3.8999 6.39998 3.8999 6.93998C3.8999 7.47998 4.17992 7.96995 4.63992 8.21995L10.9299 11.61C11.2599 11.79 11.6299 11.88 11.9899 11.88C12.3499 11.88 12.7199 11.79 13.0499 11.61L19.3399 8.21995C19.7999 7.96995 20.0799 7.47998 20.0799 6.93998C20.0999 6.39998 19.8099 5.90995 19.3499 5.65995Z"
                fill="#8C8C8C"
              />
              <path
                opacity="0.4"
                d="M9.90021 12.7899L4.0502 9.85989C3.6002 9.62989 3.08021 9.65989 2.65021 9.91989C2.22021 10.1799 1.97021 10.6399 1.97021 11.1399V16.6699C1.97021 17.6299 2.5002 18.4899 3.3602 18.9199L9.21021 21.8399C9.4102 21.9399 9.63022 21.9899 9.85022 21.9899C10.1102 21.9899 10.3702 21.9199 10.6002 21.7699C11.0302 21.5099 11.2802 21.0499 11.2802 20.5499V15.0199C11.2902 14.0799 10.7602 13.2199 9.90021 12.7899Z"
                fill="#8C8C8C"
              />
              <path
                opacity="0.4"
                d="M22.03 11.1502V15.7401C22.02 15.7301 22.01 15.7101 22 15.7001C22 15.6901 21.99 15.6801 21.98 15.6701C21.94 15.6101 21.89 15.5501 21.84 15.5001C21.83 15.4901 21.82 15.4702 21.81 15.4602C21 14.5602 19.81 14.0001 18.5 14.0001C17.24 14.0001 16.09 14.5201 15.27 15.3601C14.48 16.1701 14 17.2801 14 18.5001C14 19.3401 14.24 20.1401 14.65 20.8201C14.82 21.1101 15.03 21.3701 15.26 21.6101L14.79 21.8501C14.59 21.9501 14.37 22.0001 14.15 22.0001C13.89 22.0001 13.63 21.9302 13.39 21.7802C12.97 21.5202 12.71 21.0601 12.71 20.5601V15.0401C12.71 14.0801 13.24 13.2201 14.1 12.7901L19.95 9.87013C20.4 9.64013 20.92 9.66012 21.35 9.93012C21.77 10.1901 22.03 10.6502 22.03 11.1502Z"
                fill="#8C8C8C"
              />
              <path
                d="M21.98 15.67C21.16 14.66 19.91 14.02 18.5 14.02C17.44 14.02 16.46 14.39 15.69 15.01C14.65 15.83 14 17.1 14 18.52C14 19.36 14.24 20.16 14.65 20.84C14.92 21.29 15.26 21.68 15.66 22H15.67C16.44 22.64 17.43 23.02 18.5 23.02C19.64 23.02 20.67 22.6 21.46 21.9C21.81 21.6 22.11 21.24 22.35 20.84C22.76 20.16 23 19.36 23 18.52C23 17.44 22.62 16.44 21.98 15.67ZM20.76 17.96L18.36 20.18C18.22 20.31 18.03 20.38 17.85 20.38C17.66 20.38 17.47 20.31 17.32 20.16L16.21 19.05C15.92 18.76 15.92 18.28 16.21 17.99C16.5 17.7 16.98 17.7 17.27 17.99L17.87 18.59L19.74 16.86C20.04 16.58 20.52 16.6 20.8 16.9C21.09 17.21 21.07 17.68 20.76 17.96Z"
                fill="#8C8C8C"
              />
            </svg>
          }
          title="Active Packages"
        />

        <p className="mt-[13px]  ml-[40px] mb-[8px] text-[12px] text-[#8C8C8C]">
          STORAGE
        </p>

        <div onClick={() => navigate(`/storage`)} className="cursor-pointer storage">
          <div className="flex items-start  gap-[15px]">
            <div className="sidebar-icon">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
              >
                <path
                  opacity="0.4"
                  d="M21.9902 10.8402C21.9702 10.3602 21.8902 9.89023 21.7402 9.44023C21.0502 7.28023 19.0303 5.72023 16.6503 5.72023H13.8602C13.2802 5.72023 13.2402 5.66023 12.9302 5.25023L11.5303 3.39023C10.8803 2.52023 10.3702 1.99023 8.74023 1.99023H6.41022C3.97022 1.99023 1.99023 3.97023 1.99023 6.41023V9.43022V16.6402C1.99023 19.5902 4.39027 21.9902 7.34027 21.9902H16.6403C19.5903 21.9902 21.9902 19.5902 21.9902 16.6402V11.0602C22.0002 11.0002 22.0002 10.9102 21.9902 10.8402Z"
                  fill="#8C8C8C"
                />
                <path
                  d="M15.5801 19.7C13.4701 19.85 13.4701 22.91 15.5801 23.06H20.5901C21.2001 23.06 21.78 22.84 22.23 22.43C23.71 21.14 22.92 18.54 20.97 18.3C20.27 14.08 14.16 15.68 15.61 19.7"
                  fill="#8C8C8C"
                />
              </svg>
            </div>
            {/* <img src={userIcon} alt={""} className="sidebar-icon" /> */}
            <p className="text-[12px]">My Storage</p>
          </div>
          <div className="w-full mt-[8px] bg-[#DDD]  h-[8px] rounded-[4px]">
            <div
              className="bg-[#4D58E3] h-[8px] rounded-full"
              style={{ width: `${usedPercentage || 0}%` }}
            ></div>
          </div>
          <div className="storageusedText mt-[4px]">
            {usedSize} used of
            {totalStorageGb === null
              ? " Unlimited GB "
              : ` ${totalStorageGb} GB  `}
          </div>
        </div>

        <p className="mt-[8px]  ml-[40px] mb-[8px] text-[12px] text-[#8C8C8C]">
          DESKTOP TOOL
        </p>
        {/* <NavItem to="/payments" icon={PaymentsLogo} title="Payments" /> */}
        {/* <NavItem
          to='/settings'
          icon={SettingsLogo}
          title='Settings'
        /> */}
        <NavItem
          to="/crewusers"
          icon={
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
            >
              <path
                opacity="0.4"
                d="M17.53 7.77C17.46 7.76 17.39 7.76 17.32 7.77C15.77 7.72 14.54 6.45 14.54 4.89C14.54 3.3 15.83 2 17.43 2C19.02 2 20.32 3.29 20.32 4.89C20.31 6.45 19.08 7.72 17.53 7.77Z"
                fill="#8C8C8C"
              />
              <path
                opacity="0.4"
                d="M20.7901 14.6999C19.6701 15.4499 18.1001 15.7299 16.6501 15.5399C17.0301 14.7199 17.2301 13.8099 17.2401 12.8499C17.2401 11.8499 17.0201 10.8999 16.6001 10.0699C18.0801 9.86992 19.6501 10.1499 20.7801 10.8999C22.3601 11.9399 22.3601 13.6499 20.7901 14.6999Z"
                fill="#8C8C8C"
              />
              <path
                opacity="0.4"
                d="M6.4399 7.77C6.5099 7.76 6.5799 7.76 6.6499 7.77C8.1999 7.72 9.4299 6.45 9.4299 4.89C9.4299 3.3 8.1399 2 6.5399 2C4.9499 2 3.6499 3.29 3.6499 4.89C3.6599 6.45 4.8899 7.72 6.4399 7.77Z"
                fill="#8C8C8C"
              />
              <path
                opacity="0.4"
                d="M6.55012 12.8501C6.55012 13.8201 6.76012 14.7401 7.14012 15.5701C5.73012 15.7201 4.26012 15.4201 3.18012 14.7101C1.60012 13.6601 1.60012 11.9501 3.18012 10.9001C4.25012 10.1801 5.76012 9.8901 7.18012 10.0501C6.77012 10.8901 6.55012 11.8401 6.55012 12.8501Z"
                fill="#8C8C8C"
              />
              <path
                d="M12.1198 15.87C12.0398 15.86 11.9498 15.86 11.8598 15.87C10.0198 15.81 8.5498 14.3 8.5498 12.44C8.5498 10.54 10.0798 9 11.9898 9C13.8898 9 15.4298 10.54 15.4298 12.44C15.4298 14.3 13.9698 15.81 12.1198 15.87Z"
                fill="#8C8C8C"
              />
              <path
                d="M8.8698 17.9401C7.3598 18.9501 7.3598 20.6101 8.8698 21.6101C10.5898 22.7601 13.4098 22.7601 15.1298 21.6101C16.6398 20.6001 16.6398 18.9401 15.1298 17.9401C13.4198 16.7901 10.5998 16.7901 8.8698 17.9401Z"
                fill="#8C8C8C"
              />
            </svg>
          }
          icon2={
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
            >
              <path
                opacity="0.4"
                d="M17.53 7.77C17.46 7.76 17.39 7.76 17.32 7.77C15.77 7.72 14.54 6.45 14.54 4.89C14.54 3.3 15.83 2 17.43 2C19.02 2 20.32 3.29 20.32 4.89C20.31 6.45 19.08 7.72 17.53 7.77Z"
                fill="#4D58E3"
              />
              <path
                opacity="0.4"
                d="M20.7901 14.6999C19.6701 15.4499 18.1001 15.7299 16.6501 15.5399C17.0301 14.7199 17.2301 13.8099 17.2401 12.8499C17.2401 11.8499 17.0201 10.8999 16.6001 10.0699C18.0801 9.86992 19.6501 10.1499 20.7801 10.8999C22.3601 11.9399 22.3601 13.6499 20.7901 14.6999Z"
                fill="#4D58E3"
              />
              <path
                opacity="0.4"
                d="M6.4399 7.77C6.5099 7.76 6.5799 7.76 6.6499 7.77C8.1999 7.72 9.4299 6.45 9.4299 4.89C9.4299 3.3 8.1399 2 6.5399 2C4.9499 2 3.6499 3.29 3.6499 4.89C3.6599 6.45 4.8899 7.72 6.4399 7.77Z"
                fill="#4D58E3"
              />
              <path
                opacity="0.4"
                d="M6.55012 12.8501C6.55012 13.8201 6.76012 14.7401 7.14012 15.5701C5.73012 15.7201 4.26012 15.4201 3.18012 14.7101C1.60012 13.6601 1.60012 11.9501 3.18012 10.9001C4.25012 10.1801 5.76012 9.8901 7.18012 10.0501C6.77012 10.8901 6.55012 11.8401 6.55012 12.8501Z"
                fill="#4D58E3"
              />
              <path
                d="M12.1198 15.87C12.0398 15.86 11.9498 15.86 11.8598 15.87C10.0198 15.81 8.5498 14.3 8.5498 12.44C8.5498 10.54 10.0798 9 11.9898 9C13.8898 9 15.4298 10.54 15.4298 12.44C15.4298 14.3 13.9698 15.81 12.1198 15.87Z"
                fill="#4D58E3"
              />
              <path
                d="M8.8698 17.9401C7.3598 18.9501 7.3598 20.6101 8.8698 21.6101C10.5898 22.7601 13.4098 22.7601 15.1298 21.6101C16.6398 20.6001 16.6398 18.9401 15.1298 17.9401C13.4198 16.7901 10.5998 16.7901 8.8698 17.9401Z"
                fill="#4D58E3"
              />
            </svg>
          }
          title="Crew Management"
        />
        <NavItem
          to="/tools"
          icon2={
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
            >
              <path
                opacity="0.4"
                d="M21.4702 5V19C21.4702 20.66 20.1202 22 18.4702 22H14.4702C12.8102 22 11.4702 20.66 11.4702 19V5C11.4702 3.34 12.8102 2 14.4702 2H18.4702C20.1202 2 21.4702 3.34 21.4702 5Z"
                fill="#4D58E3"
              />
              <path
                d="M17.2202 6C17.2202 6.41 16.8802 6.75 16.4702 6.75H11.4702V5.25H16.4702C16.8802 5.25 17.2202 5.59 17.2202 6Z"
                fill="#4D58E3"
              />
              <path
                d="M16.1202 18C16.1202 18.41 15.7902 18.75 15.3702 18.75H11.4702V17.25H15.3702C15.7902 17.25 16.1202 17.59 16.1202 18Z"
                fill="#4D58E3"
              />
              <path
                d="M17.2202 14.0102C17.2102 14.4202 16.8802 14.7502 16.4702 14.7502C16.4602 14.7502 16.4602 14.7502 16.4602 14.7502L11.4702 14.7002V13.2002L16.4702 13.2502C16.8902 13.2502 17.2202 13.5902 17.2202 14.0102Z"
                fill="#4D58E3"
              />
              <path
                d="M15.0302 10C15.0302 10.41 14.6902 10.75 14.2802 10.75H11.4702V9.25H14.2802C14.6902 9.25 15.0302 9.59 15.0302 10Z"
                fill="#4D58E3"
              />
              <path
                opacity="0.4"
                d="M8.43979 4.95V17.91C8.43979 18.36 8.24979 19.05 8.01979 19.43L7.19979 20.79C6.25979 22.37 4.71979 22.37 3.76979 20.79L2.95979 19.43C2.71979 19.05 2.52979 18.36 2.52979 17.91V4.95C2.52979 3.33 3.85978 2 5.48978 2C7.10979 2 8.43979 3.33 8.43979 4.95Z"
                fill="#4D58E3"
              />
              <path
                d="M8.43979 6.25H2.52979V7.75H8.43979V6.25Z"
                fill="#4D58E3"
              />
            </svg>
          }
          icon={
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
            >
              <path
                opacity="0.4"
                d="M21.4702 5V19C21.4702 20.66 20.1202 22 18.4702 22H14.4702C12.8102 22 11.4702 20.66 11.4702 19V5C11.4702 3.34 12.8102 2 14.4702 2H18.4702C20.1202 2 21.4702 3.34 21.4702 5Z"
                fill="#8C8C8C"
              />
              <path
                d="M17.2202 6C17.2202 6.41 16.8802 6.75 16.4702 6.75H11.4702V5.25H16.4702C16.8802 5.25 17.2202 5.59 17.2202 6Z"
                fill="#8C8C8C"
              />
              <path
                d="M16.1202 18C16.1202 18.41 15.7902 18.75 15.3702 18.75H11.4702V17.25H15.3702C15.7902 17.25 16.1202 17.59 16.1202 18Z"
                fill="#8C8C8C"
              />
              <path
                d="M17.2202 14.0102C17.2102 14.4202 16.8802 14.7502 16.4702 14.7502C16.4602 14.7502 16.4602 14.7502 16.4602 14.7502L11.4702 14.7002V13.2002L16.4702 13.2502C16.8902 13.2502 17.2202 13.5902 17.2202 14.0102Z"
                fill="#8C8C8C"
              />
              <path
                d="M15.0302 10C15.0302 10.41 14.6902 10.75 14.2802 10.75H11.4702V9.25H14.2802C14.6902 9.25 15.0302 9.59 15.0302 10Z"
                fill="#8C8C8C"
              />
              <path
                opacity="0.4"
                d="M8.43979 4.95V17.91C8.43979 18.36 8.24979 19.05 8.01979 19.43L7.19979 20.79C6.25979 22.37 4.71979 22.37 3.76979 20.79L2.95979 19.43C2.71979 19.05 2.52979 18.36 2.52979 17.91V4.95C2.52979 3.33 3.85978 2 5.48978 2C7.10979 2 8.43979 3.33 8.43979 4.95Z"
                fill="#8C8C8C"
              />
              <path
                d="M8.43979 6.25H2.52979V7.75H8.43979V6.25Z"
                fill="#8C8C8C"
              />
            </svg>
          }
          title="Tools & Tutorials "
        />

        {/* <p className="mt-[13px]  ml-[40px] mb-[5px] text-[12px] text-[#8C8C8C]">
          SUBSCRIPTIONS
        </p>
        <NavItem to="/subscriptions" icon={StudioLogo} title="Subscriptions" /> */}

        {/* <NavItem
          to="/activepackages"
          icon={StudioLogo}
          title="Active Packages"
        /> */}
      </div>
      <div
        style={{ borderTop: "1px solid rgba(140, 140, 140, 0.10)" }}
        className=" pt-[17px]  justify-center items-center flex "
      >
        <p className="poweredBy">Powered by memshots.com</p>{" "}
      </div>
    </div>
  );
}

function NavItem({ to, icon, title, exact, icon2 }) {
  const location = useLocation();
  const isActive = location.pathname === to;

  return (
    <NavLink
      exact={exact}
      to={to}
      className={`sidebar-link ${isActive ? "active" : ""}`}
    >
      {icon && <div className="sidebar-icon"> {isActive ? icon2 : icon}</div>}
      {/* {icon && <img src={icon} alt={title} className="sidebar-icon" />} */}
      <p className="text-[13px]">{title}</p>
    </NavLink>
  );
}

export default Sidebar;
