import React, { useContext, useEffect, useState } from "react";
import "./Navbar.css";
import { userAuthContext } from "../../Context/UserAuthContext.js";
import { dataContext } from "../../Context/DataContext";
import { useLocation, useNavigate } from "react-router-dom";
import poweredBy from "../../Assets/NavbarIcons/poweredBy.png";
import UsersDropDown from "../UsersDropDown/UsersDropDown.js";
import HamburgerDropDown from "../HamburgerDropDown/HamburgerDropDown.js";
import PackageShowPopUp from "../ChoosePackageComponent/PackageShowPopUp.js";
import PackageStatusBut from "../packageStatusBut/PackageStatusBut.js";
//import notification from '../../Assets/NavbarIcons/notification.svg'
//import currency from '../../Assets/NavbarIcons/currency.svg'
//import options from '../../Assets/NavbarIcons/options.svg'

const Navbar = () => {
  const { googleSignOut } = useContext(userAuthContext);
  const {
    roleSelected,
    setRoleSelected,
    studioRole,
    studioRoleLoading,
    setStudioId,
    activePackages,
    searchTerm,
    studioId,
    setSearchTerm,
    handleNavigateTrial,
  } = useContext(dataContext);
  const navigate = useNavigate();
  const location = useLocation();
  const [logoUrl, setLogoUrl] = useState("");

  useEffect(() => {
    const extensions = ["png", "jpeg", "jpg"];
    let found = false;

    const checkLogo = async () => {
      for (let ext of extensions) {
        // const url = `https://storage.googleapis.com/studioprofile/${studioId}/vcd.${ext}`;
        const url = `https://storage.googleapis.com/studioprofile/${studioId}/logo.${ext}`;
        try {
          const response = await fetch(url);
          if (response.ok) {
            setLogoUrl(url);
            found = true;
            break;
          }
        } catch (error) {
          console.error(`Error fetching logo with .${ext} extension`, error);
        }
      }
      if (!found) {
        // setLogoUrl(fallbackUrl);
      }
    };

    checkLogo();
  }, [studioId]);

  return (
    <div className="navbar">
      <div className="flex justify-between items-center">
        <div className="flex  gap-[2px]">
          <div>
            {studioRoleLoading && <div>Loading...</div>}
            {studioRole && studioRole.length !== 0 && !studioRoleLoading && (
              <div className="flex items-center">
                <img
                  // src={`https://storage.googleapis.com/studioprofile/${studioId}/logo_light.png `}
                  src={logoUrl || roleSelected?.studioLogo}
                  alt=""
                  className="w-[32px] h-[32px] object-contain rounded-[4px]"
                />
                <p className=" ml-[11px] font_medium_18 w-[170px] truncate ">
                  {roleSelected?.studioName}
                </p>
                <UsersDropDown
                  studioRole={studioRole}
                  setRoleSelected={setRoleSelected}
                  setStudioId={setStudioId}
                />
              </div>
            )}
            {studioRole && studioRole.length === 0 && !studioRoleLoading && (
              <div className="flex gap-[8px]">
                {/* <button class="bg-black hover:bg-slate-500 text-white font-bold py-2 px-4 rounded" onClick={() =>
                  navigate(
                    `/organizations`,
                  )
                }>
                  Create Organisation
                </button> */}
              </div>
            )}
          </div>
          <div className="ml-[43px]">
            {/* <img src={poweredBy} alt="" className='w-[118px] h-[11px]' /> */}
          </div>
          <PackageStatusBut />
        </div>
        <div className="flex gap-5 items-center">
          {location.pathname === "/Event" && (
            <div className="searchBox relative">
              <div className="absolute left-2">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                >
                  <path
                    d="M21.0002 21L16.6572 16.657M16.6572 16.657C17.4001 15.9141 17.9894 15.0322 18.3914 14.0615C18.7935 13.0909 19.0004 12.0506 19.0004 11C19.0004 9.9494 18.7935 8.90908 18.3914 7.93845C17.9894 6.96782 17.4001 6.08589 16.6572 5.343C15.9143 4.60011 15.0324 4.01082 14.0618 3.60877C13.0911 3.20673 12.0508 2.99979 11.0002 2.99979C9.9496 2.99979 8.90929 3.20673 7.93866 3.60877C6.96803 4.01082 6.08609 4.60011 5.34321 5.343C3.84288 6.84333 3 8.87821 3 11C3 13.1218 3.84288 15.1567 5.34321 16.657C6.84354 18.1573 8.87842 19.0002 11.0002 19.0002C13.122 19.0002 15.1569 18.1573 16.6572 16.657Z"
                    stroke="#8C8C8C"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </div>
              <input
                style={{
                  borderRadius: "8px",
                  border: "1px solid rgba(140, 140, 140, 0.20)",
                  background: "#FFF",
                }}
                className="w-full h-full   focus:outline-none pl-[40px] py-[10px]"
                type="search"
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                placeholder="Search event"
              />
            </div>
          )}

          {/* {!activePackages?.activePackage ? (
            <button
              onClick={() => handleNavigateTrial()}
              className="trailPlan border-[1px] border-[#787878] border-opacity-70 px-[20px] py-[10px] rounded-[10px]"
            >
              {" "}
              Start Free Trial{" "}
            </button>
          ) : activePackages?.packageStatus === "ACTIVE" &&
            activePackages?.packageDetails?.packageType !== "YEARLY" ? (
            <button onClick={() => handleNavigateTrial()} className="trailPlan">
              {" "}
              Start Free Trial{" "}
            </button>
          ) : (
            ""
          )} */}
          {location?.pathname !== "/choosepackage" &&
          !activePackages?.activePackage ? (
            <button
              onClick={() => navigate(`/choosepackage`)}
              className="eventButton"
            >
              {" "}
              Upgrade Now{" "}
            </button>
          ) : activePackages?.packageStatus === "ACTIVE" &&
            activePackages?.packageDetails?.packageType !== "YEARLY" ? (
            <button
              onClick={() => navigate(`/choosepackage`)}
              className="eventButton"
            >
              {" "}
              Upgrade Now{" "}
            </button>
          ) : (
            ""
          )}
          {/* <div>
            {activePackages?.activePackage === true ? (
              <button
                onClick={() => navigate(`/activepackages`)}
                className="border-[1px] text-green-500 border-green-500 p-[10px] rounded-[10px] flex items-center gap-2"
              >
                <span className="flex w-3 h-3 me-3  bg-green-500 rounded-full"></span>{" "}
                Package is {activePackages?.packageStatus || "Active"}
              </button>
            ) : (
              <button
                onClick={() => navigate(`/activepackages`)}
                className="border-[1px] text-red-500 border-red-500 p-[10px] rounded-[10px] flex items-center gap-2"
              >
                <span className="flex w-3 h-3 me-3  bg-red-500 rounded-full"></span>{" "}
                Package {activePackages?.packageStatus || "Not Active"}
              </button>
            )}
          </div> */}
          <HamburgerDropDown googleSignOut={googleSignOut} />
        </div>
      </div>
    </div>
  );
};

export default Navbar;
