import React, {
  useState,
  useEffect,
  useContext,
  useCallback,
  useRef,
} from "react";
import { useParams } from "react-router-dom";
import { userAuthContext } from "../../Context/UserAuthContext";
import {
  ref,
  uploadBytesResumable,
} from "firebase/storage";
import { photosharetool } from "../../store/firebase-config";
import axios from "axios";
import QRCode from "react-qr-code";
import { dataContext } from "../../Context/DataContext";
import { bucketStorageMap } from "../../store/firebase-config";
import LoadingSpinner from "../../Components/LoadingSpinner/LoadingSpinner";
const QRPopup = ({ qrCodeValue, onClose }) => {
  return (
    <div className="fixed inset-0 bg-black bg-opacity-75 flex items-center justify-center z-50">
      <div className="bg-white rounded-lg p-6 w-96">
        <div className="flex justify-between items-center">
          <h2 className="text-xl font-bold">QR Code</h2>
          <button
            onClick={onClose}
            className="text-red-500 text-lg font-bold hover:text-red-700"
          >
            X
          </button>
        </div>
        <div className="flex flex-col items-center justify-center mt-4">
          {qrCodeValue ? (
            <>
              <QRCode value={qrCodeValue} size={200} />
              <p className="text-sm mt-2 break-words text-center">
                {qrCodeValue}
              </p>
            </>
          ) : (
            <p className="text-sm text-gray-500">No QR Code Available</p>
          )}
        </div>
      </div>
    </div>
  );
};

const PhotoShareUserSelection = () => {
  const [qrCodeValue, setQRCodeValue] = useState(null); // Value for QR code
  const [showQRPopup, setShowQRPopup] = useState(false); // QR Popup State
  const [images, setImages] = useState([]);
  const [selectedImages, setSelectedImages] = useState([]);
  const [refresh, setRefresh] = useState(false);
  const [progress, setProgress] = useState(0);
  const [albumDetails, setAlbumDetails] = useState({});
  const [isUploading, setIsUploading] = useState(false);
  const [loading, setLoading] = useState(false);
  const [visibleImages, setVisibleImages] = useState(100); // Number of images to display initially
  const { projectId, shootmatesAlbumId, sectionId } = useParams();
  const { userIdToken, currentUser, verifyUser } = useContext(userAuthContext);
  const tokenData = userIdToken || currentUser?.accessToken;
  const { studioId, studioDetails } = useContext(dataContext);

  useEffect(() => {
    verifyUser();
    const fetchData = async () => {
      if (studioId) {
        setLoading(true);
        try {
          await getUploadedMediasData();
        } catch (error) {
          console.error("Error fetching data:", error);
        } finally {
          setLoading(false);
        }
      }
    };
    fetchData();
  }, [studioId, refresh]);

  const splitString = (str) => {
    const parts = str.split('/');     
    return `${parts[2]}/${parts[3]}`
  };

  const getThumbnail = (url) => {
    const replacements = ["aimemshots", "shootmatesprivatecloud", "albumdata"];
    const targetReplacement = "albumthumbnails";
    const urlParts = url.split("/");
    const updatedParts = urlParts.map((part) =>
      replacements.includes(part) ? targetReplacement : part
    );
    const lastPart = updatedParts.at(-1);
    const modifiedFilename = lastPart.includes(".")
      ? `d_${lastPart}`
      : `d_${lastPart}`;
    updatedParts[updatedParts.length - 1] = modifiedFilename;
    return updatedParts.join("/");
  };

  const getQrcodeUrl = (fileId) => {
    return `https://${
      studioDetails?.studioUniqueText || "photosharetool"
    }.memshots.com/${
      albumDetails?.slugId
    }/qrsharephoto?imgid=${splitString(fileId)}`;
  };

  const getBucketName = (type) => {
    switch (type) {
      case "photoselection":
      case "album":
      case "photosharetool":
        return "albumdata";
      case "aipaid":
        return "shootmatesprivatecloud";
      case "aiunpaid":
        return "aimemshots";
      default:
        return "albumdata";
    }
  };

  const getUploadedMediasData = useCallback(async () => {
    try {
      const config = {
        method: "get",
        url: `https://asia-south1-shootmates.cloudfunctions.net/medias/api/shootmates/medias/getallimagesfromalbum/${projectId}/${studioId}/${shootmatesAlbumId}`,
        headers: {
          Authorization: `Bearer ${tokenData}`,
          "Content-Type": "application/json",
        },
      };
      const response = await axios(config);
      let mediaFiles = [];
      if (response.status === 200) {
        setAlbumDetails(response?.data?.data);
        const sections = response?.data?.data?.sections || [];
        const mediaDatas = await Promise.all(
          sections.map(async (item) => {
            mediaFiles.push(...item?.sectionData?.media);
          })
        );
        mediaFiles.sort((a, b) => new Date(b.uploadedTime) - new Date(a.uploadedTime));

        setImages(mediaFiles);
      }
    } catch (error) {
      console.error("Error fetching album details:", error);
    }
  }, [projectId, studioId, shootmatesAlbumId, tokenData]);

  // const getAllMediaFromAlbum = async (memshotAlbumId, type) => {
  //   try {
  //     const bucketName = getBucketName(type);
  //     const selectedStorage = bucketStorageMap[bucketName];
  //     if (!selectedStorage) throw new Error("Invalid bucket name.");
  //     const albumRef = ref(selectedStorage, `${memshotAlbumId}/`);
  //     const albumResult = await listAll(albumRef);
  //     const folderPrefixes = albumResult.prefixes;

  //     const allMediaFiles = [];

  //     // Process each folder concurrently
  //     await Promise.all(
  //       folderPrefixes.map(async (folderRef) => {
  //         const folderResult = await listAll(folderRef);

  //         // Fetch metadata and process media files
  //         const mediaFiles = await Promise.all(
  //           folderResult.items
  //             .filter((item) => item.name !== "folderCheck.keep")
  //             .map(async (item) => {
  //               const metadata = await getMetadata(item); // Fetch metadata
  //               return {
  //                 name: item.name,
  //                 fullPath: `https://storage.googleapis.com/${bucketName}/${item.fullPath}`,
  //                 folder: folderRef.name,
  //                 qrurl: `https://${
  //                   studioDetails?.studioUniqueText || "photosharetool"
  //                 }.memshots.com/${
  //                   albumDetails?.slugId
  //                 }/qrsharephoto?imgid=${splitAtFirstSlash(item.fullPath)}`,
  //                 thumbnail: `https://storage.googleapis.com/albumthumbnails/${memshotAlbumId}/${folderRef.name}/d_${item.name}`,
  //                 createdAt: new Date(metadata.timeCreated), // Add createdAt
  //               };
  //             })
  //         );

  //         // Sort media files by createdAt in descending order
  //         mediaFiles.sort((a, b) => b.createdAt - a.createdAt);

  //         allMediaFiles.push(...mediaFiles);
  //       })
  //     );

  //     setImages(allMediaFiles); // Update state with sorted files
  //   } catch (error) {
  //     console.error("Error fetching media from album:", error);
  //   }
  // };

  const handleUpload = () => {
    const bucketName = getBucketName(albumDetails?.type);
    const selectedStorage = bucketStorageMap[bucketName];
    if (!selectedStorage) throw new Error("Invalid bucket name.");
    setIsUploading(true);
    const name = `${Date.now()}`;
    const jsonData = {
      media: selectedImages,
      studioUniqueText: studioDetails?.studioUniqueText || "photosharetool",
      slugId: albumDetails?.slugId,
      name: name,
    };
    const jsonBlob = new Blob([JSON.stringify(jsonData)], {
      type: "application/json",
    });

    const storageRef = ref(
      photosharetool,
      `${albumDetails?.memshotAlbumId}/${name}.json`
    );

    const uploadTask = uploadBytesResumable(storageRef, jsonBlob);

    uploadTask.on(
      "state_changed",
      (snapshot) => {
        const progress =
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        setProgress(progress);
      },
      (error) => {
        console.error("Upload failed:", error);
        setIsUploading(false);
      },
      async () => {
        setIsUploading(false);
        setQRCodeValue(
          `https://${
            studioDetails?.studioUniqueText || "photosharetool"
          }.memshots.com/${albumDetails?.slugId}/QRPhotoShare/${name}`
        );
        setShowQRPopup(true);
      }
    );
  };

  const toggleSelectImage = (id) => {
    setSelectedImages(
      (prevSelected) =>
        prevSelected.includes(id)
          ? prevSelected.filter((imageId) => imageId !== id) // Deselect
          : [...prevSelected, id] // Select
    );
  };

  const refreshSelection = () => {
    setRefresh(!refresh);
  };

  const clearCart = () => {
    setSelectedImages([]);
  };

  const loadMoreImages = () => {
    setVisibleImages((prev) => prev + 20); // Increase visible images by 20
  };

  useEffect(() => {
    const handleScroll = () => {
      if (
        window.innerHeight + document.documentElement.scrollTop ===
        document.documentElement.offsetHeight
      ) {
        loadMoreImages();
      }
    };
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  return (
    <div className="min-h-screen bg-gradient-to-b from-black to-gray-900 text-white">
      <header className="p-6 flex justify-between items-center bg-gradient-to-r from-gray-800 to-gray-900 shadow-lg">
        <h1 className="text-2xl font-extrabold tracking-wide">Photo Sharing</h1>
        <div className="flex items-center space-x-6">
          <p className="text-lg font-semibold">
            Selected: {selectedImages.length}
          </p>
          {selectedImages?.length > 0 && (
            <>
              <button
                onClick={handleUpload}
                className="px-4 py-2 bg-green-500 text-white font-bold rounded-lg hover:bg-green-400 transition duration-200"
              >
                {isUploading ? "Generating Qr Code" : "   Generate QR Code"}
              </button>
              <button
                onClick={clearCart}
                className="px-4 py-2 bg-red-500 text-white font-bold rounded-lg hover:bg-red-400 transition duration-200"
              >
                Clear Selection
              </button>
            </>
          )}

          <button
            onClick={refreshSelection}
            className="px-4 py-2 bg-yellow-500 text-white font-bold rounded-lg hover:bg-yellow-400 transition duration-200"
          >
            Refresh
          </button>
        </div>
      </header>
      {loading ? (
        <LoadingSpinner />
      ) : (
        <div className="grid grid-cols-5 sm:grid-cols-6 lg:grid-cols-7 gap-6 p-8">
          {images.slice(0, visibleImages).map((image) => (
            <div
              key={image?._id}
              className={`relative rounded-lg overflow-hidden border-4 ${
                selectedImages.includes(image?.url)
                  ? "border-green-400"
                  : "border-gray-300"
              } transition transform hover:scale-105`}
            >
              <img
                src={getThumbnail(image?.url)}
                alt={image.name}
                className="w-full h-40 object-cover"
                onClick={() => toggleSelectImage(image?.url)}
              />
              <label
                className="absolute top-2 left-2 z-10"
                htmlFor={`checkbox-${image?.url}`}
              >
                <input
                  type="checkbox"
                  id={`checkbox-${image?.url}`}
                  checked={selectedImages.includes(image?.url)}
                  onChange={() => toggleSelectImage(image?.url)}
                  className="w-6 h-6 rounded border-2 border-white bg-transparent text-green-500 focus:ring-2 focus:ring-green-500"
                />
              </label>
              <div className="bg-white p-2 mt-2 rounded-lg shadow-lg flex justify-center">
                <QRCode
                  value={
                    getQrcodeUrl(image?.fileId)
                  }
                  size={80}
                />
              </div>
            </div>
          ))}
        </div>
      )}
      {visibleImages < images.length && (
        <div className="text-center my-6">
          <button
            onClick={loadMoreImages}
            className="px-6 py-3 bg-blue-600 text-white font-bold rounded-lg hover:bg-blue-500 transition duration-200"
          >
            View More
          </button>
        </div>
      )}
      {/* QR Popup */}
      {showQRPopup && (
        <QRPopup
          qrCodeValue={qrCodeValue}
          onClose={() => setShowQRPopup(false)}
        />
      )}
    </div>
  );
};

export default PhotoShareUserSelection;
