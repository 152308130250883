import React, { useContext, useEffect, useState } from "react";
import axios from "axios";
import Slider from "../Slider/Slider";
import { userAuthContext } from "../../Context/UserAuthContext";
import Loading from "../../Assets/Animation/Loading";
function RegisteredUsersPopup({
  studioId,
  memshotAlbumId,
  projectId,
  setViewRegisteredUsersPopup,
}) {
  const [refreshing, setRefreshing] = useState(true);
  const [registeredUsers, setRegisteredUsers] = useState([]);
  const [filterOption, setFilterOption] = useState("ALL");
  const [selectedUser, setSelectedUser] = useState(null);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const { userIdToken, currentUser, verifyUser } = useContext(userAuthContext);
  const tokenData = userIdToken || currentUser?.accessToken;

  useEffect(() => {
    const fetchRegisteredUsers = async () => {
      try {
        await verifyUser();
        let config = {
          method: "get",
           url: `https://asia-south1-shootmates.cloudfunctions.net/shootmatesMemshotsServices/api/shootmates/aiservices/regfaces/${projectId}/${studioId}/${memshotAlbumId}?reqType=${filterOption}`,
          // url:`https://asia-south1-shootmates.cloudfunctions.net/test_shootmatesMemshotsServices/api/shootmates/aiservices/regfaces/${projectId}/${studioId}/${memshotAlbumId}?reqType=${filterOption}`,
         
          headers: {
            Authorization: `Bearer ${tokenData}`,
            "Content-Type": "application/json",
          },
        };
        const response = await axios(config);
        setRegisteredUsers(response?.data?.data);
        setRefreshing(false);
      } catch (error) {
        console.error("Error fetching registered users:", error);
        setRefreshing(false);
      }
    };
    fetchRegisteredUsers();
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refreshing,filterOption]);

  const handleCopyLink = (link) => {
    const textarea = document.createElement("textarea");
    textarea.value = `https://app.memshots.com/faces/${link}`;
    document.body.appendChild(textarea);
    textarea.select();
    document.execCommand("copy");
    document.body.removeChild(textarea);
  };

  const handleViewFaces = (user) => {
    setSelectedUser(user);
    setCurrentImageIndex(0);
  };

  const handlePreviousImage = () => {
    setCurrentImageIndex((prevIndex) =>
      prevIndex > 0 ? prevIndex - 1 : selectedUser.faceUrl.length - 1
    );
  };

  const handleNextImage = () => {
    setCurrentImageIndex((prevIndex) =>
      prevIndex < selectedUser.faceUrl.length - 1 ? prevIndex + 1 : 0
    );
  };

  const handleCloseSlider = () => {
    setSelectedUser(null);
  };
  const getVerified = (user) => {
    if (user?.email) {
      return user?.email;
    }
    if (user?.whatsAppNo) {
      return user?.whatsAppNo;
    }
    return user?.regWhatsAppNo ? user?.regWhatsAppNo : "email not verified";
  };
  const convertMongoDBTimestampToIST = (mongodbTimestamp) => {
    const utcTime = new Date(mongodbTimestamp);
    const istTime = utcTime.toLocaleString("en-US", {
      timeZone: "Asia/Kolkata",
    });
    return istTime;
  };
  return (
    <div className="bg-white max-w-6xl w-full h-[600px] overflow-y-auto rounded-md shadow-md">
      {selectedUser && (
        <Slider
          memshotAlbumId={memshotAlbumId}
          images={selectedUser?.faceUrl}
          currentIndex={currentImageIndex}
          onPrev={handlePreviousImage}
          onNext={handleNextImage}
          onClose={handleCloseSlider}
        />
      )}
      <div className="flex justify-between items-center p-6 sticky top-0 bg-white">
        <p className="text-xl font-semibold text-blue-500">Registered Users</p>
        <div className="flex items-center">
          <span className="text-sm text-gray-500 mr-2">{`Total Users Registered: ${
            refreshing ? "Refreshing..." : registeredUsers?.counter
          }`}</span>
          <button
            onClick={() => setRefreshing(!refreshing)}
            className={`text-sm text-white bg-blue-500 hover:bg-blue-600 px-4 py-2 rounded-full focus:outline-none focus:ring focus:border-green-300 ${
              refreshing && "opacity-50 cursor-not-allowed"
            }`}
            disabled={refreshing}
          >
            {refreshing ? "Refreshing..." : "Refresh"}
          </button>
          <button
            onClick={() => setViewRegisteredUsersPopup(false)}
            className="text-sm text-white bg-red-500 hover:bg-gray-600 px-4 py-2 ml-2 rounded-full focus:outline-none focus:ring focus:border-green-300"
          >
            Close
          </button>
        </div>
      </div>
      <div className="flex justify-center items-center p-4">
        <button
          onClick={() => setFilterOption("ALL")}
          className={`text-sm px-4 py-2 mx-2 rounded-full focus:outline-none ${
            filterOption === "ALL" ? "bg-blue-500 text-white" : "bg-gray-200 text-gray-700"
          }`}
        >
          All
        </button>
        <button
          onClick={() => setFilterOption("WITHOUT_RESULTS")}
          className={`text-sm px-4 py-2 mx-2 rounded-full focus:outline-none ${
            filterOption === "WITHOUT_RESULTS" ? "bg-blue-500 text-white" : "bg-gray-200 text-gray-700"
          }`}
        >
          NO RESULTS
        </button>
        <button
          onClick={() => setFilterOption("WITH_RESULTS")}
          className={`text-sm px-4 py-2 mx-2 rounded-full focus:outline-none ${
            filterOption === "WITH_RESULTS" ? "bg-blue-500 text-white" : "bg-gray-200 text-gray-700"
          }`}
        >
          WITH RESULTS
        </button>
      </div>
      {refreshing ? (
        <Loading />
      ) : (
        <div className="overflow-x-auto px-6 pb-6">
          <table className="min-w-full border border-gray-300">
            <thead>
              <tr className="bg-blue-500 text-white">
                <th className="py-2 px-4 border-b">No</th>
                <th className="py-2 px-4 border-b">Name</th>
                <th className="py-2 px-4 border-b">Email/Number</th>
                <th className="py-2 px-4 border-b">Registered Faces</th>
                <th className="py-2 px-4 border-b">Total results Count</th>
                <th className="py-2 px-4 border-b">View Results</th>
                <th className="py-2 px-4 border-b">Registered Time</th>
              </tr>
            </thead>
            <tbody>
              {registeredUsers &&
                registeredUsers.peopleFaces?.length > 0 &&
                registeredUsers.peopleFaces?.map((user, index) => (
                  <tr
                    key={index}
                    className={`${
                      index % 2 === 0 ? "bg-green-100" : "bg-green-200"
                    }`}
                  >
                    <td className="py-2 px-4 border-b">{index + 1}</td>
                    <td className="py-2 px-4 border-b">{user?.name}</td>
                    <td className="py-2 px-4 border-b">{getVerified(user)}</td>
                    <td className="py-2 px-4 border-b">
                      {user?.faceUrl && user?.faceUrl.length > 0 ? (
                        <button
                          onClick={() => handleViewFaces(user)}
                          className="text-sm text-white bg-blue-500 hover:bg-blue-600 px-2 py-1 rounded-full focus:outline-none focus:ring focus:border-green-300"
                        >
                          Show Registered Faces
                        </button>
                      ) : (
                        <button className="text-sm text-white bg-red-500 hover:bg-red-600 px-2 py-1 rounded-full focus:outline-none focus:ring focus:border-green-300">
                          No Faces
                        </button>
                      )}
                    </td>
                    <td className="py-2 px-4 border-b">
                      {user?.totalResultcount ? user?.totalResultcount : 0}
                    </td>
                    <td className="py-2 px-4 border-b">
                      {user?.encodedString && (
                        <button
                          onClick={() => handleCopyLink(user?.encodedString)}
                          className="text-sm text-white bg-green-500 hover:bg-green-600 px-2 py-1 rounded-full focus:outline-none focus:ring focus:border-green-300"
                        >
                          Copy Link
                        </button>
                      )}
                    </td>
                    <td className="py-2 px-4 border-b">
                      {convertMongoDBTimestampToIST(user?.registeredAt)}
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
}

export default RegisteredUsersPopup;