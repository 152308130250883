import axios from "axios";
import { useContext } from "react";

export const addWorkFlowTask = async (
  setLoading,
  flowId,
  studioId,
  arrayLength,
  newNumber,
  tokenData,
  formValues,
  values,
  setTodo,
  setAddWorkflowTask,
  setWorkFlowTasks,
  setUpdate,
  update
) => {
  setLoading(true);
  try {
    let config = {
      method: "post",
      url: `${process.env.REACT_APP_CREATE_PROJECT_WORKFLOW_TASK}/${flowId}/${studioId}?operationType=ADD_WORKFLOWTASK&workflowTaskToPosition=${newNumber}&workFlowEventType=${formValues.workflowEventType}`,
      headers: {
        Authorization: `Bearer ${tokenData}`,
        "Content-Type": "application/json",
      },
      data: { newTask: { ...formValues, ...values, number: newNumber } },
    };
    const response = await axios(config);
    setTodo(false);
    setAddWorkflowTask(false);
    if (response.status === 201) {
      //setWorkFlowTasks(response?.data?.data?.workflows?.workflowTasks)
      setUpdate(!update);
      setLoading(false);
    }
  } catch (error) {
    console.log("error", error);
    setTodo(true);
    setLoading(false);
  }
};

export const createAlbum = async (
  pricingData,
  totalPrice,
  albumType,
  setLoading,
  eventId,
  studioId,
  newNumber,
  tokenData,
  formValues,
  values,
  setAlbum,
  setUpdate,
  update
) => {
  setLoading(true);
  try {
    let config = {
      method: "post",
      // url: `https://asia-south1-shootmates.cloudfunctions.net/shootmatesMemshotsServices/api/shootmates/albumservices/createalbum/${eventId}/${studioId}?albumType=${albumType}`,
      url: `https://asia-south1-shootmates.cloudfunctions.net/shootmatesMemshotsServices/api/shootmates/albumservices/createalbum/${eventId}/${studioId}?albumType=${albumType}`,

      headers: {
        Authorization: `Bearer ${tokenData}`,
        "Content-Type": "application/json",
      },
      data: {
        newTask: {
          ...formValues,
          ...values,
          number: newNumber,
          pricingData: pricingData,
          totalPrice: totalPrice,
          type: "album",
        },
      },
    };
    const response = await axios(config);
    setAlbum(false);
    if (response.status === 201) {
      setUpdate(!update);
      setLoading(false);
    }
  } catch (error) {
    console.log("error", error);
    setAlbum(false);
    setLoading(false);
  }
};

export const createAlbumLatest = async (
  pricingData,
  totalPrice,
  albumType,
  setLoading,
  eventId,
  studioId,
  newNumber,
  tokenData,
  formValues,
  values,
  setAlbum,
  setUpdate,
  update,
  packageType,
  paymentPlan
) => {
  setLoading(true);

  try {
    let config = {
      method: "post",
      // url: `https://asia-south1-shootmates.cloudfunctions.net/shootmatesMemshotsServices/api/shootmates/albumservices/createalbumlatest/${eventId}/${studioId}?albumType=${albumType}`,
      url: `https://asia-south1-shootmates.cloudfunctions.net/shootmatesMemshotsServices/api/shootmates/albumservices/createalbumlatest/${eventId}/${studioId}?albumType=${albumType}`,

      headers: {
        Authorization: `Bearer ${tokenData}`,
        "Content-Type": "application/json",
      },
      data: {
        newTask: {
          ...formValues,
          ...values,
          number: newNumber,
          pricingData: pricingData,
          totalPrice: totalPrice,
          packageType: packageType,
          paymentPlan: paymentPlan || "",
          type: "album",
        },
      },
    };
    const response = await axios(config);
    if (response?.data?.error === true) {
      setLoading(false);
      return { errorMsg: response?.data?.msg || "Something went wrong" };
    }
    // setAlbum(false);
    if (response.status === 201) {
      // setUpdate(!update);
      // setLoading(false);
      return response?.data;
    }
  } catch (error) {
    console.log("error", error);
    setAlbum(false);
    setLoading(false);
  }
};

export const createInvitation = async (
  pricingData,
  totalPrice,
  albumType,
  setLoading,
  eventId,
  studioId,
  newNumber,
  tokenData,
  formValues,
  values,
  setAlbum,
  setUpdate,
  update
) => {
  setLoading(true);
  try {
    let config = {
      method: "post",
      // url: `https://asia-south1-shootmates.cloudfunctions.net/shootmatesMemshotsServices/api/shootmates/albumservices/createinvitation/${eventId}/${studioId}?albumType=${albumType}`,
      url: `https://asia-south1-shootmates.cloudfunctions.net/shootmatesMemshotsServices/api/shootmates/albumservices/createinvitation/${eventId}/${studioId}?albumType=${albumType}`,
      headers: {
        Authorization: `Bearer ${tokenData}`,
        "Content-Type": "application/json",
      },
      data: {
        newTask: {
          ...formValues,
          ...values,
          number: newNumber,
          pricingData: pricingData,
          totalPrice: totalPrice,
        },
      },
    };
    const response = await axios(config);

    if (response.status === 201) {
      // setUpdate(!update);
      // setLoading(false);
      return response?.data;
    }
  } catch (error) {
    console.log("error", error);
    setAlbum(false);
    setLoading(false);
  }
};

export const createInvitationLatest = async (
  pricingData,
  totalPrice,
  albumType,
  setLoading,
  eventId,
  studioId,
  newNumber,
  tokenData,
  formValues,
  values,
  setAlbum,
  setUpdate,
  update,
  packageType,
  paymentPlan
) => {
  setLoading(true);
  try {
    let config = {
      method: "post",
      url: `https://asia-south1-shootmates.cloudfunctions.net/shootmatesMemshotsServices/api/shootmates/albumservices/createinvitationlatest/${eventId}/${studioId}?albumType=${albumType}`,
      // url: `https://asia-south1-shootmates.cloudfunctions.net/shootmatesMemshotsServices/api/shootmates/albumservices/createinvitationlatest/${eventId}/${studioId}?albumType=${albumType}`,
      headers: {
        Authorization: `Bearer ${tokenData}`,
        "Content-Type": "application/json",
      },
      data: {
        newTask: {
          ...formValues,
          ...values,
          number: newNumber,
          pricingData: pricingData,
          totalPrice: totalPrice,
          packageType: packageType,
          paymentPlan: paymentPlan || "",
        },
      },
    };
    const response = await axios(config);
    if (response?.data?.error === true) {
      setLoading(false);
      return { errorMsg: response?.data?.msg || "Something went wrong" };
    }
    if (response.status === 201) {
      // setUpdate(!update);
      // setLoading(false);
      return response?.data;
    }
  } catch (error) {
    console.log("error", error);
    setAlbum(false);
    setLoading(false);
  }
};

export const createFaceDetection = async (
  pricingData,
  totalPrice,
  albumType,
  setLoading,
  eventId,
  studioId,
  newNumber,
  tokenData,
  formValues,
  values,
  setAlbum,
  setUpdate,
  update,
  packageType,
  paymentPlan
) => {
  setLoading(true);
  try {
    let config = {
      method: "post",
      // url: `https://asia-south1-shootmates.cloudfunctions.net/shootmatesMemshotsServices/api/shootmates/albumservices/createfacedetection/${eventId}/${studioId}?albumType=${albumType}`,
      url: `https://asia-south1-shootmates.cloudfunctions.net/shootmatesMemshotsServices/api/shootmates/albumservices/createfacedetection/${eventId}/${studioId}?albumType=${albumType}`,
      headers: {
        Authorization: `Bearer ${tokenData}`,
        "Content-Type": "application/json",
      },
      data: {
        newTask: {
          ...formValues,
          ...values,
          number: newNumber,
          pricingData: pricingData,
          totalPrice: totalPrice,
          packageType: packageType,
          paymentPlan: paymentPlan || "",
          type: formValues?.paidMode === false ? "aiunpaid" : "aipaid",
        },
      },
    };
    const response = await axios(config);
    if (response?.data?.error === true) {
      setLoading(false);
      return { errorMsg: response?.data?.msg || "Something went wrong" };
    }
    // setAlbum(false);
    if (response.status === 201) {
      // setUpdate(!update);
      // setLoading(false);
      return response?.data;
    }
  } catch (error) {
    console.log("error", error);
    setAlbum(false);
    setLoading(false);
  }
};

export const createSelectionTool = async (
  pricingData,
  totalPrice,
  albumType,
  setLoading,
  eventId,
  studioId,
  newNumber,
  tokenData,
  formValues,
  values,
  setAlbum,
  setUpdate,
  update,
  packageType,
  paymentPlan
) => {
  setLoading(true);
  console.log(paymentPlan, "ay");
  try {
    let config = {
      method: "post",
      // url: `https://asia-south1-shootmates.cloudfunctions.net/shootmatesMemshotsServices/api/shootmates/albumservices/createselectiontool/${eventId}/${studioId}?albumType=${albumType}`,
      url: `https://asia-south1-shootmates.cloudfunctions.net/shootmatesMemshotsServices/api/shootmates/albumservices/createselectiontool/${eventId}/${studioId}?albumType=${albumType}`,
      headers: {
        Authorization: `Bearer ${tokenData}`,
        "Content-Type": "application/json",
      },
      data: {
        newTask: {
          ...formValues,
          ...values,
          number: newNumber,
          pricingData: pricingData,
          totalPrice: totalPrice,
          packageType: packageType,
          paymentPlan: paymentPlan || "",
          type: "photoselection",
        },
      },
    };
    const response = await axios(config);
    if (response?.data?.error === true) {
      setLoading(false);
      return { errorMsg: response?.data?.msg || "Something went wrong" };
    }
    if (response.status === 201) {
      // setUpdate(!update);
      // setLoading(false);

      return response?.data;
    }
  } catch (error) {
    console.log("error", error);
    setAlbum(false);
    setLoading(false);
  }
};

export const createPhotoShareTool = async (
  pricingData,
  totalPrice,
  albumType,
  setLoading,
  eventId,
  studioId,
  newNumber,
  tokenData,
  formValues,
  values,
  setAlbum,
  setUpdate,
  update,
  packageType,
  paymentPlan
) => {
  setLoading(true);
  try {
    let config = {
      method: "post",
      // url: `https://asia-south1-shootmates.cloudfunctions.net/shootmatesMemshotsServices/api/shootmates/albumservices/createphotosharetool/${eventId}/${studioId}?albumType=${albumType}`,
      url: `https://asia-south1-shootmates.cloudfunctions.net/shootmatesMemshotsServices/api/shootmates/albumservices/createphotosharetool/${eventId}/${studioId}?albumType=${albumType}`,
      headers: {
        Authorization: `Bearer ${tokenData}`,
        "Content-Type": "application/json",
      },
      data: {
        newTask: {
          ...formValues,
          ...values,
          number: newNumber,
          pricingData: pricingData,
          totalPrice: totalPrice,
          packageType: packageType,
          paymentPlan: paymentPlan || "",
          type: "photosharetool",
        },
      },
    };
    const response = await axios(config);
    if (response?.data?.error === true) {
      setLoading(false);
      return { errorMsg: response?.data?.msg || "Something went wrong" };
    }
    if (response.status === 201) {
      // setUpdate(!update);
      // setLoading(false);
      return response?.data;
    }
  } catch (error) {
    console.log("error", error);
    setAlbum(false);
    setLoading(false);
  }
};

export const createDuplicateSelectionTool = async (
  pricingData,
  totalPrice,
  albumType,
  setLoading,
  eventId,
  studioId,
  tokenData,
  formValues,
  setAlbum,
  setUpdate,
  update,
  shootmatesalbumId,
  packageType,
  paymentPlan
) => {
  setLoading(true);
  try {
    let config = {
      method: "post",
      // url: `https://asia-south1-shootmates.cloudfunctions.net/shootmatesMemshotsServices/api/shootmates/albumservices/createMemshotsDocumentClone/${eventId}/${studioId}/${shootmatesalbumId}?albumType=${albumType}`,
      url: `https://asia-south1-shootmates.cloudfunctions.net/shootmatesMemshotsServices/api/shootmates/albumservices/createMemshotsDocumentClone/${eventId}/${studioId}/${shootmatesalbumId}?albumType=${albumType}`,

      headers: {
        Authorization: `Bearer ${tokenData}`,
        "Content-Type": "application/json",
      },
      data: {
        newTask: {
          ...formValues,
          pricingData: pricingData,
          totalPrice: totalPrice,
          packageType: packageType,
          paymentPlan: paymentPlan || "",
          type: "photoselection",
        },
      },
    };
    const response = await axios(config);
    if (response?.data?.error === true) {
      setLoading(false);
      return { errorMsg: response?.data?.msg || "Something went wrong" };
    }
    if (response.status === 201) {
      // setUpdate(!update);
      // setLoading(false);
      return response?.data;
    }
  } catch (error) {
    console.log("error", error);
    setAlbum(false);
    setLoading(false);
  }
};

export const updateWorkFlowTask = async (
  setLoading,
  flowId,
  studioId,
  taskId,
  tokenData,
  formValues,
  setTodo,
  setAddWorkflowTask,
  setUpdate,
  update
) => {
  try {
    setLoading(true);
    let config = {
      method: "patch",
      url: `${process.env.REACT_APP_UPDATE_PROJECT_WORKFLOW_TASK}/${flowId}/${studioId}?operationType=NORMAL_TASK_UPDATE&taskId=${taskId}`,
      headers: {
        Authorization: `Bearer ${tokenData}`,
        "Content-Type": "application/json",
      },
      data: {
        ...formValues,
        crew: formValues.canAssignCrew ? formValues.crew : {},
      },
    };
    const response = await axios(config);
    setTodo(false);
    setAddWorkflowTask(false);
    if (response.status === 200) {
      setLoading(false);
      setUpdate(!update);
    }
  } catch (error) {
    console.log("error", error);
    setTodo(true);
    setLoading(false);
  }
};
