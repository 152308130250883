import React, { useContext, useEffect, useState } from "react";
import "./TrainingSession.css";
import TrainingFolder from "../../Components/Forms/TrainingSessionForm/TrainingFolder";
import Lottie from "../../Assets/Animation/Loading";
import { useParams, useLocation, useNavigate } from "react-router-dom";
//import fileUploadIcon from "../../Assets/fileUploadIcon.svg";
import AddUser from "../../Components/Forms/AddUserForm/AddUser";
//import DesignPoster from "../../Components/DesignPoster/DesignPoster";
import userIcon from "../../Assets/userIcon.svg";
import { dataContext } from "../../Context/DataContext";
import { userAuthContext } from "../../Context/UserAuthContext";
import ErrorPopup from "../../Components/PopUps/ErrorPopup/ErrorPopup";
import options from "../../Assets/NavbarIcons/options.svg";
import { PopupWrapper } from "../../UI/PopupWrapper/PopupWrapper";
import MetaDataPopup from "../../Components/MetaDataPopup/MetaDataPopup";
import RegisteredUsersPopup from "../../Components/RegisteredUsersPopup/RegisteredUserspopup";
import PaidPhotosHistory from "../../Components/PaidPhotosHistory/PaidPhotosHistory";
import LiveUsersPopup from "../../Components/LiveUsersPopup/LiveUsersPopup";
import axios from "axios";
import CrewUsers from "../../Components/PopUps/CrewUsers";
//import albumThumb from "../../Assets/albumThumn.png";
//import refresshIcon from "../../Assets/refresshIcon.svg";
import detailsIcon from "../../Assets/detailsIcon.svg";
import qrCodeIcon from "../../Assets/qrCodeIcon.svg";
import eventFaceDetection from "../../Assets/eventFaceDetection.svg";
import ConfirmationPopup from "../../Components/PopUps/Confirmationpopup/ConfirmationPopup";
import Breadcrumb from "../../Components/BreadCrumb/BreadCrumb";
import Watermark from "../../Components/Watermark/Watermark";
import QrCodeGenerator from "../../Components/QrCodeGenerator/QrCodeGenerator";
//import UserListPopup from "../../Components/PopUps/UserListPopup";

function TrainingSession() {
  const location = useLocation();
  const navigate = useNavigate();
  const initialFormData = {
    folderName: "",
    gdriveUrl: "",
    description: "",
    coverImage: "",
    title: "",
  };
  const [viewRegisteredUsersPopup, setViewRegisteredUsersPopup] =
    useState(false);
  const [viewUserListPopup, setViewUserListPopup] = useState(false);
  const [viewLiveUsersPopup, setViewLiveUsersPopup] = useState(false);
  // const [designPoster, setDesignPoster] = useState(false);
  const [viewQrPopup, setViewQrPopup] = useState(false);
  const [paidPhotosSection, setPaidPhotosSection] = useState(false);
  const [formData, setFormData] = useState(initialFormData);
  const [addWatermarkPopup, setAddWatermarkPopup] = useState(false);
  const [formErrors, setFormErrors] = useState({
    folderName: "",
    gdriveUrl: "",
  });
  const uploadTypes = ["DESKTOP", "GOOGLE_DRIVE"];
  const [selectedUploadType, setSelectedUploadType] = React.useState(
    uploadTypes[0]
  );
  const [addUserPopup, setAddUserPopup] = useState(false);
  const [sessionForm, setsessionForm] = useState(false);
  const [update, setUpdate] = useState(false);
  const [showConfirmation, setShowConfirmation] = useState({ show: false });
  const [loading, setLoading] = useState(true);
  const [metaDataPopup, setMetaDataPopup] = useState(false);
  const { projectId, shootmatesAlbumId } = useParams();
  const [error, setError] = useState({
    errorStatus: false,
    errorMessage: "",
    header: "",
  });
  const [albmTrainingSession, setalbmTrainingSession] = useState([]);
  const [albumDetails, setAlbumDetails] = useState({});
  const { userIdToken, currentUser, verifyUser } = useContext(userAuthContext);
  const tokenData = userIdToken || currentUser?.accessToken;
  const { studioId } = useContext(dataContext);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        await verifyUser();
        if (studioId) {
          await Promise.all([getTrainingSessionData(), getAlbumDetails()]);
          setLoading(false);
        } else {
          setLoading(false);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
        setLoading(false);
      }
    };
    fetchData();
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [studioId, update]);

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, [location]);

  const getTrainingSessionData = async () => {
    try {
      let config = {
        method: "get",
        url: `${process.env.REACT_APP_GET_TRAINING_SESSION_DATA}/${projectId}/${studioId}/${shootmatesAlbumId}`,
        headers: {
          Authorization: `Bearer ${tokenData}`,
          "Content-Type": "application/json",
        },
      };
      const response = await axios(config);
      if (response.status === 200) {
        if (response?.data?.data?.googleDriveLink) {
          setFormData({
            ...formData,
            gdriveUrl: response?.data?.data?.googleDriveLink,
          });
          //setGdriveUrl(response?.data?.data?.googleDriveLink)
        } else {
          setFormData({ ...formData, gdriveUrl: "" });
        }
        setalbmTrainingSession(response?.data?.data?.sections);
      }
    } catch (error) {
      console.log("error", error);
      setLoading(false);
    }
  };
  const getAlbumDetails = async () => {
    try {
      let config = {
        method: "get",
        url: `${process.env.REACT_APP_GET_ALBUM_DETAILS_DATA}/${projectId}/${studioId}/${shootmatesAlbumId}`,
        headers: {
          Authorization: `Bearer ${tokenData}`,
          "Content-Type": "application/json",
        },
      };
      const response = await axios(config);
      if (response.status === 200) {
        setAlbumDetails(response?.data?.data);
      }
    } catch (error) {
      console.log("error", error);
      setLoading(false);
    }
  };
  //publishToFaceDetectionTraining
  const publishToFaceDetectionTraining = async (
    totalImages,
    processedImages,
    folderId,
    folderName
  ) => {
    verifyUser();
    setLoading(true);
    try {
      verifyUser();
      let config = {
        method: "post",
        url: `https://asia-south1-shootmates.cloudfunctions.net/shootmatesMemshotsServices/api/shootmates/aiservices/publishfacesession/${projectId}/${studioId}/${shootmatesAlbumId}?privatePhotos=${
          albumDetails?.paidPhotos === true ? true : false
        }`,

        // url: `https://asia-south1-shootmates.cloudfunctions.net/test_shootmatesMemshotsServices/api/shootmates/aiservices/publishfacesession/${projectId}/${studioId}/${shootmatesAlbumId}?privatePhotos=${
        //   albumDetails?.paidPhotos === true ? true : false
        // }`,
        data: {
          folderId: folderId,
          folderName: folderName,
        },
        headers: {
          Authorization: `Bearer ${tokenData}`,
          "Content-Type": "application/json",
        },
      };
      const response = await axios(config);
      if (response.status === 200) {
        if (response?.data?.error) {
          setError({
            errorMessage: response?.data?.msg,
            errorStatus: true,
            header: "Message",
          });
          setUpdate(!update);
          setShowConfirmation({ show: false });
          setUpdate(!update);
          setShowConfirmation({ show: false });
          setLoading(false);
        } else {
          setError({
            errorMessage: response?.data?.msg,
            errorStatus: false,
            header: "Message",
          });
          setUpdate(!update);
          setShowConfirmation({ show: false });
          setUpdate(!update);
          setShowConfirmation({ show: false });
          setLoading(false);
        }
      }
    } catch (error) {
      console.log("error", error);
      setUpdate(!update);
      setShowConfirmation({ show: false });
      setLoading(false);
    }
  };

  const handlecreatefolder = () => {
    setsessionForm(true);
  };

  const handleAlbumMetaDataButton = () => {
    if (!albumDetails?.memshotAlbumId) {
      setError({
        errorMessage: "Memshots Album is Processing Please Wait",
        errorStatus: true,
        header: "Message",
      });
      return;
    }
    setMetaDataPopup(true);
  };

  const handleRegisteredDataButton = () => {
    if (!albumDetails?.memshotAlbumId) {
      setError({
        errorMessage: "Memshots Album is Processing Please Wait",
        errorStatus: true,
        header: "Message",
      });
      return;
    }
    setViewRegisteredUsersPopup(true);
  };

  const handleLiveDataButton = () => {
    setViewLiveUsersPopup(true);
  };

  const calculateTimeDifference = (
    compressionEndTime,
    compressionStartTime,
    key,
    status
  ) => {
    let time = null;
    if (!compressionStartTime || !compressionEndTime) {
      if (key === "compress") {
        return (time = ``);
      } else {
        return (time = ``);
      }
    }
    if (compressionStartTime === compressionEndTime) {
      if (status === "COMPLETED") {
        return (time = `0 hours 0 minutes 0 seconds`);
      } else {
        return (time = ``);
      }
    }
    const timeDifferenceInMilliseconds =
      new Date(compressionEndTime) - new Date(compressionStartTime);
    const millisecondsInSecond = 1000;
    const millisecondsInMinute = 60 * millisecondsInSecond;
    const millisecondsInHour = 60 * millisecondsInMinute;
    const hours = Math.floor(timeDifferenceInMilliseconds / millisecondsInHour);
    const minutes = Math.floor(
      (timeDifferenceInMilliseconds % millisecondsInHour) / millisecondsInMinute
    );
    const seconds = Math.floor(
      (timeDifferenceInMilliseconds % millisecondsInMinute) /
        millisecondsInSecond
    );
    time = `${hours}h ${minutes}m ${seconds}s`;
    return time;
  };

  const handleConfirmation = async (showConfirmation) => {
    const folderId = showConfirmation?.folderId;
    const totalImages = showConfirmation?.totalImages;
    const processedImages = showConfirmation?.processedImages;
    const folderName = showConfirmation?.folderName;
    await publishToFaceDetectionTraining(
      totalImages,
      processedImages,
      folderId,
      folderName
    );
  };

  const handleSubmit = async () => {
    try {
      verifyUser();
      let isValid = true;
      const errors = {};
      if (formData.folderName.trim() === "") {
        errors.folderName = "Gallery Name is empty";
        isValid = false;
      }
      if (selectedUploadType === "GOOGLE_DRIVE") {
        if (formData.gdriveUrl.trim() === "") {
          errors.gdriveUrl = "Google Drive URL is empty";
          isValid = false;
        }
      }
      if (!isValid) {
        setFormErrors(errors);
        return;
      }
      setLoading(true);
      let url =
        selectedUploadType === "GOOGLE_DRIVE"
          ? `https://asia-south1-shootmates.cloudfunctions.net/medias/api/shootmates/medias/creategdrivefolderdashboardai/${projectId}/${studioId}/${shootmatesAlbumId}`
          : `https://asia-south1-shootmates.cloudfunctions.net/medias/api/shootmates/medias/createfolderdashboard/${projectId}/${studioId}/${shootmatesAlbumId}`;

        // selectedUploadType === "GOOGLE_DRIVE"
        //   ? `https://asia-south1-shootmates.cloudfunctions.net/test_medias/api/shootmates/medias/creategdrivefolderdashboardai/${projectId}/${studioId}/${shootmatesAlbumId}`
        //   : `https://asia-south1-shootmates.cloudfunctions.net/test_medias/api/shootmates/medias/createfolderdashboard/${projectId}/${studioId}/${shootmatesAlbumId}`;

      let config = {
        method: "post",
        url: url,
        data: {
          ...formData,
          title: formData?.folderName,
          coverImage: "ai",
          description: "ai",
        },
        headers: {
          Authorization: `Bearer ${tokenData}`,
          "Content-Type": "application/json",
        },
      };
      const response = await axios(config);
      if (response.status === 200) {
        if (response?.data?.error) {
          setUpdate(!update);
          setError({
            errorMessage: response?.data?.msg,
            errorStatus: true,
            header: "Message",
          });
          setLoading(false);
        } else {
          setUpdate(!update);
          setError({
            errorMessage: response?.data?.msg,
            errorStatus: false,
            header: "Message",
          });
          setFormData(initialFormData);
          setsessionForm(false);
          setLoading(false);
        }
      }
    } catch (error) {
      console.log("error", error);
      setFormData({
        folderName: "",
        gdriveUrl: "",
      });
      setLoading(false);
      setUpdate(!update);
    }
  };

  const handleUploadTypeChange = (event) => {
    setSelectedUploadType(event.target.value);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
    setFormErrors({ ...formErrors, [name]: "" });
  };

  const handleUserListingButton = () => {
    if (!albumDetails?.memshotAlbumId) {
      setError({
        errorMessage: "Memshots Album is Processing Please Wait",
        errorStatus: true,
        header: "Message",
      });
      return;
    }
    setViewUserListPopup(true);
  };

  const handleRefreshImages = async (folderId) => {
    setLoading(true);
    try {
      let config = {
        method: "patch",
        url: `https://asia-south1-shootmates.cloudfunctions.net/medias/api/shootmates/medias/syncimagesdashboard/${projectId}/${studioId}/${shootmatesAlbumId}/${folderId}`,
        headers: {
          Authorization: `Bearer ${tokenData}`,
          "Content-Type": "application/json",
        },
        data: {
          folderId: folderId,
        },
      };
      const response = await axios(config);
      if (response.status === 201) {
        if (response.data.error === true) {
          setError({
            errorMessage: response?.data?.msg,
            errorStatus: true,
            header: "Message",
          });
          setLoading(false);
          return;
        }
        setLoading(false);
        setUpdate(!update);
      }
    } catch (error) {
      console.log("error", error);
      setLoading(false);
    }
  };

  const openuserWindow=()=>{
    const origin = window.location.origin; 
    const url = `${origin}/Event/${projectId}/photosharetool/${shootmatesAlbumId}/userselection`;
    window.open(url, "_blank");
  }

  return (
    <div className="faceTrainingDetailContainer min-h-[100vh]">
      <Breadcrumb
        update={update}
        setUpdate={setUpdate}
        items={[
          { label: "Event", url: "/Event" },
          { label: "Event Detail", url: `/Event/${projectId}` },
        ]}
      />
      {loading ? (
        <>
          {" "}
          <Lottie />
        </>
      ) : (
        <div>
          {error.errorStatus === true && (
            <PopupWrapper visible={error.errorStatus}>
              <ErrorPopup error={error} setError={setError} />
            </PopupWrapper>
          )}
          {addUserPopup && (
            <AddUser
              setAddUserPopup={setAddUserPopup}
              projectId={projectId}
              albumId={shootmatesAlbumId}
              visible={addUserPopup}
              setpopUp={setAddUserPopup}
            />
          )}
          {viewQrPopup && (
            <QrCodeGenerator
              slugId={albumDetails?.slugId}
              onClose={() => setViewQrPopup(false)}
            />
          )}
          {addWatermarkPopup && (
            <Watermark
              setAddWatermarkPopup={setAddWatermarkPopup}
              projectId={projectId}
              studioId={studioId}
              albumId={shootmatesAlbumId}
              visible={addWatermarkPopup}
            />
          )}
          {viewUserListPopup && (
            <CrewUsers
              studioId={studioId}
              shootmatesAlbumId={shootmatesAlbumId}
              projectId={projectId}
              setViewUserListPopup={setViewUserListPopup}
            />
          )}
          {metaDataPopup && (
            <MetaDataPopup
              update={update}
              setUpdate={setUpdate}
              visible={metaDataPopup}
              setView={setMetaDataPopup}
              data={albumDetails}
              studioId={studioId}
              shootmatesAlbumId={shootmatesAlbumId}
              projectId={projectId}
            />
          )}
          {viewRegisteredUsersPopup && (
            <PopupWrapper visible={viewRegisteredUsersPopup}>
              <RegisteredUsersPopup
                studioId={studioId}
                projectId={projectId}
                memshotAlbumId={albumDetails?.memshotAlbumId}
                setViewRegisteredUsersPopup={setViewRegisteredUsersPopup}
              />
            </PopupWrapper>
          )}
          {paidPhotosSection && (
            <PopupWrapper visible={paidPhotosSection}>
              <PaidPhotosHistory
                studioId={studioId}
                slugId={albumDetails?.slugId}
                projectId={projectId}
                memshotAlbumId={albumDetails?.memshotAlbumId}
                setPaidPhotosSection={setPaidPhotosSection}
              />
            </PopupWrapper>
          )}
          {viewLiveUsersPopup && (
            <PopupWrapper visible={viewLiveUsersPopup}>
              <LiveUsersPopup
                studioId={studioId}
                projectId={projectId}
                memshotAlbumId={albumDetails?.memshotAlbumId}
                setViewLiveUsersPopup={setViewLiveUsersPopup}
              />
            </PopupWrapper>
          )}
          {sessionForm && (
            <TrainingFolder
              initialFormData={initialFormData}
              setFormData={setFormData}
              uploadTypes={uploadTypes}
              selectedUploadType={selectedUploadType}
              handleUploadTypeChange={handleUploadTypeChange}
              visible={sessionForm}
              setpopUp={setsessionForm}
              formData={formData}
              formErrors={formErrors}
              handleInputChange={handleInputChange}
              handleSubmit={handleSubmit}
              error={error}
              setError={setError}
            />
          )}
          {showConfirmation?.show && (
            <ConfirmationPopup
              showConfirmation={showConfirmation}
              onCancel={() => setShowConfirmation({ show: false })}
              onConfirm={() => handleConfirmation(showConfirmation)}
            />
          )}
          <div className="flex flex-col gap-[16px]  px-[20px] py-[12px]">
            <div className="flex gap-[8px] items-center">
              <img
                src={eventFaceDetection}
                alt=""
                className="w-[62px] h-[62px]"
              />
              <div className=" flex flex-col gap-[8px]">
                <p className="font_semibold_20 text-[#161616]">
                  {albumDetails?.albumName}
                </p>
                <p className="font_normal_14  text-[#8C8C8C] ">
                  {albumDetails?.albumName}
                </p>
              </div>
            </div>
            <div className="flex justify-between items-center">
              <div className="flex gap-[16px] items-center">
                <div
                  onClick={handleAlbumMetaDataButton}
                  className="shortButton"
                >
                  <img src={detailsIcon} alt="" className="w-[16px] h-[16px]" />
                  <p className="text-[10px] text-[#161616]">Settings</p>
                </div>
                <div
                  onClick={() => setViewQrPopup(true)}
                  className="shortButtonBlack"
                >
                  <img src={qrCodeIcon} alt="" className="w-[16px] h-[16px]" />
                  <p className="text-[10px] text-[#F6F6F6]">View QR Codes</p>
                </div>
                <div
                  onClick={() => {
                    setAddWatermarkPopup(true);
                  }}
                  className="shortButton"
                >
                  <img src={options} alt="" className="w-[16px] h-[16px]" />
                  <p className="text-[10px] text-[#161616]">Add Watermark</p>
                </div>
                <div onClick={handleUserListingButton} className="shortButton">
                  <img src={userIcon} alt="" className="w-[16px] h-[16px]" />
                  <p className="text-[10px] text-[#161616]">
                    View / Manage Crew
                  </p>
                </div>
                <div
                  onClick={handleRegisteredDataButton}
                  className="shortButton"
                >
                  <img src={userIcon} alt="" className="w-[16px] h-[16px]" />
                  <p className="text-[10px] text-[#161616]">
                    View Registered Users
                  </p>
                </div>
                <div onClick={handleLiveDataButton} className="shortButton">
                  <img src={userIcon} alt="" className="w-[16px] h-[16px]" />
                  <p className="text-[10px] text-[#161616]">View Live Users</p>
                </div>
                <div
                  onClick={() => {
                    setPaidPhotosSection(true);
                  }}
                  className="shortButton"
                >
                  <img src={userIcon} alt="" className="w-[16px] h-[16px]" />
                  <p className="font_normal_11 text-[#161616]">
                    View/Manage Paid Photos
                  </p>
                </div>
                <div
                  onClick={openuserWindow}
                  className="shortButton"
                >
                  <img src={userIcon} alt="" className="w-[16px] h-[16px]" />
                  <p className="font_normal_11 text-[#161616]">
                    OPEN QR WINDOW
                  </p>
                </div>
              </div>
              <div className="flex h-[4px] items-center ">
                <button
                  onClick={handlecreatefolder}
                  className="eventButton text-[10px]"
                >
                  Create a camera folder
                </button>
              </div>
            </div>
          </div>
          <div className="seperatorHorizontalNew"></div>
          <GroupData
            albmTrainingSession={albmTrainingSession}
            calculateTimeDifference={calculateTimeDifference}
            setShowConfirmation={setShowConfirmation}
            handleRefreshImages={handleRefreshImages}
            location={location}
            navigate={navigate}
          />
        </div>
      )}
    </div>
  );
}

export default TrainingSession;

const GroupData = ({
  albmTrainingSession,
  calculateTimeDifference,
  setShowConfirmation,
  handleRefreshImages,
  location,
  navigate,
}) => {
  const renderData = (data) => {
    return (
      data &&
      data.map((obj, index) => (
        <div
          key={index}
          className="flex items-center rounded-lg mt-2 border border-gray-300 p-4 shadow-sm hover:shadow-md transition-shadow duration-200 hover:bg-blue-50"
        >
          <div className="flex-shrink-0 w-16 h-16 bg-blue-100 rounded-full flex items-center justify-center">
            <svg
              className="w-8 h-8 text-blue-600"
              fill="currentColor"
              viewBox="0 0 20 20"
            >
              <path d="M4 4a2 2 0 012-2h4l2 2h6a2 2 0 012 2v10a2 2 0 01-2 2H4a2 2 0 01-2-2V4z" />
            </svg>
          </div>
          <div className="ml-4 flex-grow">
            <div className="flex items-center justify-between mb-2">
              <p className="text-sm font-medium text-gray-900">
                {obj.folderName}
              </p>
            </div>
            <div className="flex gap-6 text-sm text-gray-700">
              {obj.uploadType !== "DESK" && (
                <div className="flex items-center">
                  <span className="ml-2">Total Images</span>
                  <span className="ml-2 text-blue-600">
                    {obj.uploadType === "DESK"
                      ? obj.processedImages
                      : obj.pullCount}
                  </span>
                </div>
              )}
              <div className="flex items-center">
                <span className="ml-2">Uploaded Images</span>
                <span className="ml-2 text-blue-600">
                  {obj.processedImages}
                </span>
              </div>
            </div>
            {obj.trainingStatus && (
              <div className="flex gap-6 text-sm text-gray-700">
                <div className="flex items-center">
                  <span className="ml-2">AI Operation Time</span>
                  <span className="ml-2 text-red-600">
                    {calculateTimeDifference(
                      obj.trainingEndTime,
                      obj.trainingStartTime,
                      "training",
                      obj.trainingStatus
                    )}
                  </span>
                  <span className="ml-2">AI Operation Status</span>
                  <span className="ml-2 text-pink-600">
                    {obj.trainingStatus}
                  </span>
                </div>
              </div>
            )}
          </div>
          <div className="flex items-center gap-3">
            <div
              onClick={() =>
                navigate(`${location.pathname}/upload/${obj.folderId}`)
              }
              className="flex items-center px-3 py-2 rounded-md bg-blue-600 hover:bg-blue-700 text-white cursor-pointer transition-colors duration-200"
            >
              <svg
                className="w-5 h-5 mr-2"
                fill="currentColor"
                viewBox="0 0 20 20"
              >
                <path d="M8 7v6h4V7h5l-7-7-7 7h5zM3 13h14v7H3v-7z" />
              </svg>
              <p className="text-sm">Upload Images</p>
            </div>
            <div
              onClick={() => handleRefreshImages(obj.folderId)}
              className="flex items-center px-3 py-2 rounded-md bg-green-600 hover:bg-green-700 text-white cursor-pointer transition-colors duration-200"
            >
              <svg
                className="w-5 h-5 mr-2"
                fill="currentColor"
                viewBox="0 0 20 20"
              >
                <path d="M10 2a8 8 0 100 16 8 8 0 000-16zM9 14V8h2v6H9zM9 6V4h2v2H9z" />
              </svg>
              <p className="text-sm">Refresh Images</p>
            </div>
            <div
              onClick={() =>
                setShowConfirmation({
                  show: true,
                  folderId: obj.folderId,
                  folderName: obj.folderName,
                  totalImages: obj.totalImages,
                  processedImages: obj.processedImages,
                })
              }
              className="flex items-center px-3 py-2 rounded-md bg-cyan-600 hover:bg-cyan-700 text-white cursor-pointer transition-colors duration-200"
            >
              <p className="text-sm">Publish</p>
            </div>
          </div>
        </div>
      ))
    );
  };

  return (
    <div className="faceTrainingDetailTable">
      {albmTrainingSession && albmTrainingSession.length === 0 ? (
        <h1>
          <span className="ml-5 text-red-500">No data found!</span>
        </h1>
      ) : (
        renderData(albmTrainingSession)
      )}
    </div>
  );
};
