import React, { useContext, useEffect, useState } from "react";
import Lottie from "../../../Assets/Animation/LoadingWhite";
import MessagePopup from "../../PopUps/MessagePopup/MessagePopup";
import axios from "axios";
import ErrorPopup from "../../PopUps/ErrorPopup/ErrorPopup";
import { dataContext } from "../../../Context/DataContext";
import { userAuthContext } from "../../../Context/UserAuthContext";
import { PopupWrapper } from "../../../UI/PopupWrapper/PopupWrapper";
import "./AddUser.css";
import popupsCloseIcon from "../../../Assets/popupsCloseIcon.svg";

const AddUser = ({
  setAddUserPopup,
  projectId,
  albumId,
  visible,
  setpopUp,
}) => {
  const { studioId } = useContext(dataContext);
  const { userIdToken, currentUser, verifyUser } = useContext(userAuthContext);
  const tokenData = userIdToken || currentUser?.accessToken;
  const [messagePopup, setMessagePopup] = useState({
    visible: false,
    type: "",
    message: "",
  });
  const [loadingText, setLoadingText] = useState(
    "Adding User to memshots album.."
  );
  const [loading, setLoading] = useState(false);
  const [inputValue, setInputValue] = useState("");
  const [name, setName] = useState("");
  const [error, setError] = useState("");
  const [nameError, setNameError] = useState("");
  const [selectedOption, setSelectedOption] = useState("ALBUM_USER");

  useEffect(() => {
    verifyUser();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleInputChange = (e) => {
    const value = e.target.value;
    setInputValue(value);
  };

  const handleNameChange = (e) => {
    const value = e.target.value;
    setName(value);

    if (value.trim() === "") {
      setNameError("Name cannot be empty");
    } else {
      setNameError("");
    }
  };

  const isValidEmail = (email) => {
    const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
    return emailRegex.test(email);
  };

  const handleUserRoleChange = (e) => {
    setSelectedOption(e.target.value);
  };

  const handleSubmit = async () => {
    try {
      let payload = null;
      if (!isValidEmail(inputValue)) {
        setError("Invalid email address");
        return;
      }

      if (name.trim() === "") {
        setNameError("Name cannot be empty");
        return;
      }
      selectedOption === "ALBUM_USER"
        ? (payload = {
            userData: {
              name: name,
              email: inputValue,
              userRole: selectedOption,
            },
          })
        : (payload = {
            userData: {
              name: name,
              email: inputValue,
            },
            userRole: selectedOption,
          });
      setLoading(true);
      verifyUser();
      let config = {
        method: "post",
        url:
          selectedOption === "ALBUM_USER"
            ? `https://asia-south1-shootmates.cloudfunctions.net/shootmatesMemshotsServices/api/shootmates/memshotsservices/addUserMemshotsAlbum/${projectId}/${studioId}/${albumId}`
            : // `https://asia-south1-shootmates.cloudfunctions.net/test_/shootmatesMemshotsServices/api/shootmates/memshotsservices/addUserMemshotsAlbum/${projectId}/${studioId}/${albumId}`
              `https://asia-south1-shootmates.cloudfunctions.net/shootmatesDesktopTool/api/shootmates/desk/addUser/${projectId}/${studioId}/${albumId}`,
        data: payload,
        headers: {
          Authorization: `Bearer ${tokenData}`,
          "Content-Type": "application/json",
        },
      };
      const response = await axios(config);
      if (response.status === 200) {
        setLoadingText("Adding User on progress..");
        setLoading(false);
        setInputValue("");
        setName("");
        setError("");
        setNameError("");
        setMessagePopup({
          visible: true,
          type: "success",
          message: response?.data?.msg,
        });
      } else {
        setLoading(false);
        setInputValue("");
        setName("");
        setError("");
        setNameError("");
        setMessagePopup({
          visible: true,
          type: "error",
          message: "Please try again..",
        });
      }
    } catch (error) {
      setLoading(false);
      console.log("error", error);
    }
  };

  return (
    <PopupWrapper visible={visible} setpopUp={setpopUp}>
      {loading ? (
        <Lottie loadingText={loadingText} />
      ) : (
        <>
          {error.errorStatus === true && (
            <ErrorPopup error={error} setError={setError} />
          )}
          {messagePopup?.visible && (
            <MessagePopup
              messagePopup={messagePopup}
              setMessagePopup={setMessagePopup}
            />
          )}
          <div className="AddUserFormContainer mt-[78px]">
            <div className="px-[16px] pt-[24px] pb-[12px] sticky top-0 bg-white flex items-center justify-between rounded-t-[12px]">
              <p className="font_semibold_20 text-[#161616]">Add User</p>
              <img
                onClick={() => {
                  setAddUserPopup(false);
                  setInputValue("");
                  setName("");
                  setError("");
                  setNameError("");
                }}
                src={popupsCloseIcon}
                alt=""
                className="cursor-pointer"
              />
            </div>
            <div className="seperatorHorizontalNew"></div>
            <div className="px-[16px] pt-[12px]">
              <p className="font_semibold_16 text-[#161616] mb-[20px]">
                User Details
              </p>
              <p className="font_normal_12 text-[#8C8C8C] mb-[8px]">
                User Name <span className="text-red-500">*</span>
              </p>
              <input
                type="text"
                className={`w-full font_normal_14 border-[1px] p-[10px] rounded-[8px] ${
                  nameError ? "border-red-500" : "border-[#8C8C8C4D]"
                }`}
                placeholder="Enter name"
                value={name}
                onChange={handleNameChange}
              />
              {nameError && (
                <p className="text-red-500 font_normal_12">{nameError}</p>
              )}
            </div>

            <div className="px-[16px]">
              <p className="font_normal_12 text-[#8C8C8C] mb-[8px]">
                Email <span className="text-red-500">*</span>
              </p>
              <input
                type="email"
                className={`w-full font_normal_14 border-[1px] p-[10px] rounded-[8px] ${
                  error ? "border-red-500" : "border-[#8C8C8C4D]"
                }`}
                placeholder="Enter email"
                value={inputValue}
                onChange={handleInputChange}
              />
            </div>
            {error && <p className="text-red-500 font_normal_12">{error}</p>}
            <div className="px-[16px] mt-[8px]">
              <p className="font_normal_12 text-[#8C8C8C] mb-[8px]">
                User Role <span className="text-red-500">*</span>
              </p>
              <select
                value={selectedOption}
                onChange={handleUserRoleChange}
                className="w-full font_normal_14 border-[1px] p-[10px] rounded-[8px] border-[#8C8C8C4D]"
              >
                <option value="ALBUM_USER">User</option>
                <option value="CREW">Crew</option>
              </select>
            </div>
            <div className="seperatorHorizontalNew mt-[16px]"></div>
            <div className="px-[16px] w-full flex gap-[16px] items-center justify-end pt-[12px] pb-[24px]">
              <button
                className="cancelButton font_semibold_12"
                onClick={() => {
                  setAddUserPopup(false);
                  setInputValue("");
                  setName("");
                  setError("");
                  setNameError("");
                }}
              >
                Cancel
              </button>
              <button className="eventButton" onClick={handleSubmit}>
                <p className="text-[#F6F6F6] font_semibold_12">Submit</p>
              </button>
            </div>
          </div>
        </>
      )}
    </PopupWrapper>
  );
};

export default AddUser;
